<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="back-item mb-2 mb-lg-5">
                    <button @click="$router.go(-1)" class="btn back-item__btn">
                        Retour
                    </button>
                    <h3 class="back-item__title">Ajouter une actualité</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col col-md-8">
                <div class="dash-box pb-5">
                    <div class="form-group">
                        <label class="app-label">
                            <span>Titre</span>
                            <input
                                    :class="errors.titre ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="text"
                                    v-model="form.titre"
                                    v-on:input="resetErrors('titre')"
                            />
                        </label>
                        <span class="text-danger mt-2" v-if="errors.titre">{{
              errors.titre[0]
            }}</span>
                    </div>
                    <div class="form-group">
                        <label class="app-label">
                            <span>Contenu</span>
                            <textarea
                                    :class="errors.contenu ? 'is-invalid' : ''"
                                    class="form-control"
                                    rows="10"
                                    v-model="form.contenu"
                                    v-on:input="resetErrors('contenu')"
                            ></textarea>
                        </label>
                        <span class="text-danger mt-2" v-if="errors.contenu">{{
              errors.contenu[0]
            }}</span>
                    </div>
                </div>
            </div>
            <div class="col col-md-4 ">
                <div class="card">
                    <div class="card-header bg-actuality">
                        Publier
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <label class="field-radio-item">
                                <input v-on:change="resetErrors('draft')"
                                       checked=""
                                       class="check-custom"
                                       name="publication"
                                       v-model="form.draft"
                                       type="radio"
                                       value="0"
                                />
                                <span class="check-toggle"></span>
                                <span class="name">Tout de suite</span>
                            </label>
                            <label class="field-radio-item mt-2">
                                <input v-on:change="resetErrors('draft')"
                                       class="check-custom"
                                       name="publication"
                                       type="radio"
                                       v-model="form.draft"
                                       value="1"
                                />
                                <span class="check-toggle"></span>
                                <span class="name">Plus tard (brouillon)</span>
                            </label>
                        </div>
                        <div class="row">
                            <span class="text-danger mt-2"
                                  v-if="errors.draft">{{ errors.draft ? errors.draft[0] : "" }}</span>
                        </div>
                        <br/>
                        <div class="text-right">
                            <button
                                    :disabled="loading"
                                    @click="addActuality()"
                                    class="btn btn-primary btn-sm"
                            >
                <span v-if="loading"
                >Publication en cours
                  <b-spinner label="Spinning" variant="light" small></b-spinner
                  ></span>
                                <span v-else>Publier</span>
                            </button>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div class="card">
                    <div class="card-header bg-actuality">
                        Image mise en avant
                    </div>
                    <div class="card-body">
                        <img alt="" class="actuality_picture" src=""/>
                        <div>
                            <input
                                    id="updateImgInput"
                                    ref="updateImgInput"
                                    :class="errors.picture ? 'is-invalid' : ''"
                                    name="picture"
                                    type="file"
                                    v-on:change="onPictureChange()"
                            />
                            <!--<label class="btn btn-primary btn-block btn-file">
                              Définir l'image mise en avant
                              <input
                                :class="errors.picture ? 'is-invalid' : ''"
                                name="picture"
                                style="display: none;"
                                type="file"
                                v-on:change="onPictureChange($event)"
                              />
                            </label>-->
                            <div class="row">
                <span class="text-danger mt-2" v-if="errors.picture">{{
                  errors.picture[0]
                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import auth from "../../../../helper/config";

    export default {
        name: "addActuality",
        data: () => ({
            loading: false,
            form: {
                titre: "",
                contenu: "",
                picture: null,
                draft: ""
            },
            errors: {}
        }),
        methods: {
            onPictureChange() {
                this.previewPicture(); //there is the problem
                this.resetErrors('file')
                this.form.picture = this.$refs.updateImgInput.files[0];
                //console.log(this.form.picture)
            },
            addActuality() {
                let url = auth.baseApi;
                this.loading = true;
                let formData = new FormData();
                formData.append("titre", this.form.titre);
                formData.append("contenu", this.form.contenu);
                formData.append("picture", this.form.picture);
                formData.append("draft", this.form.draft);

                axios
                    .post(url + "actualities/create", formData, {
                        headers: {
                            "content-type": "multipart/form-data",
                            Authorization: "Bearer " + auth.getSession()
                        }
                    })
                    .then(response => {
                        this.loading = false;
                        if (response.data.status === true) {
                            this.$toasted.global.all();
                            this.$router.push({name: "liste_actualities"});
                        } else this.$toasted.global.error();
                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.status === 401) {
                            this.errors = [];
                            this.$toasted.global.check();
                            auth.logout();
                            this.$router.push({name: "login"});
                        } else if (error.response.status === 400) {
                            this.errors = error.response.data.errors;
                        } else {
                            this.$toasted.global.error();
                        }
                    });
            },
            resetErrors(field) {
                if (field) {
                    this.errors[field] = null;
                } else {
                    this.errors = [];
                    let actu = this.form;
                    Object.keys(actu).forEach(index => (actu[index] = null));
                }
            },
            previewPicture() {
                const preview = document.querySelector(".actuality_picture");
                const file = document.querySelector("input[name=picture]").files[0];
                const reader = new FileReader();
                reader.onloadend = function () {
                    preview.src = reader.result;
                };
                if (file) {
                    reader.readAsDataURL(file);
                } else {
                    preview.src = "";
                }
                //this.onPictureChange();
            }
        }
    };
</script>

<style scoped>
    .btn-file {
        cursor: pointer;
    }

    .actuality_picture {
        width: 100%;
        height: 179px;
        padding-bottom: 10px;
    }

    .bg-actuality {
        background-color: #eaeff5 !important;
    }
</style>
