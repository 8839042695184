<template>
    <div class="post-single">
        <div>
            <div class="vld-parent align-content-center">
                <loading :active.sync="loading" :is-full-page="true"></loading>
            </div>
        </div>
        <div v-if="!loading">
            <div class="post-single-banner">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h2 class="post-single__title">{{ datas.titre }}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <img
                                id="curr_actu_img"
                                :src="web_site_url + datas.path_picture"
                                alt="..."
                                class="post-single__img img-fluid"
                        />
                        <!-- <div class="post-single__content text-justify">
                            {{ datas.contenu }}
                        </div> -->
                        <div class="post-single__content text-justify" v-html="datas.contenu"></div>

                        <br/>
                        <br/>
                        <br/>
                    </div>
                    <div class="col-lg-4" v-if="other_actualities.length > 0">
                        <h3 class="post-similar__title">Articles populaires</h3>
                        <div
                             class="post-mini"
                             v-for="(actuality, cpt) in other_actualities"
                             :key="cpt"
                        >
                            <div class="row">
                                <div class="col-md-5">
                                    <img v-if="actuality.id !== $route.params.id"
                                         alt="..."
                                         class="post-mini__img img-fluid mini-actu"
                                         :src="web_site_url + actuality.path_picture"
                                    />
                                </div>
                                <div class="col-md-7">
                                    <div class="post-mini__main text-justify">
                                        <a v-if="actuality.id !== $route.params.id"
                                                @click="$router.push({name: 'actuality_details',params: { id: actuality.id }}).catch(err => {})"
                                                class="post-similar__btn"
                                        >{{ actuality.titre | truncate(115) }}</a
                                        >
                                    </div>
                                </div>

                            </div>

                        </div>

                        <a
                                @click="$router.push({ name: 'home', query: {scroll: 1}}).catch(err => {})"
                                class="post-similar__btn"
                        >VOIR TOUT</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from "../../../../helper/config";
    import axios from "axios";
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        name: "detailsActuality",
        components: {Loading},
        data: () => ({
            loading: false,
            datas: [],
            other_actualities: [],
            web_site_url: auth.basePath
        }),
        watch: {
          "$route.params.id": function (val) {
            if (val){
                this.loadOneActuality()
            }
          }
        },
        mounted() {
            this.loadOneActuality(), this.loadOtherActualities();
        },
        methods: {
            loadOneActuality() {
                let url = auth.baseApi;
                this.loading = true;
                let id = this.$route.params.id;
                axios
                    .get(url + "guest/actualities/" + id)
                    .then(response => {
                        this.loading = false;
                        this.datas = response.data;
                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.data.status) {
                            this.$toasted.global.error();
                        }
                    });
            },
            loadOtherActualities() {
                let url = auth.baseApi;
                this.loading = true;
                axios
                    .get(url + "guest/actualities")
                    .then(response => {
                        this.loading = false;
                        this.other_actualities = response.data;
                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.data.status) {
                            this.$toasted.global.error();
                        }
                    });
            }
        }
    };
</script>

<style scoped>
    .mini-actu {
        width: 90px;
        border-radius: 5px;
    }

    #curr_actu_img {
        width: 100%;
    }

    .post-single__content {
        line-height: 135%;
    }
</style>
