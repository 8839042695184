<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="m-heading mb-3">
            <h3 class="m-title">Opportunités ({{ pagination.total }})</h3>
            <div class="row">
              <button
                @click="loadOpportunities()"
                class="btn btn-primary mx-1"
                data-toggle="modal"
              >
                <span>Actualiser</span>
              </button>
              <button
                @click="$router.push({ name: 'opportunity_add' })"
                class="btn btn-primary"
                v-if="curr_user_profil.id !== 1"
              >
                <svg
                  fill="none"
                  height="13"
                  viewBox="0 0 13 13"
                  width="13"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M7.04232 5.95834H11.9173V7.04168H7.04232V11.9167H5.95898V7.04168H1.08398V5.95834H5.95898V1.08334H7.04232V5.95834Z"
                    fill="white"
                    fill-rule="evenodd"
                    stroke="white"
                    stroke-width="2"
                  ></path>
                </svg>
                <span>Ajouter un stage/emplois </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" v-if="loading">
        <div class="row">
          <div class="container">
            <div class="col">
              <div class="profile-box">
                <b-spinner
                  label="Text Centered"
                  style="width: 8rem; height: 8rem;"
                  variant="primary"
                ></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row" v-if="pagination.total > 0">
          <div
            :key="cpt"
            class="opportunity-wrapper col-md-6 col-lg-4"
            v-for="(opportunity, cpt) in opportunities"
          >
            <div class="opportunity">
              <div class="opportunity__header" style="height: 45px; max-height: 45px">
                <div class="opportunity__category col-6">{{ opportunity.titre | truncate(20) }}</div>
                <button class="btn btn-warning opportunity__type col-6" style="margin-left: -2px" type="button">
                  {{ opportunity.type }}
                </button>
              </div>
              <div class="opportunity__main">
                <br>
                <h3 class="opportunity__title">
                  {{ opportunity.publicateur  | truncate(20)}}
                </h3>
                <div class="opportunity__content" style="max-height: 45px; height: 45px">
                  {{ opportunity.description | truncate(50) }}
                </div>
                <a
                  @click="
                    $router.push({
                      name: 'opportunity_details',
                      params: { id: opportunity.id }
                    })
                  "
                  class="opportunity__link btn btn-outline-secondary"
                  >Lire la suite</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="container" v-else>
          <div class="row">
            <div class="col">
              <div class="student-single-content">
                <div class="student-single-content__content text-center">
                  Auncun résultat
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="pagination.total > 0">
        <div class="row">
          <div class="container">
            <div class="">
              <pagination-component
                :offset="5"
                :pagination="pagination"
                @paginate="loadOpportunities()"
                v-if="pagination.last_page > 1"
              ></pagination-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth from "../../../../helper/config";
import PaginationComponent from "../PaginationComponent";

export default {
  name: "listOpportunities",
  data: () => ({
    curr_user_profil: auth.getCurrentUser().profil,
    loading: false,
    opportunities: [],
    pagination: {
      current_page: 1
    }
  }),
  components: { PaginationComponent },
  created() {
    this.loadOpportunities();
  },
  methods: {
    loadOpportunities() {
      let url = auth.baseApi;
      this.loading = true;
      let page = "/?page=" + this.pagination.current_page;
      axios
        .get(url + "opportunities/list" + page, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          if (response) {
            this.opportunities = response.data.data.data;
            this.pagination = response.data.pagination;
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    }
  }
};
</script>

<style scoped>
.opportunity {
  height: 250px;
}
</style>
