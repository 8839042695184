<template>
  <div class="profil-content">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="m-heading mb-3">
            <h3 class="m-title">Mon Profil</h3>
            <div align="center">
              <button
                @click="$router.push({ name: 'user_profil' })"
                class="btn btn-red"
              >
                <span>Mettre à jour</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" v-if="loading">
        <div class="row">
          <div class="col">
            <div class="profile-box">
              <b-spinner
                variant="primary"
                label="Text Centered"
                style="width: 8rem; height: 8rem;"
              ></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col">
            <div class="profile-box">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <div class="profile-preview">
                    <div class="profile-preview__photo">
                      <img
                        :src="web_site_url + form.path_picture"
                        alt="..."
                        class="profile-preview__img d-block img-fluid"
                        id="profil_picture"
                      />
                    </div>
                    <h3 class="profile-preview__name">
                      {{ curr_user.nom + " " + curr_user.prenoms }}
                    </h3>
                    <div class="profile-preview__job">
                      {{ user_identity.email }}
                    </div>
                    <h5 class="mt-3">Pourcentage profil</h5>
                    <b-progress :max="100" show-progress animated>
                      <b-progress-bar
                        :value="form.completed_pourcent"
                        :label="`${(form.completed_pourcent / 100) * 100}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </div>
                </div>
                <div class="col-md-8 col-lg-8">
                  <h3 class="auth-title">Ravi de vous revoir</h3>
                  <div class="auth-subtitle">
                    Voici vos informations personnelles
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Nom</span>
                          <small>{{ form.nom }}</small>
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Prénom(s)</span>
                          <small>{{ form.prenoms }}</small>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Nationalité</span>
                          <small>{{ form.nationalite }}</small>
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Pays de résidence</span>
                          <small>{{ form.pays_residence }}</small>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Téléphone</span>
                          <small>{{ form.telephone }}</small>
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Profession</span>
                          <small>{{ form.profession }}</small>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Employeur</span>
                          <small>{{ form.employeur }}</small>
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Sexe</span>
                          <small v-if="form.sexe === 'F'">Féminin</small>
                          <small v-else>Masculin</small>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row text-justify">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="app-label">
                          <span>Biographie</span>
                          <small class="text-justify">{{
                            form.biographie
                          }}</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <div class="profile-box">
              <div align="center"><h3>Mon parcours ESGIS</h3></div>
              <br />
              <div class="row" v-if="form.parcours.length > 0">
                <div class="container">
                  <div class="dash-table-box table-responsive">
                    <table class="table--custom table table-hover">
                      <thead>
                        <tr>
                          <th>Diplôme</th>
                          <th>Esgis</th>
                          <th>Période</th>
                          <th>Filière</th>
                          <th>Réussite</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(parcour, cpt) in form.parcours" :key="cpt">
                          <td>{{ parcour.diplome.nom }}</td>
                          <td>{{ parcour.esgis.pays }}</td>
                          <td>
                            {{ parcour.start_year }}-{{ parcour.end_year }}
                          </td>
                          <td>{{ parcour.filiere.nom }}</td>
                          <td>
                            <span v-if="parcour.success === 1">OUI</span>
                            <span v-else>NON</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="container" v-else>
                <div class="row">
                  <div class="col">
                    <div class="student-single-content__content text-center">
                      Auncun résultat
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth from "../../../helper/config";
import { mapGetters, mapState } from "vuex";

export default {
  name: "userProfil",
  data: () => ({
    loading: false,
    web_site_url: auth.basePath,
    user_identity: null,
    picture: null,
    form: {
      nom: null,
      email: null,
      prenoms: null,
      nationalite: null,
      pays_residence: null,
      profession: null,
      employeur: null,
      esgis: null,
      last_grade: null,
      last_grade_year: null,
      telephone: null,
      sexe: null,
      filiere: null,
      first_year: null,
      biographie: null,
      path_picture: null,
      parcours: []
    },
    curr_year: new Date().getFullYear()
  }),
  computed: {
    ...mapGetters({
      years: "getYears",
      pays: "getPays",
      profession: "getProfession"
    }),
    ...mapState({
      curr_user: state => state.user
    })
  },
  created() {
    this.loadCurrUserData();
  },
  props: {},
  components: {},
  methods: {
    loadCurrUserData() {
      let url = auth.baseApi;
      this.loading = true;
      this.user_identity = "";
      axios
        .get(url + "users/current", {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          this.user_identity = Object.assign({}, response.data[0]);
          this.form = response.data[0];

          this.message = "";
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    }
  }
};
</script>

<style scoped>
.profil-content {
  padding-bottom: 100px;
}

#profil_picture {
  -moz-border-radius: 700px;
  -webkit-border-radius: 700px;
  border-radius: 700px;
}
</style>
