<template>
  <div class="section-notice">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="notice-wrapper">
            <div class="notice">
              <svg
                class="notice__thumb"
                fill="none"
                height="47"
                viewBox="0 0 44 47"
                width="44"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.58845 47H2.06499L15.8845 41.9759C14.6137 36.9517 10.8014 33.0621 6.03611 31.6035L0.158849 44.731C-0.15884 45.5414 0.158849 46.5138 0.953073 46.8379C1.11192 47 1.42961 47 1.58845 47Z"
                  fill="white"
                />
                <path
                  d="M18.9026 40.8414L24.3034 38.8966C25.0976 38.5724 25.4153 37.7621 25.2564 36.9517C23.668 29.9828 18.4261 24.4724 11.5958 22.6897C10.8016 22.3655 10.0073 22.8517 9.68965 23.5L7.46582 28.3621C13.0254 30.3069 17.473 35.0069 18.9026 40.8414Z"
                  fill="white"
                />
                <path
                  d="M21.4437 20.2586H20.4906C19.061 20.2586 17.6314 20.9069 16.996 22.2035C16.5195 23.0138 16.8372 23.9862 17.4726 24.4724C17.4726 24.4724 17.4726 24.4724 17.6314 24.4724H18.2668C18.9022 24.4724 19.3787 24.1483 19.6964 23.6621H20.6495C22.0791 23.6621 23.5087 23.0138 24.1441 21.7172C24.6206 20.2586 24.3029 18.8 23.3498 17.6655L22.8733 16.8552H23.8264C25.256 16.8552 26.6856 16.2069 27.321 14.9103C27.7975 14.1 27.4798 13.1276 26.8444 12.6414C26.8444 12.6414 26.8444 12.6414 26.6856 12.6414C25.8914 12.3172 25.0971 12.4793 24.6206 13.2897H23.6675C22.2379 13.2897 20.8083 13.9379 20.1729 15.2345C19.6964 16.6931 20.0141 18.1517 20.9672 19.2862L21.4437 20.2586Z"
                  fill="white"
                />
                <path
                  d="M32.4047 20.9069H33.3577L35.2639 21.231C36.0581 21.231 36.6935 20.9069 37.3289 20.4207C38.2819 19.2862 38.5996 17.8276 38.1231 16.369C38.1231 16.0448 37.9642 15.7207 37.9642 15.3966H38.7585C40.0292 16.0448 41.6177 15.8828 42.7296 14.9103C43.365 14.2621 43.5238 13.2897 42.8884 12.6414C42.2531 11.9931 41.3 11.831 40.6646 12.4793H39.7115C38.4408 11.831 36.8523 11.9931 35.7404 12.9655C34.7873 14.1 34.4697 15.5586 34.9462 17.0172C34.9462 17.3414 35.105 17.6655 35.105 17.9897H34.3108C33.0401 17.3414 31.4516 17.5034 30.3397 18.4759C29.7043 19.1241 29.5455 20.0966 30.1808 20.7448C30.6574 21.3931 31.7693 21.5552 32.4047 20.9069Z"
                  fill="white"
                />
                <path
                  d="M10.8017 12.8034C10.6428 13.6138 11.2782 14.4241 12.2313 14.5862H12.3901C13.1843 14.5862 13.8197 13.9379 13.9786 13.1276L14.7728 12.6414C16.0435 12.1552 17.1555 11.0207 17.3143 9.56207C17.3143 8.10345 16.3612 6.8069 15.0905 6.15862L14.2963 5.67241L15.0905 5.18621C16.3612 4.7 17.4731 3.56552 17.632 2.1069C17.9497 1.29655 17.4731 0.324138 16.5201 0C16.3612 0 16.3612 0 16.2024 0C15.4082 0 14.7728 0.648276 14.6139 1.45862L13.9786 1.94483C12.549 2.43103 11.437 3.72759 11.2782 5.18621C11.2782 6.64483 12.2313 7.94138 13.502 8.58965L14.2963 9.07586L13.502 9.56207C12.0724 10.0483 10.9605 11.3448 10.8017 12.8034Z"
                  fill="white"
                />
                <path
                  d="M31.2924 32.0897L32.0866 32.7379C32.8809 33.3862 33.8339 33.8724 34.787 34.0345H35.8989C37.3285 33.3862 38.2816 31.7655 38.1227 30.1448C38.1227 29.8207 38.2816 29.6586 38.2816 29.3345L39.0758 29.8207C40.0289 30.7931 41.6173 31.2793 42.8881 30.7931C43.6823 30.469 44.1588 29.4966 43.8412 28.6862C43.5235 27.8759 42.5704 27.3897 41.7762 27.7138L40.982 27.0655C40.0289 26.0931 38.4404 25.6069 37.1697 26.0931C35.7401 26.7414 34.9459 28.2 34.9459 29.8207C34.9459 30.1448 34.787 30.3069 34.787 30.631L33.9928 30.1448C33.0397 29.1724 31.4513 28.6862 30.1805 29.1724C29.3863 29.4966 28.9098 30.469 29.2274 31.2793C29.5451 32.0897 30.4982 32.4138 31.2924 32.0897Z"
                  fill="white"
                />
                <path
                  d="M25.4156 6.48276C26.2929 6.48276 27.004 5.75715 27.004 4.86207C27.004 3.96699 26.2929 3.24138 25.4156 3.24138C24.5383 3.24138 23.8271 3.96699 23.8271 4.86207C23.8271 5.75715 24.5383 6.48276 25.4156 6.48276Z"
                  fill="white"
                />
                <path
                  d="M3.17731 25.931C4.05459 25.931 4.76576 25.2054 4.76576 24.3103C4.76576 23.4153 4.05459 22.6897 3.17731 22.6897C2.30004 22.6897 1.58887 23.4153 1.58887 24.3103C1.58887 25.2054 2.30004 25.931 3.17731 25.931Z"
                  fill="white"
                />
                <path
                  d="M33.357 43.7586C34.2343 43.7586 34.9454 43.033 34.9454 42.1379C34.9454 41.2428 34.2343 40.5172 33.357 40.5172C32.4797 40.5172 31.7686 41.2428 31.7686 42.1379C31.7686 43.033 32.4797 43.7586 33.357 43.7586Z"
                  fill="white"
                />
                <path
                  d="M4.76521 16.2069C5.64248 16.2069 6.35365 15.4813 6.35365 14.5862C6.35365 13.6911 5.64248 12.9655 4.76521 12.9655C3.88793 12.9655 3.17676 13.6911 3.17676 14.5862C3.17676 15.4813 3.88793 16.2069 4.76521 16.2069Z"
                  fill="white"
                />
                <path
                  d="M41.2994 8.10346C42.1767 8.10346 42.8878 7.37785 42.8878 6.48277C42.8878 5.58768 42.1767 4.86208 41.2994 4.86208C40.4221 4.86208 39.7109 5.58768 39.7109 6.48277C39.7109 7.37785 40.4221 8.10346 41.2994 8.10346Z"
                  fill="white"
                />
              </svg>
              <div class="notice__main">
                <h3 class="notice__title" v-if="distance > 0">
                  Lancement du club Alumni
                </h3>
                <h3 class="notice__title" v-else>
                  Le Club Alumni est lancé !!
                </h3>
                <!--
                <ul class="notice-list">
                  <li class="notice-list-item">
                    <span class="notice-list-item__number">{{
                      `${days}`
                    }}</span>
                    <span class="notice-list-item__name">Jours</span>
                  </li>
                  <li class="notice-list-item">
                    <span class="notice-list-item__number">{{
                      `${hours}`
                    }}</span>
                    <span class="notice-list-item__name">Heures</span>
                  </li>
                  <li class="notice-list-item">
                    <span class="notice-list-item__number">{{
                      `${minutes}`
                    }}</span>
                    <span class="notice-list-item__name">Minutes</span>
                  </li>
                  <li class="notice-list-item">
                    <span class="notice-list-item__number">{{
                      `${seconds}`
                    }}</span>
                    <span class="notice-list-item__name">Secondes</span>
                  </li>
                </ul>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeEsgisBirthday",
  data: () => ({
    test: null,
    countDownDate: new Date("dec 6 2019 00:00:00").getTime(),
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    distance: 0
  }),
  mounted() {
    /*var vm = this;
    var x = setInterval(function() {
      var now = new Date().getTime();
      vm.distance = vm.countDownDate - now;
      vm.days = Math.floor(vm.distance / (1000 * 60 * 60 * 24));
      vm.hours = Math.floor(
        (vm.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      vm.minutes = Math.floor((vm.distance % (1000 * 60 * 60)) / (1000 * 60));
      vm.seconds = Math.floor((vm.distance % (1000 * 60)) / 1000);
      if (vm.distance < 0) {
        if (vm.distance < -1000 * 60 * 60 * 24) {
          // if its past the "game day"
          // reset timer to next year
          vm.countDownDate += 1000 * 60 * 60 * 24 * 1 * 365;
        } else {
          vm.days = 0;
          vm.hours = 0;
          vm.minutes = 0;
          vm.seconds = 0;
        }
      }
    }, 1000);
    this.test = x;*/
  }
};
</script>

<style scoped></style>
