<template>
  <div class="profil-content">
    <b-modal id="modal-update-mail" title="Changer le mail ">
      <template v-slot:default="{ hide }">
        <b-form autocomplete="off" ref="Message">
          <b-form-group>
            <h3 class="auth-title mb-3">Nouvelle adresse email</h3>
            <input
              :class="errors.email ? 'is-invalid' : ''"
              class="form-control"
              v-model="form_update_email.email"
              v-on:input="resetErrors('email')"
            />
            <span class="text-danger" v-if="errors.email">{{
              errors.email[0]
            }}</span>
          </b-form-group>
        </b-form>
      </template>
      <div slot="modal-footer">
        <a @click="resetErrors()" class="link-red mx-1">Annuler</a>
        <b-button
          :disabled="loading_mail"
          @click="changeEmailAdresse()"
          class="btn btn-red"
          type="button"
        >
          <span v-if="loading_mail"
            >Mise à jour <b-spinner small label="Small Spinner"></b-spinner
          ></span>
          <span v-else>Changer le mail</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal id="modal-update-userpicture" title="Changer votre photo ">
      <template v-slot:default="{ hide }">
        <b-form autocomplete="off">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
            v-if="message"
          >
            <strong>Attention!</strong> {{ message }}
          </div>
          <b-form-group label="Nouvelle photo de profil">
            <input
              :class="errors.picture ? 'is-invalid' : ''"
              class="form-control"
              type="file"
              v-on:change="onImageChange"
            />
            <span class="text-danger" v-if="errors.picture">{{
              errors.picture[0]
            }}</span>
          </b-form-group>
        </b-form>
      </template>
      <div slot="modal-footer">
        <a @click="hidePictureModal()" class="link-red mx-1">Annuler</a>
        <b-button
          :disabled="loading_picture"
          @click="updateUserProfilPicture()"
          class="btn btn-red"
          type="button"
        >
          <span v-if="loading_picture"
            >Mise à jour <b-spinner small label="Small Spinner"></b-spinner
          ></span>
          <span v-else>Mettre à jour</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal id="modal-update-password" title="Changer le mot de passe">
      <template v-slot:default="{ hide }">
        <b-form autocomplete="off">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
            v-if="message"
          >
            <strong>Attention!</strong> {{ message }}
          </div>
          <b-form-group>
            <h3 class="auth-title mb-3">Ancien mot de passe</h3>
            <input
              :class="errors.old_password ? 'is-invalid' : ''"
              class="form-control"
              type="password"
              v-model="form_update_password.old_password"
              v-on:input="resetErrors('old_password')"
            />
            <span class="text-danger" v-if="errors.old_password">{{
              errors.old_password[0]
            }}</span>
          </b-form-group>
          <b-form-group>
            <h3 class="auth-title mb-3">Nouveau mot de passe</h3>
            <input
              :class="errors.new_password ? 'is-invalid' : ''"
              class="form-control"
              type="password"
              v-model="form_update_password.new_password"
              v-on:input="resetErrors('new_password')"
            />
            <span class="text-danger" v-if="errors.new_password">{{
              errors.new_password[0]
            }}</span>
            <div class="form-info">
              Le mot de passe doit contenir au moins huit caractères, dont au
              moins une lettre et un chiffre.
            </div>
          </b-form-group>
        </b-form>
      </template>
      <div slot="modal-footer">
        <a @click="resetErrors()" class="link-red mx-1">Annuler</a>
        <b-button
          :disabled="loading_password"
          @click="changePassword()"
          class="btn btn-red"
          type="button"
        >
          <span v-if="loading_password"
            >Mise à jour <b-spinner small label="Small Spinner"></b-spinner
          ></span>
          <span v-else>Changer le mot de passe</span>
        </b-button>
      </div>
    </b-modal>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="back-item mb-4">
            <button @click="$router.go(-1)" class="btn back-item__btn">
              Retour
            </button>
            <h3 class="back-item__title">Mon Profil</h3>
          </div>
        </div>
      </div>
      <div class="text-center" v-if="loading">
        <div class="row">
          <div class="col">
            <div class="profile-box">
              <b-spinner
                variant="primary"
                label="Text Centered"
                style="width: 8rem; height: 8rem"
              ></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col">
            <div class="profile-box">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <div class="profile-preview">
                    <div class="profile-preview__photo">
                      <img
                        :src="web_site_url + form.path_picture"
                        alt="..."
                        class="profile-preview__img d-block img-fluid"
                        id="profil_picture"
                      />
                      <button
                        @click="showUpdatePictureModal()"
                        class="profile-preview__btn btn"
                      >
                        <svg
                          fill="none"
                          height="20"
                          viewBox="0 0 20 20"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M5.78077 4.87115C5.83981 4.78907 5.90538 4.68859 5.989 4.5531C6.01955 4.5036 6.10287 4.36614 6.16489 4.2638L6.23475 4.14868C6.92557 3.01866 7.42775 2.5 8.33325 2.5H11.6666C12.5721 2.5 13.0743 3.01866 13.7651 4.14868L13.835 4.26395C13.8971 4.36627 13.9803 4.50362 14.0108 4.5531C14.0945 4.68859 14.16 4.78907 14.2191 4.87115C14.258 4.92527 14.292 4.96828 14.3198 5H16.6666C18.0473 5 19.1666 6.11929 19.1666 7.5V15C19.1666 16.3807 18.0473 17.5 16.6666 17.5H3.33325C1.95254 17.5 0.833252 16.3807 0.833252 15V7.5C0.833252 6.11929 1.95254 5 3.33325 5H5.68008C5.70783 4.96828 5.74183 4.92527 5.78077 4.87115ZM3.33325 6.66667C2.87301 6.66667 2.49992 7.03976 2.49992 7.5V15C2.49992 15.4602 2.87301 15.8333 3.33325 15.8333H16.6666C17.1268 15.8333 17.4999 15.4602 17.4999 15V7.5C17.4999 7.03976 17.1268 6.66667 16.6666 6.66667H14.1666C13.613 6.66667 13.2396 6.36367 12.8661 5.84438C12.7801 5.7249 12.695 5.59443 12.5925 5.42841C12.5574 5.37156 12.4664 5.22131 12.4038 5.11812L12.4038 5.11804L12.3431 5.01799C11.9662 4.40144 11.7389 4.16667 11.6666 4.16667H8.33325C8.26098 4.16667 8.03367 4.40144 7.65675 5.01799L7.59615 5.11787C7.5336 5.22107 7.44242 5.37152 7.40731 5.42841C7.30485 5.59443 7.21971 5.7249 7.13376 5.84438C6.76023 6.36367 6.38687 6.66667 5.83325 6.66667H3.33325ZM16.6666 8.33333C16.6666 8.79357 16.2935 9.16667 15.8333 9.16667C15.373 9.16667 14.9999 8.79357 14.9999 8.33333C14.9999 7.8731 15.373 7.5 15.8333 7.5C16.2935 7.5 16.6666 7.8731 16.6666 8.33333ZM5.83325 10.8333C5.83325 13.1345 7.69873 15 9.99992 15C12.3011 15 14.1666 13.1345 14.1666 10.8333C14.1666 8.53215 12.3011 6.66667 9.99992 6.66667C7.69873 6.66667 5.83325 8.53215 5.83325 10.8333ZM12.4999 10.8333C12.4999 12.214 11.3806 13.3333 9.99992 13.3333C8.61921 13.3333 7.49992 12.214 7.49992 10.8333C7.49992 9.45262 8.61921 8.33333 9.99992 8.33333C11.3806 8.33333 12.4999 9.45262 12.4999 10.8333Z"
                            fill="white"
                            fill-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <div class="profile-preview__size">500x500px max</div>
                    <h3 class="profile-preview__name">
                      {{ curr_user.nom + " " + curr_user.prenoms }}
                    </h3>
                    <div class="profile-preview__job">
                      {{ user_identity.email }}
                    </div>

                    <ul class="profile-list">
                      <li class="profile-list-item">
                        <div class="profile-list-item__name">Email</div>
                        <div class="profile-list-item__value">
                          {{ form.email }}
                        </div>
                        <a
                          @click="resetEmailForm()"
                          class="profile-list-item__link"
                          v-b-modal.modal-update-mail
                          >Changer le mail</a
                        >
                      </li>
                      <li class="profile-list-item">
                        <div class="profile-list-item__name">Mot de passe</div>
                        <a
                          class="profile-list-item__link"
                          v-b-modal.modal-update-password
                          v-on:click="resetPasswordForm()"
                          >Changer le mot de passe</a
                        >
                      </li>
                      <li class="profile-list-item">
                        <div align="center">
                          <button
                            @click="updateUserData()"
                            :disabled="loading_update"
                            class="btn btn-red"
                            type="submit"
                          >
                            <span v-if="loading_update"
                              >Mise à jour en cours
                              <b-spinner small label="Small Spinner"></b-spinner
                            ></span>
                            <span v-else>Mettre à jour</span>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-8 col-lg-8">
                  <form class="form-register">
                    <h3 class="auth-title">Ravi de vous revoir</h3>
                    <div class="auth-subtitle">
                      Veuillez rentrer les informations suivantes
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Nom</span>
                            <input
                              :class="errors.nom ? 'is-invalid' : ''"
                              class="form-control"
                              type="text"
                              v-model="form.nom"
                              v-on:input="resetErrors('nom')"
                            />
                          </label>
                          <span class="text-danger" v-if="errors.nom">{{
                            errors.nom[0]
                          }}</span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Prénom(s)</span>
                            <input
                              :class="errors.prenoms ? 'is-invalid' : ''"
                              class="form-control"
                              type="text"
                              v-model="form.prenoms"
                              v-on:input="resetErrors('prenoms')"
                            />
                          </label>
                          <span class="text-danger" v-if="errors.prenoms">{{
                            errors.prenoms[0]
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Nationalité</span>
                            <b-form-select
                              :class="errors.nationalite ? 'is-invalid' : ''"
                              :options="pays"
                              class="mb-3"
                              text-field="nom_fr_fr"
                              v-model="form.nationalite"
                              v-on:change="resetErrors('nationalite')"
                              value-field="nom_fr_fr"
                            >
                              <!-- This slot appears above the options from 'options' prop -->
                              <template v-slot:first>
                                <option :value="null" disabled>
                                  -- Sélectionnez un pays --
                                </option>
                              </template>
                            </b-form-select>
                          </label>
                          <span class="text-danger" v-if="errors.nationalite">{{
                            errors.nationalite[0]
                          }}</span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Pays de résidence</span>
                            <b-form-select
                              :class="errors.pays_residence ? 'is-invalid' : ''"
                              :options="pays"
                              class="mb-3"
                              text-field="nom_fr_fr"
                              v-model="form.pays_residence"
                              v-on:change="resetErrors('pays_residence')"
                              value-field="nom_fr_fr"
                            >
                              <!-- This slot appears above the options from 'options' prop -->
                              <template v-slot:first>
                                <option :value="null" disabled>
                                  -- Sélectionnez un pays --
                                </option>
                              </template>
                            </b-form-select>
                            <span
                              class="text-danger"
                              v-if="errors.pays_residence"
                              >{{ errors.pays_residence[0] }}</span
                            >
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Téléphone</span>
                            <input
                              :class="errors.telephone ? 'is-invalid' : ''"
                              class="form-control"
                              min="0"
                              placeholder="Ex:0022998435432"
                              type="number"
                              v-model="form.telephone"
                              v-on:input="resetErrors('telephone')"
                            />
                          </label>
                          <span class="text-danger" v-if="errors.telephone">{{
                            errors.telephone[0]
                          }}</span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Profession</span>
                            <b-form-select
                              :class="errors.profession ? 'is-invalid' : ''"
                              :options="profession"
                              class="form-control"
                              v-model="form.profession"
                              v-on:change="resetErrors('profession')"
                            >
                              <!-- This slot appears above the options from 'options' prop -->
                              <template v-slot:first>
                                <option :value="null" disabled>
                                  -- Sélectionnez une profession --
                                </option>
                              </template>
                            </b-form-select>
                          </label>
                          <span class="text-danger" v-if="errors.profession">{{
                            errors.profession[0]
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Employeur</span>
                            <input
                              :class="errors.employeur ? 'is-invalid' : ''"
                              class="form-control"
                              type="text"
                              v-model="form.employeur"
                              v-on:v-on:input="resetErrors('employeur')"
                            />
                          </label>
                          <span class="text-danger" v-if="errors.employeur">{{
                            errors.employeur[0]
                          }}</span>
                        </div>
                      </div>
                      <div class="field-radios mb-3">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Sexe</span>
                            <div class="field-radios">
                              <label class="field-radio-item">
                                <input
                                  class="check-custom"
                                  name="gender"
                                  type="radio"
                                  v-model="form.sexe"
                                  v-on:change="resetErrors('sexe')"
                                  value="M"
                                />
                                <span class="check-toggle"></span>
                                <span class="name">Masculin</span>
                              </label>
                              <label class="field-radio-item">
                                <input
                                  class="check-custom"
                                  name="gender"
                                  type="radio"
                                  v-model="form.sexe"
                                  v-on:change="resetErrors('sexe')"
                                  value="F"
                                />
                                <span class="check-toggle"></span>
                                <span class="name">Féminin</span>
                              </label>
                            </div>
                          </label>
                          <span class="text-danger" v-if="errors.sexe">{{
                            errors.sexe[0]
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="app-label">
                            <span>Biographie</span>
                            <textarea
                              :class="errors.biographie ? 'is-invalid' : ''"
                              class="form-control"
                              rows="3"
                              v-model="form.biographie"
                              v-on:input="resetErrors('biographie')"
                            ></textarea>
                          </label>
                          <span class="text-danger" v-if="errors.biographie">{{
                            errors.biographie[0]
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <div class="profile-box">
              <div align="center">
                <h1>Mon parcours</h1>
                <a @click="addParcours()" class="btn btn-red text-white mx-1"
                  >Ajouter</a
                ><br /><br />
              </div>
              <div class="row" v-if="form.parcours.length > 0">
                <div class="container">
                  <div
                    class="row"
                    :key="key"
                    v-for="(item, key) in form.parcours"
                  >
                    <b-col cols="2">
                      <b-form-group label="Diplôme">
                        <b-form-select
                          @change="handleDiplomeChange(key, item.id_diplome)"
                          :class="
                            errors['parcours.' + key + '.id_diplome']
                              ? 'is-invalid'
                              : ''
                          "
                          :options="diplomes"
                          text-field="nom"
                          v-model="form.parcours[key].id_diplome"
                          value-field="id"
                        >
                          <template v-slot:first>
                            <option :value="null" disabled>
                              -- Sélectionner un diplôme --
                            </option>
                          </template>
                        </b-form-select>
                        <span
                          class="text-danger"
                          v-if="errors['parcours.' + key + '.id_diplome']"
                          >{{
                            errors["parcours." + key + ".id_diplome"][0]
                          }}</span
                        >
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-form-group label="Esgis">
                        <b-form-select
                          :class="
                            errors['parcours.' + key + '.id_esgis']
                              ? 'is-invalid'
                              : ''
                          "
                          :options="esgis"
                          text-field="pays"
                          v-model="form.parcours[key].id_esgis"
                          value-field="id"
                        >
                          <template v-slot:first>
                            <option :value="null" disabled>
                              -- Sélectionner un ESGIS --
                            </option>
                          </template>
                        </b-form-select>
                        <span
                          class="text-danger"
                          v-if="errors['parcours.' + key + '.id_esgis']"
                          >{{
                            errors["parcours." + key + ".id_esgis"][0]
                          }}</span
                        >
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-form-group label="Filière">
                        <b-form-select
                          :class="
                            errors['parcours.' + key + '.id_filiere']
                              ? 'is-invalid'
                              : ''
                          "
                          :options="item.filieres"
                          text-field="nom"
                          v-model="form.parcours[key].id_filiere"
                          value-field="id"
                        >
                          <template v-slot:first>
                            <option :value="null" disabled>
                              -- Sélectionner une filière --
                            </option>
                          </template>
                        </b-form-select>
                        <span
                          class="text-danger"
                          v-if="errors['parcours.' + key + '.id_filiere']"
                          >{{
                            errors["parcours." + key + ".id_filiere"][0]
                          }}</span
                        >
                      </b-form-group>
                    </b-col>

                    <div class="col-lg-1">
                      <b-form-group label="Début">
                        <label class="app-label">
                          <input
                            :class="
                              errors['parcours.' + key + '.start_year']
                                ? 'is-invalid'
                                : ''
                            "
                            class="form-control"
                            type="tel"
                            v-model="form.parcours[key].start_year"
                          />
                        </label>
                        <span
                          class="text-danger"
                          v-if="errors['parcours.' + key + '.start_year']"
                          >{{
                            errors["parcours." + key + ".start_year"][0]
                          }}</span
                        >
                      </b-form-group>
                    </div>
                    <div class="col-lg-1">
                      <b-form-group label="Fin">
                        <input
                          :class="
                            errors['parcours.' + key + '.end_year']
                              ? 'is-invalid'
                              : ''
                          "
                          class="form-control"
                          type="tel"
                          v-model="form.parcours[key].end_year"
                        />
                        <span
                          class="text-danger"
                          v-if="errors['parcours.' + key + '.end_year']"
                          >{{
                            errors["parcours." + key + ".end_year"][0]
                          }}</span
                        ></b-form-group
                      >
                    </div>
                    <b-col cols="2">
                      <b-form-group label="Diplôme reçu">
                        <b-form-checkbox
                          :id="'checkbox-' + key"
                          v-model="form.parcours[key].success"
                          :name="'checkbox-' + key"
                          value="1"
                          unchecked-value="0"
                        ></b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <!--<b-col cols="1">
                      <b-button
                        @click="deleteParcours(item)"
                        class="btn btn-sm"
                        variant="danger"
                        >Supprimer
                      </b-button>
                    </b-col>-->
                  </div>
                </div>
              </div>
              <div class="container" v-else>
                <div class="row">
                  <div class="col">
                    <div class="student-single-content__content text-center">
                      Auncun résultat
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth, { _buildQuery } from "../../../helper/config";
import { mapGetters, mapState } from "vuex";

export default {
  name: "UpdateProfil",
  data: () => ({
    parcours_index: 0,
    loading: false,
    loading_picture: false,
    loading_mail: false,
    loading_password: false,
    loading_update: false,
    web_site_url: auth.basePath,
    user_identity: null,
    picture: null,
    form: {
      nom: null,
      email: null,
      prenoms: null,
      nationalite: null,
      pays_residence: null,
      profession: null,
      employeur: null,
      //esgis: null,
      //last_grade: null,
      //last_grade_year: null,
      telephone: null,
      sexe: null,
      filiere: null,
      first_year: null,
      biographie: null,
      path_picture: null,
      parcours: [],
    },
    form_update_email: {
      email: null,
    },
    form_update_password: {
      old_password: null,
      new_pasword: null,
    },
    errors: [],
    diplomes: [],
    filieres: [],
    esgis: [],
    message: "",
    curr_year: new Date().getFullYear(),
  }),
  computed: {
    ...mapGetters({
      years: "getYears",
      pays: "getPays",
      profession: "getProfession",
    }),
    ...mapState({
      curr_user: (state) => state.user,
    }),
  },
  created() {
    this.loadCurrUserData();
    this.loadDiplomes();
    this.loadFilieres();
    this.loadEsgis();
  },
  props: {},
  components: {},
  methods: {
    handleDiplomeChange(key, diplome_id) {
      let url = auth.baseApi;
      let params = {
        diplome_id: diplome_id
      };
      axios
        .get(url + "filieres" + _buildQuery(params), {
          headers: { Authorization: "Bearer " + auth.getSession() },
        })
        .then(response => {
          this.form.parcours[key].filieres = response.data;
          //console.log(this.form.parcours[key])
        })
        .catch(error => {
          // eslint-disable-next-line no-constant-condition,no-empty
          if (error.response.status) {
            this.$toasted.global.error();
          }
        });
    },
    addParcours() {
      this.form.parcours.push({
        id: this.parcours_index,
        diplome: null,
        esgis: null,
        start_year: null,
        end_year: null,
        filiere: null,
        success: null,
        filieres: []
      });
    },
    deleteParcours(item) {
      let index = this.form.parcours.indexOf(item);
      this.form.parcours.splice(index, 1);
      --this.parcours_index;
    },
    onImageChange(e) {
      this.picture = e.target.files[0];
    },
    resetErrors(field) {
      if (field) {
        this.errors[field] = null;
      } else {
        this.errors = [];
        this.form.nom = null;
        this.form.prenoms = null;
        this.form.nationalite = null;
        this.form.pays_residence = null;
        this.form.profession = null;
        this.form.employeur = null;
        this.form.esgis = null;
        this.form.last_grade = null;
        this.form.last_grade_year = null;
        this.form.telephone = null;
        this.form.sexe = null;
        this.form.biographie = null;

        this.form_update_email.email = null;
        this.form_update_password.old_password = null;
        this.form_update_password.new_pasword = null;

        this.$bvModal.hide("modal-update-password");
        this.$bvModal.hide("modal-update-mail");
        this.$bvModal.hide("modal-update-userpicture");
      }
    },
    updateUserProfilPicture() {
      if (this.picture) {
        let url = auth.baseApi;
        this.loading_picture = true;
        let user_id = auth.getCurrentUser().id;
        let formData = new FormData();

        formData.append("picture", this.picture);

        axios
          .post(url + "users/picture/" + user_id, formData, {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: "Bearer " + auth.getSession(),
            },
          })
          .then((response) => {
            this.loading_picture = false;
            if (response.data.status === true) {
              let user = localStorage.getItem("user"); //string user + token
              let user_data = JSON.parse(user); //object user + token

              user_data.user[0] = response.data.user;

              let new_local_storage = JSON.stringify(user_data);
              localStorage.setItem("user", new_local_storage); //string user + token
              this.$store.commit("updateUserdata", response.data.user);

              this.$toasted.global.all();
              this.$bvModal.hide("modal-update-userpicture");
              this.message = "";
              this.loadCurrUserData();
            } else this.$toasted.global.error();
          })
          .catch((error) => {
            this.loading_picture = false;
            // eslint-disable-next-line no-constant-condition
            if (error.response.status === 401) {
              this.errors = [];
              this.$toasted.global.all({
                message: "Votre connexion a expirée",
              });
              auth.logout();
              this.$router.push({ name: "login" });
            } else if (error.response.status === 400) {
              this.errors = error.response.data.errors;
            } else if (error.response.status === 422) {
              this.message = error.response.data.errors;
            } else {
              this.$toasted.global.error();
            }
          });
      } else this.message = "Vous devez fournir une photo de profil";
    },
    loadCurrUserData() {
      let url = auth.baseApi;
      this.loading = true;
      this.user_identity = "";
      axios
        .get(url + "users/current", {
          headers: { Authorization: "Bearer " + auth.getSession() },
        })
        .then((response) => {
          this.loading = false;
          this.user_identity = Object.assign({}, response.data[0]);
          this.form = response.data[0];
          this.message = "";
        })
        .catch((error) => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    },
    updateUserData() {
      this.loading_update = true;
      if (this.form.parcours.length > 0) {
        let url = auth.baseApi;
        let user_id = auth.getCurrentUser().id;

        axios
          .put(url + "users/update/" + user_id, this.form, {
            headers: { Authorization: "Bearer " + auth.getSession() },
          })
          .then((response) => {
            this.loading_update = false;
            if (response.data.status === true) {
              let user = localStorage.getItem("user"); //string user + token
              let user_data = JSON.parse(user); //object user + token

              user_data.user[0] = response.data.user;

              let new_local_storage = JSON.stringify(user_data);
              localStorage.setItem("user", new_local_storage); //string user + token
              this.$store.commit("updateUserdata", response.data.user);

              // this.$emit('changeUserData', new_local_storage)

              this.$toasted.global.all();
              this.$router.push({ path: "/admin/show_profil" });
            } else this.$toasted.global.error();
          })
          .catch((error) => {
            this.loading_update = false;
            // eslint-disable-next-line no-constant-condition
            if (error.response.status === 401) {
              this.errors = [];
              this.$toasted.global.all({
                message: "Votre connexion a expirée",
              });
              auth.logout();
              this.$router.push({ name: "login" });
            } else if (error.response.status === 400) {
              this.errors = error.response.data.errors;
            } else {
              this.$toasted.global.error();
            }
          });
      } else {
        this.loading_update = false;
        this.$toasted.global.error({ message: "Ajouter au moins un parcours" });
      }
    },
    changeEmailAdresse() {
      this.loading_mail = true;
      let url = auth.baseApi;
      axios
        .post(url + "email/reset/apply/", this.form_update_email, {
          headers: { Authorization: "Bearer " + auth.getSession() },
        })
        .then((response) => {
          this.loading_mail = false;
          if (response.data.status === true) {
            this.$toasted.global.check();
            this.$bvModal.hide("modal-update-mail");
            auth.logout();
            this.$router.push({ name: "login" });
          } else this.$toasted.global.error();
        })
        .catch((error) => {
          this.loading_mail = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else if (error.response.status === 400) {
            this.errors = error.response.data.errors;
          } else {
            this.$toasted.global.error();
          }
        });
    },
    changePassword() {
      this.loading_password = true;
      let url = auth.baseApi;
      axios
        .put(url + "users/change/password", this.form_update_password, {
          headers: { Authorization: "Bearer " + auth.getSession() },
        })
        .then((response) => {
          this.loading_password = false;
          if (response.data.status === true) {
            this.$toasted.global.all();
            this.$bvModal.hide("modal-update-password");
            auth.logout();
            this.$router.push({ name: "login" });
          } else this.$toasted.global.error();
        })
        .catch((error) => {
          this.loading_password = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else if (error.response.status === 400) {
            this.errors = error.response.data.errors;
          } else if (error.response.status === 422) {
            this.message = error.response.data.message;
          } else {
            this.$toasted.global.error();
          }
        });
    },
    resetEmailForm() {
      this.resetErrors("email");
      this.form_update_email.email = null;
    },
    resetPasswordForm() {
      this.resetErrors("old_password");
      this.resetErrors("new_password");
      this.form_update_password.old_password = null;
      this.form_update_password.new_pasword = null;
    },
    showUpdatePictureModal() {
      this.message = "";
      this.$bvModal.show("modal-update-userpicture");
    },
    hidePictureModal() {
      this.$bvModal.hide("modal-update-userpicture");
    },
    loadDiplomes() {
      let url = auth.baseApi;
      this.loading = true;
      axios
        .get(url + "diplomes", {
          headers: { Authorization: "Bearer " + auth.getSession() },
        })
        .then((response) => {
          this.loading = false;
          this.diplomes = response.data;
        })
        .catch((error) => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition,no-empty
          if (error.response.status) {
          }
        });
    },
    loadFilieres(diplome_id = null) {
      let url = auth.baseApi;
      //this.loading = true;
      let params = {
        diplome_id: diplome_id,
      };
      axios
        .get(url + "filieres" + _buildQuery(params), {
          headers: { Authorization: "Bearer " + auth.getSession() },
        })
        .then((response) => {
          //this.loading = false;
          this.filieres = response.data;
        })
        .catch((error) => {
          // this.loading = false;
          // eslint-disable-next-line no-constant-condition,no-empty
          if (error.response.status) {
          }
        });
    },
    loadEsgis() {
      let url = auth.baseApi;
      this.loading = true;
      axios
        .get(url + "esgis", {
          headers: { Authorization: "Bearer " + auth.getSession() },
        })
        .then((response) => {
          this.loading = false;
          this.esgis = response.data;
        })
        .catch((error) => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition,no-empty
          if (error.response.status) {
          }
        });
    },
  },
};
</script>

<style scoped>
.profil-content {
  padding-bottom: 100px;
}

#profil_picture {
  -moz-border-radius: 700px;
  -webkit-border-radius: 700px;
  border-radius: 700px;
}
</style>
