<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner row">
        <div class="footer__brand-wrapper col-md-3">
          <a
            class="footer__brand"
            href="https://ebusinessafrique.com/"
            target="_blank"
          >
            <span>By</span>
            e-Business Afrique</a
          >
        </div>
        <div class="col-md-3">
          <ul class="footer-list footer-list--inline">
            <li class="footer-list__item">Suivez-nous</li>
            <li class="footer-list__item">
              <ul class="social-list">
                <li class="social-list__item">
                  <a
                    class="btn btn--no-padding btn-social btn-social--facebook"
                    href="https://web.facebook.com/esgisalumni/"
                    target="_blank"
                  >
                    <svg
                      fill="none"
                      height="13"
                      viewBox="0 0 6 13"
                      width="6"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.75839 6.0691H3.97873V12.1616H1.28239V6.0691H0V3.92794H1.28239V2.54237C1.28239 1.55154 1.78606 0 4.00273 0L6 0.0078082V2.08616H4.55085C4.31315 2.08616 3.9789 2.19714 3.9789 2.6698V3.92994H5.99396L5.75839 6.0691Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <a
            @click="
              $router
                .push({
                  name: 'terms'
                })
                .catch(err => {})
            "
            >Conditions d'utilisation</a
          >
        </div>
        <div class="col-md-3 footer__copyright text-left">
          Copyright © 2019 ESGIS Alumni
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footerBar"
};
</script>

<style scoped>
.footer__brand {
  text-align: right;
}
</style>
