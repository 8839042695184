import Vue from "vue";
import Toasted from "vue-toasted";

Vue.use(Toasted);
Vue.toasted.register(
    "error",
    payload => {
        if (!payload.message) {
            return "Oups quelque chose s'est mal passé";
        }
        return "Oops.. " + payload.message;
    },
    {
        type: "error",
        duration: 4000
    }
);
Vue.toasted.register(
    "all",
    payload => {
        if (!payload.message) {
            return "Opération effectuée";
        }
        return `${payload.message}`;
    },
    {
        type: "success",
        duration: 4000
    }
);

Vue.toasted.register(
    "check",
    payload => {
        if (!payload.message) {
            return "Opération effectuée, vérifiez vos emails";
        }
        return `${payload.message}`;
    },
    {
        type: "success",
        duration: 4000
    }
);
export default {
    /*DEV*/
    // baseApi: "http://127.0.0.1:8090/api/",
    // basePath: "http://127.0.0.1:8090/",

    /*TEST*/
    /*baseApi: "http://165.22.28.198:83/api/",
    basePath: "http://165.22.28.198:83/",*/

    /*PROD*/
    baseApi: "https://api.esgisalumni.com/api/",
    basePath: "https://api.esgisalumni.com/",

    authenticate(data) {
        localStorage.setItem("user", JSON.stringify(data));
    },
    getToken() {
        let u = JSON.parse(localStorage.getItem("user"));
        if (u) {
            return u;
        } else {
            return null;
        }
    },

    getSession() {
        return JSON.parse(localStorage.getItem("user")).token;
    },

    logout() {
        delete localStorage.user;
    },

    loggedIn() {
        return !!localStorage.user;
    },

    getCurrentUser() {
        const data = localStorage.getItem("user");
        if (data) return JSON.parse(data).user[0];
        else return undefined;
    }
};

export function _buildQuery(params) {
    let string = "";

    for (let key in params) {
        if (params[key] !== "" && params[key] !== null) {
            string += `${key}=${params[key]}&`;
        }
    }
    string = "?" + string.substring(0, string.length - 1);
    return string;
}
