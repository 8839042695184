<template>
    <div class="section">
        <div class="container" ref="actu">
            <div class="row">
                <div class="col">
                    <div class="section-heading section-heading--center">
                        <h2 class="section-title">Nos Actualités</h2>
                    </div>
                </div>
            </div>
            <div class="text-center" v-if="loading">
                <div class="row">
                    <div class="container">
                        <div class="col">
                            <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                    style="width: 8rem; height: 8rem;"
                            ></b-spinner>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="datas.length > 0" >
                <div
                        :key="cpt"
                        class="post-item-wrapper col-md-6 col-lg-4 mt-2"
                        v-for="(actuality, cpt) in datas"
                >
                    <!--<a class="post-item">-->
                    <a
                            @click="
              $router
                .push({
                  name: 'actuality_details',
                  params: { id: actuality.id }
                })
                .catch(err => {})
            "
                            class="post-item"
                    >
                        <img
                                :src="web_site_url + actuality.path_picture"
                                alt="..."
                                class="service-item__img img-fluid" style="height: 200px; width: 100%"
                        />
                        <div class="post-item__body">
                            <h3 class="post-item__title">
                                {{ actuality.titre | truncate(30) }}
                            </h3>
                            <!-- <div class="post-item__content">
                                {{ actuality.contenu | truncate(80) }}
                            </div> -->
                            <div class="post-item__content" :inner-html.prop="actuality.contenu | truncate(80)"></div>
                            <div class="text-right">
                <span style="font-size: 11px"
                >Publié le {{ format_date(actuality.date_publication) }}</span
                >
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth, {_buildQuery} from "../../../helper/config";
    import axios from "axios";
    import moment from "moment";

    export default {
        name: "homeActualities",
        data: () => ({
            loading: false,
            datas: [],
            web_site_url: auth.basePath
        }),
        props: {
          scroll: {
              required: true
          }
        },
        mounted() {
            this.loadActualities();

        },
        methods: {
            format_date(value) {
                if (value) {
                    return moment(String(value)).format("DD-MM-YYYY");
                }
            },
            loadActualities() {
                if (this.scroll === 1){
                    const el = this.$refs.actu;

                    if (el) {
                        // Use el.scrollIntoView() to instantly scroll to the element
                        el.scrollIntoView({behavior: 'smooth'});
                    }
                }

                this.loading = true;
                const params = {
                   draft: 0
                };
                axios
                    .get(auth.baseApi + "guest/actualities" + _buildQuery(params))
                    .then(response => {
                        this.loading = false;
                        this.datas = response.data;

                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.data.status) {
                            this.$toasted.global.error();
                        }
                    });
            }
        }
    };
</script>

<style scoped>
    .post-item {
        /*height: 274px;*/
    }

    .post-item__content {
        height: 80px;
    }
</style>
