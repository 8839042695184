<template>
  <div>
    <nav
      aria-label="pagination"
      class="pagination is-centered is-rounded"
      role="navigation"
    >
      <button
        :disabled="pagination.current_page <= 1"
        @click.prevent="changePage(pagination.current_page - 1)"
        class="btn"
      >
        PRÉCEDENT
      </button>
      <ul class="pagination-items">
        <li :key="page" v-for="page in pages">
          <button
            :class="isCurrentPage(page) ? 'is-active' : ''"
            @click.prevent="changePage(page)"
            class="pagination-item btn"
          >
            {{ page }}
          </button>
        </li>
      </ul>
      <button
        :disabled="pagination.current_page >= pagination.last_page"
        @click.prevent="changePage(pagination.current_page + 1)"
        class="btn"
      >
        SUIVANT
      </button>
    </nav>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: ["pagination", "offset"],
  methods: {
    isCurrentPage(page) {
      return this.pagination.current_page === page;
    },
    changePage(page) {
      if (page > this.pagination.last_page) {
        page = this.pagination.last_page;
      }
      this.pagination.current_page = page;
      this.$emit("paginate");
    }
  },
  computed: {
    pages() {
      let pages = [];
      let from = this.pagination.current_page - Math.floor(this.offset / 2);
      if (from < 1) {
        from = 1;
      }
      let to = from + this.offset - 1;
      if (to > this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      while (from <= to) {
        pages.push(from);
        from++;
      }
      return pages;
    }
  }
};
</script>

<style scoped>
.pagination {
  margin-top: 40px;
}
</style>
