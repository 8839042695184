import Vue from "vue";
import Vuex from "vuex";
import auth from "../helper/config";
import paysData from "./pays";
import professions from "./professions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: auth.getCurrentUser() ? auth.getCurrentUser() : {},
    curr_year: new Date().getFullYear(),
    years: [],
    pays: [],
    profession: []
  },
  mutations: {
    updateUserdata(state, new_user) {
      // mutate state
      state.user = new_user;
    }
  },
  actions: {},
  modules: {},
  getters: {
    getYears: state => {
      let cpt = 0;
      for (let i = 1994; i <= state.curr_year; i++) {
        state.years[cpt] = { value: i, text: i };
        cpt++;
      }
      return state.years;
    },
    getPays: state => {
      state.pays = paysData;
      return state.pays;
    },
    getProfession: state => {
      state.profession = professions;
      return state.profession;
    }
  }
});
