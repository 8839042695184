<template>
  <div class="guest">
    <top-bar></top-bar>
    <router-view />
    <footer-bar></footer-bar>
    <!-- End Right content here -->
  </div>
</template>

<script>
import footerBar from "./footerBar";
import TopBar from "@/views/shared/topBar";

export default {
  name: "DefaultGuestContainer",
  components: {
    TopBar,
    footerBar
  },
  methods: {}
};
</script>

<style scoped></style>
