<template>
    <main class="main main--gray col-md-12 text-justify">
        <div class="post-single-banner">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="post-single__title text-center">
                            Suppression des données utilisateurs:
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="auth-box22">
            <div class="auth-box__main">
                <h1>Suppression des données utilisateurs: </h1><br>
            </div>
        </div>
        <br/><br/><br/><br/>
    </main>
</template>

<script>
    export default {
        name: "termsAndCondtions"
    };
</script>

<style scoped>
    /*div .terms {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
        }*/
    .auth-box22 {
        position: relative;
        width: 800px;
        max-width: 90%;
        margin: 30px auto 0px;
    }
</style>
