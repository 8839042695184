<template>
  <main class="main main--gray">
    <div class="container">
      <div class="auth-register row">
        <div class="auth-register-left col-md-4 col-lg-4"></div>
        <div class="auth-register-right col-md-8 col-lg-8">
          <form @submit.prevent="register" class="form-register">
            <h3 class="auth-title">Ravi de vous revoir</h3>
            <div class="auth-subtitle">
              Veuillez rentrer les informations suivantes
            </div>
            <div class="field-radios mb-3">
              <div class="form-group">
                <label class="app-label">
                  <div class="field-radios">
                    <label class="field-radio-item">
                      <input
                        class="check-custom"
                        name="gender"
                        type="radio"
                        v-model="form.sexe"
                        v-on:change="resetErrors('sexe')"
                        value="M"
                      />
                      <span class="check-toggle"></span>
                      <span class="name">Masculin</span>
                    </label>
                    <label class="field-radio-item">
                      <input
                        class="check-custom"
                        name="gender"
                        type="radio"
                        v-model="form.sexe"
                        v-on:change="resetErrors('sexe')"
                        value="F"
                      />
                      <span class="check-toggle"></span>
                      <span class="name">Féminin</span>
                    </label>
                  </div>
                </label>
                <span class="text-danger" v-if="errors.sexe">{{
                  errors.sexe[0]
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label class="app-label">
                    <span>Adresse email</span>
                    <input
                      :class="errors.email ? 'is-invalid' : ''"
                      class="form-control"
                      type="email"
                      v-model="form.email"
                      v-on:input="resetErrors('email')"
                    />
                  </label>
                  <span class="text-danger" v-if="errors.email">{{
                    errors.email[0]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Nom</span>
                    <input
                      :class="errors.nom ? 'is-invalid' : ''"
                      class="form-control"
                      type="text"
                      v-model="form.nom"
                      v-on:input="resetErrors('nom')"
                    />
                  </label>
                  <span class="text-danger" v-if="errors.nom">{{
                    errors.nom[0]
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Prénom(s)</span>
                    <input
                      :class="errors.prenoms ? 'is-invalid' : ''"
                      class="form-control"
                      type="text"
                      v-model="form.prenoms"
                      v-on:input="resetErrors('prenoms')"
                    />
                  </label>
                  <span class="text-danger" v-if="errors.prenoms">{{
                    errors.prenoms[0]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Nationalité</span>
                    <b-form-select
                      :class="errors.nationalite ? 'is-invalid' : ''"
                      :options="pays"
                      class="mb-3"
                      text-field="nom_fr_fr"
                      v-model="form.nationalite"
                      v-on:change="resetErrors('nationalite')"
                      value-field="nom_fr_fr"
                    >
                      <!-- This slot appears above the options from 'options' prop -->
                      <template v-slot:first>
                        <option :value="null" disabled
                          >-- Sélectionnez un pays --
                        </option>
                      </template>
                    </b-form-select>
                  </label>
                  <span class="text-danger" v-if="errors.nationalite">{{
                    errors.nationalite[0]
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Pays de résidence</span>
                    <b-form-select
                      :class="errors.pays_residence ? 'is-invalid' : ''"
                      :options="pays"
                      class="mb-3"
                      text-field="nom_fr_fr"
                      v-model="form.pays_residence"
                      v-on:change="resetErrors('pays_residence')"
                      value-field="nom_fr_fr"
                    >
                      <!-- This slot appears above the options from 'options' prop -->
                      <template v-slot:first>
                        <option :value="null" disabled
                          >-- Sélectionnez un pays --
                        </option>
                      </template>
                    </b-form-select>
                    <span class="text-danger" v-if="errors.pays_residence">{{
                      errors.pays_residence[0]
                    }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Téléphone</span>
                    <input
                      :class="errors.telephone ? 'is-invalid' : ''"
                      class="form-control"
                      min="0"
                      placeholder="Ex:0022998435432"
                      type="number"
                      v-model="form.telephone"
                      v-on:input="resetErrors('telephone')"
                    />
                  </label>
                  <span class="text-danger" v-if="errors.telephone">{{
                    errors.telephone[0]
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Profession</span>
                    <b-form-select
                      :class="errors.profession ? 'is-invalid' : ''"
                      :options="profession"
                      class="mb-3"
                      v-model="form.profession"
                      v-on:change="resetErrors('profession')"
                    >
                      <!-- This slot appears above the options from 'options' prop -->
                      <template v-slot:first>
                        <option :value="null" disabled
                          >-- Sélectionnez une profession --
                        </option>
                      </template>
                    </b-form-select>
                  </label>
                  <span class="text-danger" v-if="errors.profession">{{
                    errors.profession[0]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Employeur</span>
                    <input
                      :class="errors.employeur ? 'is-invalid' : ''"
                      class="form-control"
                      type="text"
                      v-model="form.employeur"
                      v-on:v-on:input="resetErrors('employeur')"
                    />
                  </label>
                  <span class="text-danger" v-if="errors.employeur">{{
                    errors.employeur[0]
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Préciser ESGIS</span>
                    <div class="field-radios">
                      <label class="field-radio-item">
                        <input
                          checked=""
                          class="check-custom"
                          name="choose"
                          type="radio"
                          v-model="form.esgis"
                          v-on:change="resetErrors('esgis')"
                          value="Togo"
                        />
                        <span class="check-toggle"></span>
                        <span class="name">Togo</span>
                      </label>
                      <label class="field-radio-item">
                        <input
                          class="check-custom"
                          name="choose"
                          type="radio"
                          v-model="form.esgis"
                          v-on:change="resetErrors('esgis')"
                          value="Bénin"
                        />
                        <span class="check-toggle"></span>
                        <span class="name">Bénin</span>
                      </label>
                      <label class="field-radio-item">
                        <input
                          class="check-custom"
                          name="choose"
                          type="radio"
                          v-model="form.esgis"
                          v-on:change="resetErrors('esgis')"
                          value="Gabon"
                        />
                        <span class="check-toggle"></span>
                        <span class="name">Gabon</span>
                      </label>
                    </div>
                  </label>
                  <span class="text-danger" v-if="errors.esgis">{{
                    errors.esgis[0]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Dernier diplôme</span>
                    <select
                      :class="errors.last_grade ? 'is-invalid' : ''"
                      class="form-control"
                      v-model="form.last_grade"
                      v-on:change="resetErrors('last_grade')"
                    >
                      <option value="null">-- Choisissez un diplôme --</option>
                      <option value="BTS">BTS</option>
                      <option value="Licence">Licence</option>
                      <option value="Master">Master</option>
                    </select>
                  </label>
                  <span class="text-danger" v-if="errors.last_grade">{{
                    errors.last_grade[0]
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Année d’obtention du diplôme</span>
                    <b-form-select
                      :class="errors.last_grade_year ? 'is-invalid' : ''"
                      :options="years"
                      class="mb-3"
                      v-model="form.last_grade_year"
                      v-on:change="resetErrors('last_grade_year')"
                    >
                      <!-- This slot appears above the options from 'options' prop -->
                      <template v-slot:first>
                        <option :value="null" disabled
                          >-- Sélectionnez une année --
                        </option>
                      </template>
                    </b-form-select>
                  </label>
                  <span class="text-danger" v-if="errors.last_grade_year">{{
                    errors.last_grade_year[0]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Mot de passe</span>
                    <input
                      :class="errors.password ? 'is-invalid' : ''"
                      class="form-control"
                      type="password"
                      v-model="form.password"
                      v-on:input="resetErrors('password')"
                    />
                    <div class="form-info">
                      Le mot de passe doit contenir au moins huit caractères,
                      dont au moins une lettre et un chiffre.
                    </div>
                  </label>
                  <span class="text-danger" v-if="errors.password">{{
                    errors.password[0]
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="app-label">
                    <span>Confirmer le mot de passe</span>
                    <input
                      :class="errors.password_confirmation ? 'is-invalid' : ''"
                      class="form-control"
                      type="password"
                      v-model="form.password_confirmation"
                      v-on:input="resetErrors('password_confirmation')"
                    />
                  </label>
                  <span
                    class="text-danger"
                    v-if="errors.password_confirmation"
                    >{{ errors.password_confirmation[0] }}</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  unchecked-value="not_accepted"
                  v-model="status"
                  value="accepted"
                >
                  {{ $route.query.profession }}
                  <router-link to="/terms"
                    >J'accepte les conditions d'utilisation
                  </router-link>
                </b-form-checkbox>
                <span class="text-danger" v-if="message">{{ message }}</span>
              </div>
            </div>
            <div align="center">
              <button :disabled="loading" class="btn btn-red" type="submit">
                <span v-if="loading">Création en cours...</span>
                <span v-else>Créer le compte</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>/* eslint-disable */
import axios from "axios";
import auth from "../../../helper/config";
import {mapGetters} from "vuex";

export default {
    name: "register",
    data: () => ({
        status: 'not_accepted',
        message: '',
        loading: false,
        current_year: new Date().getFullYear(),
        form: {
            nom: null,
            prenoms: null,
            email: null,
            nationalite: null,
            pays_residence: null,
            profession: null,
            employeur: null,
            esgis: null,
            last_grade: null,
            last_grade_year: null,
            password: null,
            password_confirmation: null,
            telephone: null,
            sexe: null,
            filiere: null,
            first_year: null,
        },
        errors: [],
        last_grade_years: []
    }),
    computed: {
        ...mapGetters({
            years: "getYears",
            pays: "getPays",
            profession: "getProfession"
        }),
    },
    watch: {
        "$route.query.search": function () {
            alert('1');
            if (this.$route.query.search) {
                alert(this.$route.query.search);
                this.form.profession = this.$route.query.search;
            }
        }
    },
    created() {
        //this.setYearsPeriod()
    },
    methods: {

        resetErrors(field) {
            if (field) {
                this.errors[field] = null
            } else {
                this.errors = []
                this.form.nom = null
                this.form.prenoms = null
                this.form.email = null
                this.form.nationalite = null
                this.form.pays_residence = null
                this.form.profession = null
                this.form.employeur = null
                this.form.esgis = null
                this.form.last_grade = null
                this.form.last_grade_year = null
                this.form.password = null
                this.form.password_confirmation = null
                this.form.telephone = null
                this.form.sexe = null
            }
        },
        register() {
            if (this.status !== 'not_accepted') {
                let url = auth.baseApi;
                this.loading = true;
                this.message = ""
                axios
                    .post(url + "register/student", this.form)
                    .then(response => {
                        this.loading = false;
                        if (response.data.status === true) {
                            this.$toasted.global.all({message: 'Inscription réussie. Consultez vos emails'})
                            this.$router.push({name: "login"});
                        } else this.$toasted.global.error()
                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if ((error.response.status == 400)) {
                            this.errors = error.response.data.errors;
                        } else this.$toasted.global.error()
                    });
            } else this.message = "Vous devez approuver les conditions d'utilisation"
        }
    }
};
</script>

<style scoped>
    .auth-register-left {
        background-image: url("../../../assets/img/auth-img.jpg");
    }
</style>
