<template>
  <div>
    <div class="text-center" v-if="loading">
      <div class="row">
        <div class="container">
          <div class="col">
            <div class="profile-box">
              <b-spinner
                variant="primary"
                label="Text Centered"
                style="width: 8rem; height: 8rem;"
              ></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-modal id="modal-advanced-search" size="lg" title="Recherche avancée">
        <form class="form-modal">
          <h3 class="auth-title mb-3">Recherche avancée</h3>

          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label class="app-label">
                  <span>Sexe</span>
                  <div class="field-radios">
                    <label class="field-radio-item">
                      <input
                        checked=""
                        class="check-custom"
                        name="choose"
                        type="radio"
                        v-model="query.sexe"
                        value="M"
                      />
                      <span class="check-toggle"></span>
                      <span class="name">Masculin</span>
                    </label>
                    <label class="field-radio-item">
                      <input
                        class="check-custom"
                        name="choose"
                        type="radio"
                        v-model="query.sexe"
                        value="F"
                      />
                      <span class="check-toggle"></span>
                      <span class="name">Féminin</span>
                    </label>
                  </div>
                </label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label class="app-label">
                  <span>Nationalité</span>
                  <b-form-group>
                    <b-form-select
                      :options="pays"
                      text-field="nom_fr_fr"
                      v-model="query.nationalite"
                      value-field="nom_fr_fr"
                    >
                      <template v-slot:first>
                        <option :value="null"
                          >-- Sélectionner une nationalité --
                        </option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label class="app-label">
                  <span>Pays de résidence</span>
                  <b-form-group>
                    <b-form-select
                      :options="pays"
                      value-field="nom_fr_fr"
                      text-field="nom_fr_fr"
                      v-model="query.pays_residence"
                    >
                      <template v-slot:first>
                        <option :value="null"
                          >-- Sélectionner un pays --
                        </option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label class="app-label">
                  <span>Profession</span>
                  <input
                    class="form-control"
                    type="text"
                    v-model="query.profession"
                  />
                </label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label class="app-label">
                  <span>Employeur</span>
                  <input
                    class="form-control"
                    type="text"
                    v-model="query.employeur"
                  />
                </label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label class="app-label">
                  <span>Dernier diplôme</span>
                  <b-form-group>
                    <b-form-select
                      :options="last_grades"
                      v-model="query.last_grade"
                    >
                      <template v-slot:first>
                        <option :value="null"
                          >-- Sélectionner un diplôme --
                        </option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label class="app-label">
                  <span>Année d’obtention du diplôme</span>
                  <b-form-group>
                    <b-form-select
                      :options="years"
                      v-model="query.last_grade_year"
                    >
                      <template v-slot:first>
                        <option :value="null"
                          >-- Sélectionner une année --
                        </option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </label>
              </div>
            </div>
          </div>
        </form>
        <div slot="modal-footer">
          <div align="center">
            <button
              :disabled="loading"
              @click="(pagination.current_page = 1), advancedSearch(true)"
              class="btn btn-red"
              type="button"
            >
              <span v-if="loading">Filtrage...</span>
              <span v-else>Appliquer le filtre</span>
            </button>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-add-ol-student" size="lg" title="Ajouter un ancien">
        <form class="form-register" autocomplete="off">
          <div class="row">
            <div class="col-lg-6">
              <div class="field-radios mb-3">
                <div class="form-group">
                  <label class="app-label">
                    <span>Sexe</span>
                    <div class="field-radios">
                      <label class="field-radio-item">
                        <input
                          class="check-custom"
                          name="gender"
                          type="radio"
                          v-model="form_add.sexe"
                          v-on:change="resetErrors('sexe')"
                          value="M"
                        />
                        <span class="check-toggle"></span>
                        <span class="name">Masculin</span>
                      </label>
                      <label class="field-radio-item">
                        <input
                          class="check-custom"
                          name="gender"
                          type="radio"
                          v-model="form.sexe"
                          v-on:change="resetErrors('sexe')"
                          value="F"
                        />
                        <span class="check-toggle"></span>
                        <span class="name">Féminin</span>
                      </label>
                    </div>
                  </label>
                  <span class="text-danger" v-if="errors.sexe">{{
                    errors.sexe[0]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="app-label">
                  <span>Nom</span>
                  <input
                    :class="errors.nom ? 'is-invalid' : ''"
                    class="form-control"
                    type="text"
                    v-model="form_add.nom"
                    v-on:input="resetErrors('nom')"
                  />
                </label>
                <span class="text-danger" v-if="errors.nom">{{
                  errors.nom[0]
                }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="app-label">
                  <span>Prénom(s)</span>
                  <input
                    :class="errors.prenoms ? 'is-invalid' : ''"
                    class="form-control"
                    type="text"
                    v-model="form_add.prenoms"
                    v-on:input="resetErrors('prenoms')"
                  />
                </label>
                <span class="text-danger" v-if="errors.prenoms">{{
                  errors.prenoms[0]
                }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="app-label">
                  <span>Email</span>
                  <input
                    :class="errors.email ? 'is-invalid' : ''"
                    class="form-control"
                    type="email"
                    v-model="form_add.email"
                    v-on:input="resetErrors('email')"
                  />
                </label>
                <span class="text-danger" v-if="errors.email">{{
                  errors.email[0]
                }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="app-label">
                  <span>Téléphone</span>
                  <input
                    :class="errors.telephone ? 'is-invalid' : ''"
                    class="form-control"
                    min="0"
                    placeholder="Ex:0022998435432"
                    type="number"
                    v-model="form_add.telephone"
                    v-on:input="resetErrors('telephone')"
                  />
                </label>
                <span class="text-danger" v-if="errors.telephone">{{
                  errors.telephone[0]
                }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="app-label">
                  <span>Nationalité</span>
                  <b-form-select
                    :class="errors.nationalite ? 'is-invalid' : ''"
                    :options="pays"
                    class="mb-3"
                    text-field="nom_fr_fr"
                    v-model="form_add.nationalite"
                    v-on:change="resetErrors('nationalite')"
                    value-field="nom_fr_fr"
                  >
                    <!-- This slot appears above the options from 'options' prop -->
                    <template v-slot:first>
                      <option :value="null" disabled
                        >-- Sélectionnez un pays --
                      </option>
                    </template>
                  </b-form-select>
                </label>
                <span class="text-danger" v-if="errors.nationalite">{{
                  errors.nationalite[0]
                }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="app-label">
                  <span>Pays de résidence</span>
                  <b-form-select
                    :class="errors.pays_residence ? 'is-invalid' : ''"
                    :options="pays"
                    class="mb-3"
                    text-field="nom_fr_fr"
                    v-model="form_add.pays_residence"
                    v-on:change="resetErrors('pays_residence')"
                    value-field="nom_fr_fr"
                  >
                    <!-- This slot appears above the options from 'options' prop -->
                    <template v-slot:first>
                      <option :value="null" disabled
                        >-- Sélectionnez un pays --
                      </option>
                    </template>
                  </b-form-select>
                  <span class="text-danger" v-if="errors.pays_residence">{{
                    errors.pays_residence[0]
                  }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="app-label">
                  <span>Employeur</span>
                  <input
                    :class="errors.employeur ? 'is-invalid' : ''"
                    class="form-control"
                    type="text"
                    v-model="form_add.employeur"
                    v-on:v-on:input="resetErrors('employeur')"
                  />
                </label>
                <span class="text-danger" v-if="errors.employeur">{{
                  errors.employeur[0]
                }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="app-label">
                  <span>Profession</span>
                  <b-form-select
                    :class="errors.profession ? 'is-invalid' : ''"
                    :options="profession"
                    class="form-control"
                    v-model="form_add.profession"
                    v-on:change="resetErrors('profession')"
                  >
                    <!-- This slot appears above the options from 'options' prop -->
                    <template v-slot:first>
                      <option :value="null" disabled
                        >-- Sélectionnez une profession --
                      </option>
                    </template>
                  </b-form-select>
                </label>
                <span class="text-danger" v-if="errors.profession">{{
                  errors.profession[0]
                }}</span>
              </div>
            </div>
          </div>
        </form>
        <div slot="modal-footer">
          <div align="center">
            <button
              @click="createOldStudent()"
              :disabled="loading_add"
              class="btn btn-red"
              type="button"
            >
              <span v-if="loading_add"
                >Création en cours
                <b-spinner small label="Small Spinner"></b-spinner
              ></span>
              <span v-else>Créer</span>
            </button>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-old-student-options" size="lg" title="Options">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Status">
              <b-form-radio-group
                id="radio-group-1"
                v-model="user_selected.is_activate"
                :options="status"
                name="status-options"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Approbation">
              <b-form-radio-group
                id="radio-group-2"
                v-model="user_selected.approved"
                :options="approve"
                name="status-options-2"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Certification">
              <b-form-radio-group
                id="radio-group-3"
                v-model="user_selected.certified"
                :options="certified"
                name="status-options-3"
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <div slot="modal-footer">
          <div align="center">
            <button
              :disabled="loading_state"
              class="btn btn-red"
              type="button"
              @click="updateStudentState()"
            >
              <span v-if="loading_state"
                >Modification en cours
                <b-spinner small label="Small Spinner"></b-spinner
              ></span>
              <span v-else>Modifier</span>
            </button>
          </div>
        </div>
      </b-modal>

      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="m-heading">
              <h3 class="m-title">
                Liste des anciens
                <span v-if="pagination.total">({{ pagination.total }})</span>
              </h3>
            </div>
            <div class="m-heading m-heading--end">
              <button
                v-if="curr_user.profil.id !== 1"
                v-b-modal.modal-add-ol-student
                class="btn btn-primary mx-1"
                data-toggle="modal"
              >
                <span>Ajouter</span>
              </button>
              <button
                @click="loadOldStudents()"
                class="btn btn-primary mx-1"
                data-toggle="modal"
              >
                <span>Actualiser</span>
              </button>
              <a
                class="filter-advance-trigger"
                @click="showAdancedSearchModal()"
              >
                <svg
                  fill="none"
                  height="14"
                  viewBox="0 0 14 14"
                  width="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.15225 12.1503C7.39562 12.1503 8.55825 11.7836 9.52711 11.1458L12.4014 14L14 12.4214L11.158 9.59909C11.8847 8.61048 12.3045 7.38269 12.3045 6.07517C12.3045 2.72665 9.54325 0 6.15225 0C2.76125 0 0 2.72665 0 6.07517C0 9.42369 2.76125 12.1503 6.15225 12.1503ZM6.15225 2.23235C8.29988 2.23235 10.0438 3.95444 10.0438 6.07517C10.0438 8.1959 8.29988 9.918 6.15225 9.918C4.00461 9.918 2.26067 8.1959 2.26067 6.07517C2.26067 3.95444 4.00461 2.23235 6.15225 2.23235Z"
                    fill="#7A8796"
                  ></path>
                </svg>
                <span>Recherche avancée</span>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="filter-box">
              <div class="filter-heading">
                <div class="filter-trigger">
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2492 0H0.750872C0.0846841 0 -0.251472 0.808313 0.220559 1.28034L6 7.06066V13.5C6 13.7447 6.1194 13.9741 6.3199 14.1144L8.8199 15.8638C9.31312 16.2091 10 15.8592 10 15.2494V7.06066L15.7796 1.28034C16.2507 0.80925 15.9168 0 15.2492 0Z"
                      fill="#7A8796"
                    ></path>
                  </svg>
                  <span>Filtrer par</span>
                </div>

                <div class="filter-heading__right">
                  <a class="filter-heading__close">
                    <svg
                      fill="none"
                      height="10"
                      viewBox="0 0 10 10"
                      width="10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.1932 5L9.53304 1.66015C9.69155 1.50193 9.78071 1.28721 9.78091 1.06324C9.78111 0.839278 9.69233 0.624405 9.5341 0.465897C9.37587 0.307389 9.16116 0.218229 8.93719 0.218031C8.71322 0.217833 8.49835 0.306614 8.33984 0.464843L5 3.80469L1.66015 0.464843C1.50165 0.306334 1.28666 0.217285 1.0625 0.217285C0.838334 0.217285 0.623351 0.306334 0.464843 0.464843C0.306334 0.623351 0.217285 0.838334 0.217285 1.0625C0.217285 1.28666 0.306334 1.50165 0.464843 1.66015L3.80469 5L0.464843 8.33984C0.306334 8.49835 0.217285 8.71333 0.217285 8.9375C0.217285 9.16166 0.306334 9.37665 0.464843 9.53515C0.623351 9.69366 0.838334 9.78271 1.0625 9.78271C1.28666 9.78271 1.50165 9.69366 1.66015 9.53515L5 6.19531L8.33984 9.53515C8.49835 9.69366 8.71333 9.78271 8.9375 9.78271C9.16166 9.78271 9.37665 9.69366 9.53515 9.53515C9.69366 9.37665 9.78271 9.16166 9.78271 8.9375C9.78271 8.71333 9.69366 8.49835 9.53515 8.33984L6.1932 5Z"
                        fill="#344051"
                      ></path>
                    </svg>
                  </a>
                  <a
                    class="filter-advance-trigger filter-advance-trigger--mobile"
                    data-target="#filtersModal"
                    data-toggle="modal"
                  >
                    <span>Recherche avancée</span>
                  </a>
                </div>
              </div>

              <div class="filter-items">
                <div class="filter-item">
                  <b-form-group>
                    <b-form-select
                      :options="esgis"
                      v-model="query.esgis"
                      v-on:change="
                        (pagination.current_page = 1), loadOldStudents(true)
                      "
                    >
                      <template v-slot:first>
                        <option :value="null">Pays</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="filter-item">
                  <b-form-group>
                    <b-form-select
                      :options="filiere"
                      v-model="query.filiere"
                      v-on:change="
                        (pagination.current_page = 1), loadOldStudents(true)
                      "
                    >
                      <template v-slot:first>
                        <option :value="null">Filière</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="filter-item">
                  <b-form-group>
                    <b-form-select
                      :options="years"
                      v-model="query.first_year"
                      v-on:change="
                        (pagination.current_page = 1), loadOldStudents(true)
                      "
                    >
                      <template v-slot:first>
                        <option :value="null">Année début étude</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="filter-item">
                  <b-form-group>
                    <b-form-select
                      :options="years"
                      v-model="query.last_grade_year"
                      v-on:change="
                        (pagination.current_page = 1), loadOldStudents(true)
                      "
                    >
                      <template v-slot:first>
                        <option :value="null">Année fin étude</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="filter-items-apply-wrapper">
                <button class="filter-items-apply btn btn-red">
                  Appliquer le filtre
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pagination.total > 0">
          <div class="row">
            <div
              :key="cpt"
              class="student-wrapper col-md-4 col-lg-3"
              v-for="(user, cpt) in users"
            >
              <div class="student">
                <div v-if="curr_user.profil.id !== 1">
                  <button
                    class="student-status-trigger"
                    @click="showOptionsModal(user)"
                  >
                    <svg
                      fill="none"
                      height="16"
                      viewBox="0 0 4 16"
                      width="4"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                        fill="#7A8796"
                      ></path>
                    </svg>
                  </button>
                  <div class="student-status-box">
                    <div class="field-radios">
                      <label class="field-radio-item">
                        <input
                          checked
                          class="check-custom"
                          name="choose"
                          type="radio"
                          v-model="form.state"
                          value="1"
                        />
                        <span class="check-toggle"></span>
                        <span class="name">Activé</span>
                      </label>
                      <label class="field-radio-item">
                        <input
                          class="check-custom"
                          name="choose"
                          type="radio"
                          v-model="form.state"
                          value="0"
                        />
                        <span class="check-toggle"></span>
                        <span class="name">Désactivé</span>
                      </label>
                    </div>
                    <button
                      @click="updateStudentState(user.id)"
                      class="btn btn-primary"
                    >
                      Changer le statut
                    </button>
                  </div>
                </div>
                <div class="student__header">
                  <img
                    :src="web_site_url + user.path_picture"
                    alt="..."
                    class="student__img img-fluid"
                  />
                  <!--<img src="../../../../assets/img/user1.png" alt="..."
                                         class="student__img img-fluid">-->
                  <h3 class="student__name text-center">
                    {{ (user.nom + " " + user.prenoms) | truncate(20) }}
                  </h3>
                  <div class="student__job">
                    {{ user.profession ? user.profession : "---" }}
                  </div>
                </div>
                <ul class="student-list">
                  <li class="student-list__item">
                    <div class="round">
                      <svg
                        fill="none"
                        height="12"
                        viewBox="0 0 13 12"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.7066 0.576141L9.26914 0.0136407C9.0043 -0.0472967 8.73242 0.0909845 8.62461 0.339422L7.49961 2.96442C7.40117 3.19411 7.4668 3.46364 7.66133 3.62067L9.08164 4.78317C8.23789 6.58083 6.76367 8.07614 4.92852 8.9363L3.76602 7.51598C3.60664 7.32145 3.33945 7.25583 3.10977 7.35427L0.484766 8.47927C0.233984 8.58942 0.0957031 8.8613 0.156641 9.12614L0.719141 11.5636C0.777734 11.8168 1.00273 11.9996 1.26758 11.9996C7.26992 11.9996 12.1426 7.1363 12.1426 1.12458C12.1426 0.862078 11.9621 0.634735 11.7066 0.576141Z"
                          fill="#344051"
                        ></path>
                      </svg>
                    </div>
                    <span>{{ user.telephone ? user.telephone : "---" }}</span>
                  </li>
                  <li class="student-list__item">
                    <div class="round">
                      <svg
                        fill="none"
                        height="11"
                        viewBox="0 0 13 11"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.1875 4.12045C12.1875 3.74129 11.9979 3.38913 11.6728 3.19963L6.5 0.625L1.32719 3.19963C1.02911 3.38913 0.8125 3.74129 0.8125 4.12045V9.29166C0.8125 9.88755 1.29992 10.375 1.89582 10.375H11.1042C11.7001 10.375 12.1875 9.88755 12.1875 9.29166V4.12045ZM6.5 6.71875L2.14883 3.875L6.5 1.70834L10.8512 3.875L6.5 6.71875Z"
                          fill="#344051"
                        ></path>
                      </svg>
                    </div>
                    <span>{{ user.email }}</span>
                  </li>
                </ul>
                <button
                  @click="
                    $router.push({
                      name: 'ancien_details',
                      params: { id: user.id }
                    })
                  "
                  class="student__btn btn"
                >
                  Voir le profil
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="pagination">
                <pagination-component
                  :offset="5"
                  :pagination="pagination"
                  @paginate="loadOldStudents()"
                  v-if="pagination.last_page > 1"
                ></pagination-component>
              </div>
            </div>
          </div>
        </div>
        <div class="container" v-else>
          <div class="row">
            <div class="col">
              <div class="student-single-content">
                <div class="student-single-content__content text-center">
                  Auncun résultat
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth, { _buildQuery } from "../../../../helper/config";
import PaginationComponent from "@/views/pages/admin/PaginationComponent";
import { mapGetters, mapState } from "vuex";

export default {
  name: "liste_old_students",
  data: () => ({
    user_selected: {
      id: null,
      approved: null,
      certified: null,
      is_activate: null
    },
    loading: false,
    loading_state: false,
    loading_add: false,
    users: [],
    web_site_url: auth.basePath,
    pagination: {
      current_page: 1
    },
    form_add: {
      nom: null,
      email: null,
      prenoms: null,
      nationalite: null,
      pays_residence: null,
      profession: null,
      employeur: null,
      esgis: null,
      last_grade: null,
      last_grade_year: null,
      telephone: null,
      sexe: null,
      filiere: null,
      first_year: null,
      biographie: null,
      path_picture: null,
      parcours: []
    },
    form: {
      state: null,
      approved: null,
      certified: null
    },
    query: {
      search: null,
      esgis: null,
      filiere: null,
      pays_residence: null,
      first_year: null,
      last_grade_year: null,
      sexe: null,
      profession: null,
      employeur: null,
      last_grade: null,
      nationalite: null
    },
    last_grades: [
      { value: "BTS", text: "BTS" },
      { value: "Licence", text: "Licence" },
      { value: "Master", text: "Master" }
    ],
    last_grade_years: [
      { value: 2010, text: "2010" },
      { value: 2011, text: "2011" },
      { value: 2012, text: "2012" },
      { value: 2013, text: "2013" },
      { value: 2014, text: "2014" },
      { value: 2015, text: "2015" },
      { value: 2016, text: "2016" },
      { value: 2017, text: "2017" },
      { value: 2018, text: "2018" },
      { value: 2019, text: "2019" }
    ],
    esgis: [
      { value: "Bénin", text: "Bénin" },
      { value: "Gabon", text: "Gabon" },
      { value: "Togo", text: "Togo" }
    ],
    pays_residence: [],
    filiere: [
      { value: "Droit", text: "Droit" },
      { value: "Comptabilité", text: "Comptabilité" },
      { value: "Finance", text: "Finance" }
    ],
    nationalite: [],
    errors: [],
    status: [
      { value: 1, text: "Actif" },
      { value: 0, text: "Inactif" }
    ],
    approve: [
      { value: 1, text: "Approuvé" },
      { value: 0, text: "Non approuvé" }
    ],
    certified: [
      { value: 1, text: "Certifié" },
      { value: 0, text: "Non certifié" }
    ]
  }),
  computed: {
    ...mapGetters({
      years: "getYears",
      pays: "getPays",
      profession: "getProfession"
    }),
    ...mapState({
      curr_user: state => state.user
    })
  },

  watch: {
    "$route.query.search": function() {
      if (this.$route.query.search) {
        this.query.search = this.$route.query.search;
        this.pagination.current_page = "";
        this.loadOldStudents(true);
      }
    }
  },
  components: {
    PaginationComponent
  },
  created() {
    this.loadOldStudents();
  },
  methods: {
    createOldStudent() {
      let url = auth.baseApi;
      this.loading_add = true;
      this.message = "";
      axios
        .post(url + "users/students/add", this.form_add, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading_add = false;
          if (response.data.status === true) {
            this.$toasted.global.all({
              message: "Inscription réussie. Consultez vos emails"
            });
            this.loadOldStudents();
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading_add = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else if (error.response.status === 400) {
            this.errors = error.response.data.errors;
          } else this.$toasted.global.error();
        });
    },
    loadOldStudents() {
      //console.log(user_filter)
      let url = auth.baseApi;
      this.loading = true;
      this.query.search = this.$route.query.search
        ? this.$route.query.search
        : null;
      this.query.page = this.pagination.current_page;

      axios
        .get(url + "users/students/list" + _buildQuery(this.query), {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          if (response) {
            this.users = response.data.data.data;
            this.pagination = response.data.pagination;
            this.$bvModal.hide("modal-advanced-search");
            //this.resetFilter()
            this.message = "";
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    },
    advancedSearch(user_filter) {
      let url = auth.baseApi;
      this.loading = true;
      let filter = user_filter ? _buildQuery(this.query) : "";
      let page = filter
        ? "&page=" + this.pagination.current_page
        : "/?page=" + this.pagination.current_page;
      axios
        .get(url + "users/students/list" + filter + page, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          if (response) {
            this.users = response.data.data.data;
            this.pagination = response.data.pagination;
            this.$bvModal.hide("modal-advanced-search");
            this.resetFilter();
            this.message = "";
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    },
    updateStudentState() {
      let student_id = this.user_selected.id;
      let url = auth.baseApi;
      this.loading_state = true;
      axios
        .put(
          url + "users/admin/update/studentstate/" + student_id,
          this.user_selected,
          {
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + auth.getSession()
            }
          }
        )
        .then(response => {
          this.loading_state = false;
          if (response.data.status === true) {
            this.$toasted.global.all();
            this.loadOldStudents();
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading_state = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    },
    resetFilter() {
      this.query.sexe = null;
      this.query.nationalite = null;
      this.query.pays_residence = null;
      this.query.esgis = null;
      this.query.profession = null;
      this.query.employeur = null;
      this.query.last_grade = null;
      this.query.last_grade_year = null;
      this.query.filiere = null;
      this.query.first_year = null;
    },
    showAdancedSearchModal() {
      this.resetFilter();
      this.$bvModal.show("modal-advanced-search");
    },
    resetErrors(field) {
      if (field) {
        this.errors[field] = null;
      } else {
        this.errors = [];
        let user = this.form_add;
        Object.keys(user).forEach(index => (user[index] = null));
      }
    },
    showOptionsModal(user) {
      this.user_selected.id = user.id;
      this.user_selected.approved = user.approved;
      this.user_selected.certified = user.certified;
      this.user_selected.is_activate = user.is_activate;
      this.$bvModal.show("modal-old-student-options");
    }
  }
};
</script>

<style scoped>
.student__img {
  height: 150px;
}
</style>
