<template>
  <main class="main main--gray col-md-12 text-justify">
    <div class="post-single-banner">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2 class="post-single__title text-center">
              Conditions d'utilisation
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="auth-box22">
      <div class="auth-box__main">
        <p>
          Devant le développement des nouveaux outils de communication, il est
          nécessaire de porter une attention particulière à la protection de la
          vie privée. C'est pourquoi, nous nous engageons à respecter la
          confidentialité des renseignements personnels que nous collectons.
        </p>
        <br /><br />
        <strong>
          <p>Collecte des renseignements personnels :</p>
          <br />
        </strong>

        <p>
          Nous collectons un certain nombre de renseignements nécessaires aux
          inscriptions et/ou à l’utilisation de l’espace personnel : nom,
          prénom, coordonnées téléphoniques, adresse e-mail, niveau d’étude. Les
          renseignements personnels que nous collectons sont recueillis au
          travers de formulaires et grâce à l'interactivité établie entre vous
          et notre site Web.
        </p>
        <br /><br />
        <strong><p>Formulaires et interactivité :</p></strong><br />
        <p>
          Vos renseignements personnels sont collectés par le biais de
          formulaire, à savoir :
        </p>

        <ul>
          <li>. Formulaire d'inscription</li>
          <li>. Formulaire de modification de vos informations personnelles</li>
        </ul>
        <br /><br />
        <p>
          Nous aurons pleinement accès aux renseignements suivants : nom,
          prénoms, téléphone, pays de résidence, années d’obtention de votre
          diplôme, niveau d’étude, filière et adresse e-mail.
        </p>
      </div>
    </div>
    <br /><br /><br /><br />
  </main>
</template>

<script>
export default {
  name: "termsAndCondtions"
};
</script>

<style scoped>
/*div .terms {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }*/
.auth-box22 {
  position: relative;
  width: 800px;
  max-width: 90%;
  margin: 30px auto 0px;
}
</style>
