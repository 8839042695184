<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="back-item mb-2 mb-lg-5">
          <button @click="$router.go(-1)" class="btn back-item__btn">
            Retour
          </button>
          <h3 class="back-item__title">Ajouter un stage/emplois</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dash-box pb-5">
          <form @submit.prevent="addNewOpportunity" autocomplete="off">
            <div class="opportunity-radios field-radios mb-3">
              <label class="field-radio-item">
                <input
                  checked=""
                  class="check-custom"
                  name="gender"
                  type="radio"
                  v-model="opportunity.type"
                  v-on:change="resetErrors('type')"
                  value="EMPLOI"
                />
                <span class="check-toggle"></span>
                <span class="name">Emplois</span>
              </label>
              <label class="field-radio-item">
                <input
                  class="check-custom"
                  name="gender"
                  type="radio"
                  v-model="opportunity.type"
                  v-on:change="resetErrors('type')"
                  value="STAGE"
                />
                <span class="check-toggle"></span>
                <span class="name">Stage</span>
              </label>
              <label class="field-radio-item">
                <input
                  class="check-custom"
                  name="gender"
                  type="radio"
                  v-model="opportunity.type"
                  v-on:change="resetErrors('type')"
                  value="AFFAIRE"
                />
                <span class="check-toggle"></span>
                <span class="name">Opportunité d’affaire</span>
              </label>
              <span class="text-danger" v-if="errors.type">{{
                errors.type[0]
              }}</span>
            </div>

            <div class="form-group">
              <label class="app-label">
                <span>Titre</span>
                <input
                  :class="errors.titre ? 'is-invalid' : ''"
                  class="form-control"
                  type="text"
                  v-model="opportunity.titre"
                  v-on:input="resetErrors('titre')"
                />
              </label>
              <span class="text-danger" v-if="errors.titre">{{
                errors.titre[0]
              }}</span>
            </div>
            <div class="form-group">
              <label class="app-label">
                <span>Nom de l’entreprise</span>
                <input
                  :class="errors.publicateur ? 'is-invalid' : ''"
                  class="form-control"
                  type="text"
                  v-model="opportunity.publicateur"
                  v-on:input="resetErrors('publicateur')"
                />
              </label>
              <span class="text-danger" v-if="errors.publicateur">{{
                errors.publicateur[0]
              }}</span>
            </div>
            <div class="form-group">
              <label class="app-label">
                <span>Description</span>
                <textarea
                  :class="errors.description ? 'is-invalid' : ''"
                  class="form-control"
                  rows="2"
                  v-model="opportunity.description"
                  v-on:input="resetErrors('description')"
                ></textarea>
              </label>
              <span class="text-danger" v-if="errors.description">{{
                errors.description[0]
              }}</span>
            </div>
            <div class="form-group">
              <label class="app-label">
                <span>Mission</span>
                <textarea
                  :class="errors.missions ? 'is-invalid' : ''"
                  class="form-control"
                  rows="7"
                  v-model="opportunity.missions"
                  v-on:input="resetErrors('missions')"
                ></textarea>
              </label>
              <span class="text-danger" v-if="errors.missions">{{
                errors.missions[0]
              }}</span>
            </div>
            <div class="form-group">
              <label class="app-label">
                <span>Pièces à fournir</span>
                <textarea
                  :class="errors.pieces ? 'is-invalid' : ''"
                  class="form-control"
                  rows="4"
                  v-model="opportunity.pieces"
                  v-on:input="resetErrors('pieces')"
                ></textarea>
              </label>
              <span class="text-danger" v-if="errors.pieces">{{
                errors.pieces[0]
              }}</span>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="app-label">
                    <span>Date limite</span>
                    <input
                      :class="errors.date_limite ? 'is-invalid' : ''"
                      class="form-control"
                      rows="7"
                      type="date"
                      v-model="opportunity.date_limite"
                      v-on:input="resetErrors('date_limite')"
                    />
                  </label>
                  <span class="text-danger" v-if="errors.date_limite">{{
                    errors.date_limite[0]
                  }}</span>
                </div>
              </div>
            </div>

            <button :disabled="loading" class="btn btn-red">
              <span v-if="loading"
                >Ajout en cours
                <b-spinner label="Spinning" variant="light" small></b-spinner
              ></span>
              <span v-else>Ajouter</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth from "../../../../helper/config";

export default {
  name: "addOpportunity",
  data: () => ({
    loading: false,
    opportunity: {
      titre: null,
      type: null,
      missions: null,
      pieces: null,
      publicateur: null,
      date_limite: null,
      description: null
    },

    errors: []
  }),
  methods: {
    resetErrors(field) {
      if (field) {
        this.errors[field] = null;
      } else {
        this.errors = [];
        this.opportunity.titre = null;
        this.opportunity.type = null;
        this.opportunity.missions = null;
        this.opportunity.pieces = null;
        this.opportunity.publicateur = null;
        this.opportunity.date_limite = null;
        this.opportunity.description = null;
      }
    },
    addNewOpportunity() {
      let url = auth.baseApi;
      this.loading = true;
      axios
        .post(url + "opportunities/create", this.opportunity, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          if (response.data.status === true) {
            this.$toasted.global.all();
            this.$router.push({ name: "liste_opportunities" });
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else if (error.response.status === 400) {
            this.errors = error.response.data.errors;
          } else {
            this.$toasted.global.error();
          }
        });
    }
  }
};
</script>

<style scoped></style>
