<template>
  <div class="banner">
    <div class="container">
      <div class="row">
        <div class="banner__inner col-md-6">
          <h3 class="banner__title">
            L'école des leaders. 25 années au service de l'excellence
          </h3>
          <div class="row">
            <div class="skill-wrapper col-6">
              <div class="skill-item">
                <div class="skill-item__img-wrapper">
                  <img
                    alt="..."
                    class="skill-item__img img-fluid"
                    src="../../../assets/img/study.svg"
                  />
                </div>
                <div class="skill-item__body">
                  <h3 class="skill-item__number">+15000</h3>
                  <div class="skill-item__name">Diplomés</div>
                </div>
              </div>
            </div>
            <div class="skill-wrapper col-6">
              <div class="skill-item">
                <div class="skill-item__img-wrapper">
                  <img
                    alt="..."
                    class="skill-item__img img-fluid"
                    src="../../../assets/img/home.svg"
                  />
                </div>
                <div class="skill-item__body">
                  <h3 class="skill-item__number">7</h3>
                  <div class="skill-item__name">Parcours</div>
                </div>
              </div>
            </div>
            <div class="skill-wrapper col-6">
              <div class="skill-item">
                <div class="skill-item__img-wrapper">
                  <img
                    alt="..."
                    class="skill-item__img img-fluid"
                    src="../../../assets/img/location.svg"
                  />
                </div>
                <div class="skill-item__body">
                  <h3 class="skill-item__number">3</h3>
                  <div class="skill-item__name">Pays</div>
                </div>
              </div>
            </div>
            <div class="skill-wrapper col-6">
              <div class="skill-item">
                <div class="skill-item__img-wrapper">
                  <img
                    alt="..."
                    class="skill-item__img img-fluid"
                    src="../../../assets/img/study.svg"
                  />
                </div>
                <div class="skill-item__body">
                  <h3 class="skill-item__number">+15</h3>
                  <div class="skill-item__name">Nationalités</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeBanner"
};
</script>

<style scoped>
.banner {
  background-image: url("../../../assets/img/banner.jpg");
}
</style>
