<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import $ from "jquery";

function initScript() {
  const filter_box = document.querySelector(".filter-box");

  $(document).on("click", "#manager-search-form__close", function(e) {
    e.preventDefault();

    $(".manager-search-form").removeClass("manager-search-form--show");
  });
  $("#agile__actions").hide();

  $(document).on("click", "#manager-search-form-trigger", function() {
    $(".manager-search-form").addClass("manager-search-form--show");
  });

  /*$(document).on("click", ".student-status-trigger", function(e) {
    e.stopPropagation();
    const el = this;
    const $parent = $(el)
      .parents(".student")
      .first();

    $(".student").each((i, el) => {
      if (el !== $parent.get(0)) $(el).removeClass("student-status-box--show");
    });
    $(el)
      .parents(".student")
      .first()
      .toggleClass("student-status-box--show");
  });*/

  $(document).on("click", ".filter-heading", function() {
    filter_box.classList.add("filter-box--show");
  });

  $(document).on("click", ".filter-heading__close", function(e) {
    e.stopPropagation();
    filter_box.classList.remove("filter-box--show");
  });

  $(document).on("click", "#manager-header__trigger", function(e) {
    e.preventDefault();

    $("body").toggleClass("manager-sidebar--show");
  });

  //main.js
  // header
  document.querySelectorAll(".js-header-trigger").forEach(el => {
    el.addEventListener("click", function(e) {
      e.preventDefault();
      document.querySelector("#header").classList.toggle("header--mobile");
      document.querySelector("body").classList.toggle("header--mobile");
    });
  });
}
export default {
  mounted() {
    setTimeout(() => {
      initScript();
    }, 500);
  },
  methods: {}
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap");
@import "./assets/css/style.css";
div .header-nav-inner {
  overflow: hidden;
}

div .guest {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

button .btn {
  padding: 0;
}
</style>
