<template>
  <div>Add admin</div>
</template>

<script>
export default {
  name: "addAdmin"
};
</script>

<style scoped></style>
