<template>
  <div>
    <div class="text-center" v-if="loading">
      <div class="row">
        <div class="container">
          <div class="col">
            <div class="profile-box">
              <b-spinner
                variant="primary"
                label="Text Centered"
                style="width: 8rem; height: 8rem;"
              ></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="back-item mb-4">
            <div class="col-md-2">
              <button @click="$router.go(-1)" class="btn back-item__btn">
                Retour
              </button>
            </div>
            <div class="col-md-8">
              <h3 class="back-item__title">{{ opportunity.titre }}
                <button
                        class="btn btn-warning opportunity__type mr-5 align-items-end"
                        type="button"
                >
                  {{ opportunity.type }}
                </button>
                <!--<b-badge variant="warning">{{ opportunity.type }}</b-badge>--></h3>
              <!--<button
                    class="btn btn-warning opportunity__type mr-5 align-items-end"
                    type="button"
            >
              {{ opportunity.type }}
            </button>-->


            </div>
            <!--<b-button-group>
              <b-dropdown right split text="Options">
                <b-dropdown-item
                  @click="$router.push({ name: 'opportunity_update' })"
                  ><svg
                    fill="none"
                    height="14"
                    viewBox="0 0 14 14"
                    width="14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0741 2.9843C13.362 2.69639 13.362 2.21654 13.0741 1.94339L11.3466 0.215932C11.0735 -0.0719775 10.5936 -0.0719775 10.3057 0.215932L8.94736 1.56689L11.7157 4.33526L13.0741 2.9843ZM0 10.5216V13.29H2.76837L10.9332 5.11778L8.16483 2.34942L0 10.5216Z"
                      fill="#2F80ED"
                    ></path></svg
                  >&nbsp;Modifier</b-dropdown-item
                >
                <b-dropdown-item @click="deleteOpportunity()"
                  ><svg
                    fill="none"
                    height="14"
                    viewBox="0 0 11 14"
                    width="11"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.40029 1.09201L7.54674 1.23846H7.75385H9.83846V1.71538H0.5V1.23846H2.58462H2.79172L2.93817 1.09201L3.53018 0.5H6.80828L7.40029 1.09201ZM1.5246 12.5062C1.34139 12.323 1.23846 12.0745 1.23846 11.8154V3.45385H9.1V11.8154C9.1 12.0745 8.99708 12.323 8.81387 12.5062C8.63066 12.6894 8.38217 12.7923 8.12308 12.7923H2.21538C1.95629 12.7923 1.7078 12.6894 1.5246 12.5062Z"
                      fill="#EB5757"
                      stroke="#EB5757"
                    ></path></svg
                  >&nbsp;Supprimer</b-dropdown-item
                >
              </b-dropdown>
            </b-button-group>-->

            <b-button-group  v-if="curr_user.profil.id == 2">
              <b-dropdown right text="Options">
                <b-dropdown-item @click="$router.push({ name: 'opportunity_update' })">
                  <svg
                          fill="none"
                          height="14"
                          viewBox="0 0 14 14"
                          width="14"
                          xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                            d="M13.0741 2.9843C13.362 2.69639 13.362 2.21654 13.0741 1.94339L11.3466 0.215932C11.0735 -0.0719775 10.5936 -0.0719775 10.3057 0.215932L8.94736 1.56689L11.7157 4.33526L13.0741 2.9843ZM0 10.5216V13.29H2.76837L10.9332 5.11778L8.16483 2.34942L0 10.5216Z"
                            fill="#2F80ED"
                    ></path></svg
                  >&nbsp;Modifier
                </b-dropdown-item>
                <b-dropdown-item @click="deleteOpportunity()">
                  <svg
                          fill="none"
                          height="14"
                          viewBox="0 0 11 14"
                          width="11"
                          xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                            d="M7.40029 1.09201L7.54674 1.23846H7.75385H9.83846V1.71538H0.5V1.23846H2.58462H2.79172L2.93817 1.09201L3.53018 0.5H6.80828L7.40029 1.09201ZM1.5246 12.5062C1.34139 12.323 1.23846 12.0745 1.23846 11.8154V3.45385H9.1V11.8154C9.1 12.0745 8.99708 12.323 8.81387 12.5062C8.63066 12.6894 8.38217 12.7923 8.12308 12.7923H2.21538C1.95629 12.7923 1.7078 12.6894 1.5246 12.5062Z"
                            fill="#EB5757"
                            stroke="#EB5757"
                    ></path></svg
                  >&nbsp;Supprimer
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="opportunity-single-box dash-box">
            <h3 class="opportunity__title">{{ opportunity.publicateur }}</h3>
            <div class="opportunity__content">
              {{ opportunity.description }}
            </div>

            <b>Missions:</b>
            <p></p>
            <!-- <ul>
                             <li>• Gérer les rendez-vous (agenda) et l’emploi du temps du directeur ;</li>
                             <li>• Organiser les réunions ;</li>
                             <li>• Faire le compte rendu de ces réunions ;</li>
                             <li>• Superviser les déplacements professionnels ;</li>
                             <li>• Transmettre les instructions du directeur aux responsables concernés ;</li>
                             <li>• Accueillir les visiteurs ;</li>
                             <li>• Réceptionner les appels téléphoniques ;</li>
                             <li>• Assurer le référencement unique des courriers entrant et sortant ;</li>
                             <li>• Traiter les courriers et les soumettre au directeur;</li>
                             <li>• Assurer la remise des courriers sortants ;</li>
                             <li>• Tenir le stock des fournitures de bureau et la fiche de stock ;</li>
                             <li>• Élaborer des factures, bon de commande, bordereau de livraison, facture pro forma ;
                             </li>
                             <li>• Saisir et mettre en forme divers documents (divers niveaux hiérarchiques) ;</li>
                             <li>• Préparation et constitution des dossiers ;</li>
                             <li>• Assurer la réception des PV de réunion et la signature des fiches de présence ;</li>
                             <li>• Préparer les dossiers d’appels d’offres.</li>
                         </ul>-->
            {{ opportunity.missions }}
            <p></p>

            <b> Pièces à fournir</b>
            <p>{{ opportunity.pieces }}</p>

            <b> Date limite</b>
            <p>{{ format_date(opportunity.date_limite) }}</p>
            <b> Date publication</b>
            <p>{{ format_date(opportunity.date_publication) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth from "../../../../helper/config";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "detailsOpportunity",
  data: () => ({
    loading: false,
    opportunity: {
      titre: null,
      type: null,
      missions: null,
      pieces: null,
      publicateur: null,
      date_limite: null,
      date_publication: null,
      description: null
    }
  }),
  components: {},
  watch: {
    "$route.params.id": function() {
      this.loadOpportunity();
    }
  },
  computed: {
    ...mapState({
      curr_user: state => state.user
    })
  },
  created() {
    this.loadOpportunity();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    loadOpportunity() {
      let url = auth.baseApi;
      this.loading = true;
      let id = this.$route.params.id;
      axios
        .get(url + "opportunities/" + id, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          if (response) {
            this.opportunity = response.data;
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    },
    deleteOpportunity() {
      if (confirm('Voulez-vous vraiment supprimer cette opportunité ?')) {
        let url = auth.baseApi;
        this.loading = true;
        let id = this.$route.params.id;
        axios
          .delete(url + "opportunities/delete/" + id, {
            headers: { Authorization: "Bearer " + auth.getSession() }
          })
          .then(response => {
            this.loading = false;
            if (response.data.status === true) {
              this.$toasted.global.all();
              this.$router.push({ name: "liste_opportunities" });
            } else this.$toasted.global.error();
          })
          .catch(error => {
            this.loading = false;
            // eslint-disable-next-line no-constant-condition
            if (error.response.status === 401) {
              this.errors = [];
              this.$toasted.global.all({ message: "Votre connexion a expirée" });
              auth.logout();
              this.$router.push({ name: "login" });
            } else {
              this.$toasted.global.error();
            }
          });
      }
    }
  }
};
</script>

<style scoped></style>
