<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="m-heading mb-3">
          <h3 class="m-title">Tableau de board</h3>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <b-card-group deck>
            <b-card class="card" title="Total inscrits">
              <b-spinner
                label="Text Centered"
                size="small"
                v-if="loading"
                variant="primary"
              ></b-spinner>
              <h2 v-else>{{ stats.total_inscrits }}</h2>
            </b-card>
            <b-card class="card" title="Total inscrits approuvés">
              <b-spinner
                label="Text Centered"
                size="small"
                v-if="loading"
                variant="primary"
              ></b-spinner>
              <h2 v-else>{{ stats.total_inscrits_appouves }}</h2>
            </b-card>
            <b-card class="card" title="Total inscrits non approuvés">
              <b-spinner
                label="Text Centered"
                size="small"
                v-if="loading"
                variant="primary"
              ></b-spinner>
              <h2 v-else>{{ stats.total_inscrits_non_approuves }}</h2>
            </b-card>
            <b-card title="Total inscrits certifiés">
              <b-spinner
                label="Text Centered"
                size="small"
                v-if="loading"
                variant="primary"
              ></b-spinner>
              <h2 v-else>{{ stats.total_inscrits_certifies }}</h2>
            </b-card>
            <b-card title="Total inscrits non certifiés">
              <b-spinner
                label="Text Centered"
                size="small"
                v-if="loading"
                variant="primary"
              ></b-spinner>
              <h2 v-else>{{ stats.total_inscrits_non_certifies }}</h2>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import auth from "../../../helper/config";
import axios from "axios";

function animationScript() {
  $(document).ready(function() {
    $(".card").hover(
      function() {
        $(this).animate(
          {
            marginTop: "-=1%"
          },
          200
        );
      },
      function() {
        $(this).animate(
          {
            marginTop: "0%"
          },
          200
        );
      }
    );
  });
}

export default {
  name: "Dashboard",
  data: () => ({
    loading: false,
    stats: {
      total_inscrits: 0,
      total_inscrits_appouves: 0,
      total_inscrits_non_approuves: 0,
      total_inscrits_certifies: 0,
      total_inscrits_non_certifies: 0
    }
  }),
  mounted() {
    animationScript();
    this.setupDashboard();
  },
  methods: {
    setupDashboard() {
      let url = auth.baseApi;
      this.loading = true;
      this.user_identity = "";
      axios
        .get(url + "stats", {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          this.stats = response.data;
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    }
  }
};
</script>

<style scoped>
.card:hover {
  -webkit-box-shadow: -1px 9px 40px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 9px 40px -12px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 9px 40px -12px rgba(0, 0, 0, 0.75);
}
</style>
