<template>
  <div>
    <side-bar></side-bar>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import sideBar from "./sideMenu";
import footerBar from "./footerBar";

export default {
  name: "DefaultContainer",
  data: () => ({}),
  components: {
    sideBar,
    footerBar
  },

  methods: {}
};
</script>

<style scoped></style>
