<template>
  <div class="manager">
    <div class="manager-header">
      <div class="manager-header__left">
        <a class="header__trigger" href="#0" id="manager-header__trigger">
          <span></span>
        </a>
        <a class="manager-brand" @click.prevent="$router.push('/home')">
          <img
            id="logo"
            alt="..."
            class="img-fluid"
            src="../../assets/img/logo.png"
            width="60"
          />
        </a>
      </div>
      <div class="manager-header__right">
        <form class="manager-search-form" @submit.prevent="submit">
          <label class="app-label app-label--icon">
            <span class="app-label__icon">
              <svg
                fill="none"
                height="14"
                viewBox="0 0 14 14"
                width="14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.15225 12.1503C7.39562 12.1503 8.55825 11.7836 9.52711 11.1458L12.4014 14L14 12.4214L11.158 9.59909C11.8847 8.61048 12.3045 7.38269 12.3045 6.07517C12.3045 2.72665 9.54325 0 6.15225 0C2.76125 0 0 2.72665 0 6.07517C0 9.42369 2.76125 12.1503 6.15225 12.1503ZM6.15225 2.23235C8.29988 2.23235 10.0438 3.95444 10.0438 6.07517C10.0438 8.1959 8.29988 9.918 6.15225 9.918C4.00461 9.918 2.26067 8.1959 2.26067 6.07517C2.26067 3.95444 4.00461 2.23235 6.15225 2.23235Z"
                  fill="#5E6366"
                />
              </svg>
            </span>
            <input
              v-model="search"
              class="app-label__field form-control"
              placeholder="Rechercher des étudiants par noms et prenoms"
              type="text"
            />
          </label>
        </form>
        <ul class="manager-header-actions">
          <li>
            <button class="btn btn--no-padding">
              <svg
                fill="none"
                height="14"
                viewBox="0 0 14 14"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.15225 12.1503C7.39562 12.1503 8.55825 11.7836 9.52711 11.1458L12.4014 14L14 12.4214L11.158 9.59909C11.8847 8.61048 12.3045 7.38269 12.3045 6.07517C12.3045 2.72665 9.54325 0 6.15225 0C2.76125 0 0 2.72665 0 6.07517C0 9.42369 2.76125 12.1503 6.15225 12.1503ZM6.15225 2.23235C8.29988 2.23235 10.0438 3.95444 10.0438 6.07517C10.0438 8.1959 8.29988 9.918 6.15225 9.918C4.00461 9.918 2.26067 8.1959 2.26067 6.07517C2.26067 3.95444 4.00461 2.23235 6.15225 2.23235Z"
                  fill="#99A7B3"
                />
              </svg>
            </button>
          </li>
          <!--
          <li>
            <div class="manager-header-bell">
              <div class="btn-group">
                <button
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="btn btn-transparent dropdown-toggle p-0"
                  data-toggle="dropdown"
                  type="button"
                >
                  <svg
                    fill="none"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M19 10C19 5.94082 16.7616 3.1235 13.8654 2.27771C13.7605 2.00636 13.5948 1.7541 13.3695 1.54243C12.5997 0.81919 11.4003 0.81919 10.6305 1.54243C10.4057 1.75364 10.2402 2.00525 10.1353 2.27592C7.23535 3.11803 5 5.92919 5 10C5 12.6339 4.46898 14.1098 3.48596 15.1793C3.32161 15.3582 2.87632 15.7678 2.57468 16.0453L2.57465 16.0453L2.57465 16.0453L2.5745 16.0454C2.43187 16.1766 2.32138 16.2783 2.28796 16.3119L2 16.604V20.0141H8.08798C8.29384 21.0761 8.87009 21.7867 9.9122 22.4226C11.1941 23.2049 12.8059 23.2049 14.0878 22.4226C15.0075 21.8614 15.6241 20.9989 15.8743 20.0141H22V16.604L21.712 16.3119C21.6817 16.2812 21.5757 16.1834 21.437 16.0555C21.1363 15.7781 20.6823 15.3592 20.5154 15.1769C19.5317 14.1024 19 12.6246 19 10ZM13.7367 20.0141H10.1786C10.3199 20.2769 10.5607 20.4754 10.954 20.7154C11.5963 21.1073 12.4037 21.1073 13.046 20.7154C13.3434 20.5339 13.5758 20.2937 13.7367 20.0141ZM19.0402 16.5274C19.2506 16.7573 19.7016 17.1774 20 17.4519V18.0141H4V17.4524C4.29607 17.1811 4.74843 16.7613 4.95849 16.5327C6.29422 15.0794 7 13.1178 7 10C7 6.21989 9.33277 4.01238 12 4.01238C14.6597 4.01238 17 6.23129 17 10C17 13.1078 17.706 15.07 19.0402 16.5274Z"
                      fill="#99A7B3"
                      fill-rule="evenodd"
                    />
                  </svg>
                  <span class="manager-header-bell__count">{{
                    notifications.length
                  }}</span>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  style="position: absolute; transform: translate3d(-136px, 24px, 0px); top: 0px; left: 0px; will-change: transform;"
                  v-if="notifications.length > 0"
                  x-placement="bottom-end"
                >
                  <span
                    :key="cpt"
                    class="dropdown-item"
                    v-for="(notification, cpt) in notifications"
                  >
                    <a
                      @click="
                        goTo(
                          'ancien_details',
                          notification.user.user_id,
                          notification.user.notif
                        )
                      "
                      ><span v-if="notification.user"
                        >Nouvel utilisateur
                        {{ notification.user.user_info | truncate(10) }}</span
                      ></a
                    >

                    <a
                      @click="
                        goTo(
                          'opportunity_details',
                          notification.opportunite.opp_id,
                          notification.opportunite.notif
                        )
                      "
                      ><span v-if="notification.opportunite"
                        >Nouvelle oppotunité
                        {{
                          notification.opportunite.opp_info | truncate(10)
                        }}</span
                      ></a
                    >
                    <a>
                      <span v-if="notification.actualite"
                        >Nouvelle Actualité
                        {{
                          notification.actualite.actu_info | truncate(10)
                        }}</span
                      ></a
                    >
                  </span>
                </div>
              </div>
            </div>
          </li>-->
        </ul>
      </div>
    </div>
    <div class="manager-body">
      <div class="manager-sidebar">
        <div class="m-user">
          <img
            :src="web_site_url + curr_user.path_picture"
            class="img-fluid"
            id="user_picture"
          />
          <div class="m-user__main">
            <h3 class="m-user__name">
              {{ curr_user.nom }} {{ curr_user.prenoms }}
            </h3>
            <div class="m-user__job">{{ curr_user.profession }}</div>
            <!--<h3 class="m-user__name">{{ $store.state.user.nom }} {{ $store.state.user.prenoms }}</h3>
                        <div class="m-user__job">{{ $store.state.user.profil.libelle }}</div>-->
          </div>
        </div>
        <ul class="manager-menu">
          <li class="manager-menu__item " v-if="curr_user.profil.id !== 1">
            <a
              @click="
                $router.push({ name: 'admin_dashboard' }).catch(err => {})
              "
              class="manager-menu__link"
            >
              <div class="manager-menu__round">
                <svg
                  fill="#7A8796"
                  height="23"
                  viewBox="0 0 23 23"
                  width="23"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 11.5C23 14.0077 22.1967 16.3278 20.8332 18.2171C18.7456 21.1133 15.3421 22.9974 11.5 22.9974C7.65786 22.9974 4.25437 21.1133 2.16682 18.2171C0.803309 16.3278 0 14.0077 0 11.5C0 5.14752 5.15016 0 11.5 0C17.8498 0 23 5.14752 23 11.5Z"
                    fill="#7A8796"
                  ></path>
                  <path
                    d="M20.8331 18.2171C18.7456 21.1133 15.3421 22.9974 11.4999 22.9974C7.65779 22.9974 4.25429 21.1133 2.16675 18.2171C4.25694 15.321 7.65779 13.4369 11.4999 13.4369C15.3421 13.4369 18.7429 15.321 20.8331 18.2171Z"
                    fill="#EAEFF5"
                  ></path>
                  <path
                    d="M11.5001 12.7684C13.5053 12.7684 15.1309 11.1428 15.1309 9.13764C15.1309 7.13244 13.5053 5.5069 11.5001 5.5069C9.49492 5.5069 7.86938 7.13244 7.86938 9.13764C7.86938 11.1428 9.49492 12.7684 11.5001 12.7684Z"
                    fill="#EAEFF5"
                  ></path>
                </svg>
              </div>
              <span>Tableau de board</span>
            </a>
          </li>
          <li class="manager-menu__item ">
            <a
              @click="
                $router.push({ name: 'show_user_profil' }).catch(err => {})
              "
              class="manager-menu__link"
            >
              <div class="manager-menu__round">
                <svg
                  fill="#7A8796"
                  height="23"
                  viewBox="0 0 23 23"
                  width="23"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 11.5C23 14.0077 22.1967 16.3278 20.8332 18.2171C18.7456 21.1133 15.3421 22.9974 11.5 22.9974C7.65786 22.9974 4.25437 21.1133 2.16682 18.2171C0.803309 16.3278 0 14.0077 0 11.5C0 5.14752 5.15016 0 11.5 0C17.8498 0 23 5.14752 23 11.5Z"
                    fill="#7A8796"
                  ></path>
                  <path
                    d="M20.8331 18.2171C18.7456 21.1133 15.3421 22.9974 11.4999 22.9974C7.65779 22.9974 4.25429 21.1133 2.16675 18.2171C4.25694 15.321 7.65779 13.4369 11.4999 13.4369C15.3421 13.4369 18.7429 15.321 20.8331 18.2171Z"
                    fill="#EAEFF5"
                  ></path>
                  <path
                    d="M11.5001 12.7684C13.5053 12.7684 15.1309 11.1428 15.1309 9.13764C15.1309 7.13244 13.5053 5.5069 11.5001 5.5069C9.49492 5.5069 7.86938 7.13244 7.86938 9.13764C7.86938 11.1428 9.49492 12.7684 11.5001 12.7684Z"
                    fill="#EAEFF5"
                  ></path>
                </svg>
              </div>
              <span>Profil</span>
            </a>
          </li>
          <li class="manager-menu__item ">
            <a
              @click="$router.push({ name: 'liste_ancien' }).catch(err => {})"
              class="manager-menu__link"
            >
              <div class="manager-menu__round">
                <svg
                  fill="#EAEFF5"
                  height="11"
                  viewBox="0 0 16 11"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.3333 0H2.44444C2.07625 0 1.77778 0.307799 1.77778 0.6875V0.916667H0.666667C0.298472 0.916667 0 1.22447 0 1.60417V9.39583C0 10.2818 0.696444 11 1.55556 11H14.6667C15.4031 11 16 10.3844 16 9.625V0.6875C16 0.307799 15.7015 0 15.3333 0ZM1.55556 9.625C1.49662 9.625 1.4401 9.60086 1.39842 9.55788C1.35675 9.5149 1.33333 9.45661 1.33333 9.39583V2.29167H1.77778V9.39583C1.77778 9.45661 1.75437 9.5149 1.71269 9.55788C1.67102 9.60086 1.61449 9.625 1.55556 9.625ZM8.11111 9.16667H3.88889C3.70481 9.16667 3.55556 9.01275 3.55556 8.82292V8.59375C3.55556 8.40391 3.70481 8.25 3.88889 8.25H8.11111C8.29519 8.25 8.44444 8.40391 8.44444 8.59375V8.82292C8.44444 9.01275 8.29519 9.16667 8.11111 9.16667ZM13.8889 9.16667H9.66667C9.48258 9.16667 9.33333 9.01275 9.33333 8.82292V8.59375C9.33333 8.40391 9.48258 8.25 9.66667 8.25H13.8889C14.073 8.25 14.2222 8.40391 14.2222 8.59375V8.82292C14.2222 9.01275 14.073 9.16667 13.8889 9.16667ZM8.11111 6.41667H3.88889C3.70481 6.41667 3.55556 6.26275 3.55556 6.07292V5.84375C3.55556 5.65391 3.70481 5.5 3.88889 5.5H8.11111C8.29519 5.5 8.44444 5.65391 8.44444 5.84375V6.07292C8.44444 6.26275 8.29519 6.41667 8.11111 6.41667ZM13.8889 6.41667H9.66667C9.48258 6.41667 9.33333 6.26275 9.33333 6.07292V5.84375C9.33333 5.65391 9.48258 5.5 9.66667 5.5H13.8889C14.073 5.5 14.2222 5.65391 14.2222 5.84375V6.07292C14.2222 6.26275 14.073 6.41667 13.8889 6.41667ZM13.8889 3.66667H3.88889C3.70481 3.66667 3.55556 3.51275 3.55556 3.32292V2.17708C3.55556 1.98725 3.70481 1.83333 3.88889 1.83333H13.8889C14.073 1.83333 14.2222 1.98725 14.2222 2.17708V3.32292C14.2222 3.51275 14.073 3.66667 13.8889 3.66667Z"
                    fill="#EAEFF5"
                  ></path>
                </svg>
              </div>
              <span>Liste des anciens</span>
            </a>
          </li>
          <li class="manager-menu__item" v-if="curr_user.profil.id !== 1">
            <a
              @click="
                $router.push({ name: 'liste_actualities' }).catch(err => {})
              "
              class="manager-menu__link"
            >
              <div class="manager-menu__round">
                <svg
                  fill="#EAEFF5"
                  height="13"
                  viewBox="0 0 13 13"
                  width="13"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.8449 9.81348H9.74146L6.49463 13.0635V9.81348H2.14439C1.66244 9.81348 1.18472 9.57227 0.71122 9.08984C0.237725 8.60742 0.000976562 8.12077 0.000976562 7.62988V2.24707C0.000976562 1.75618 0.237725 1.26953 0.71122 0.787109C1.18472 0.304688 1.66244 0.0634766 2.14439 0.0634766H10.8449C11.3268 0.0634766 11.8046 0.304688 12.278 0.787109C12.7515 1.26953 12.9883 1.75618 12.9883 2.24707V7.62988C12.9883 8.12077 12.7515 8.60742 12.278 9.08984C11.8046 9.57227 11.3268 9.81348 10.8449 9.81348ZM9.74146 2.50098H3.24781C3.01951 2.50098 2.82716 2.58138 2.67073 2.74219C2.51431 2.90299 2.4361 3.09554 2.4361 3.31982C2.4361 3.54411 2.51431 3.73454 2.67073 3.89111C2.82716 4.04769 3.01951 4.12598 3.24781 4.12598H9.74146C9.9613 4.12598 10.1515 4.04769 10.3122 3.89111C10.4728 3.73454 10.5532 3.54411 10.5532 3.31982C10.5532 3.09554 10.4728 2.90299 10.3122 2.74219C10.1515 2.58138 9.9613 2.50098 9.74146 2.50098ZM9.74146 5.75098H3.24781C3.01951 5.75098 2.82716 5.83138 2.67073 5.99219C2.51431 6.15299 2.4361 6.34554 2.4361 6.56982C2.4361 6.79411 2.51431 6.98454 2.67073 7.14111C2.82716 7.29769 3.01951 7.37598 3.24781 7.37598H9.74146C9.9613 7.37598 10.1515 7.29769 10.3122 7.14111C10.4728 6.98454 10.5532 6.79411 10.5532 6.56982C10.5532 6.34554 10.4728 6.15299 10.3122 5.99219C10.1515 5.83138 9.9613 5.75098 9.74146 5.75098Z"
                    fill="#EAEFF5"
                  ></path>
                </svg>
              </div>
              <span>Actualités</span>
            </a>
          </li>
          <li class="manager-menu__item">
            <a
              @click="
                $router.push({ name: 'liste_opportunities' }).catch(err => {})
              "
              class="manager-menu__link"
            >
              <div class="manager-menu__round">
                <svg
                  fill="#EAEFF5"
                  height="13"
                  viewBox="0 0 13 13"
                  width="13"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.8449 9.81348H9.74146L6.49463 13.0635V9.81348H2.14439C1.66244 9.81348 1.18472 9.57227 0.71122 9.08984C0.237725 8.60742 0.000976562 8.12077 0.000976562 7.62988V2.24707C0.000976562 1.75618 0.237725 1.26953 0.71122 0.787109C1.18472 0.304688 1.66244 0.0634766 2.14439 0.0634766H10.8449C11.3268 0.0634766 11.8046 0.304688 12.278 0.787109C12.7515 1.26953 12.9883 1.75618 12.9883 2.24707V7.62988C12.9883 8.12077 12.7515 8.60742 12.278 9.08984C11.8046 9.57227 11.3268 9.81348 10.8449 9.81348ZM9.74146 2.50098H3.24781C3.01951 2.50098 2.82716 2.58138 2.67073 2.74219C2.51431 2.90299 2.4361 3.09554 2.4361 3.31982C2.4361 3.54411 2.51431 3.73454 2.67073 3.89111C2.82716 4.04769 3.01951 4.12598 3.24781 4.12598H9.74146C9.9613 4.12598 10.1515 4.04769 10.3122 3.89111C10.4728 3.73454 10.5532 3.54411 10.5532 3.31982C10.5532 3.09554 10.4728 2.90299 10.3122 2.74219C10.1515 2.58138 9.9613 2.50098 9.74146 2.50098ZM9.74146 5.75098H3.24781C3.01951 5.75098 2.82716 5.83138 2.67073 5.99219C2.51431 6.15299 2.4361 6.34554 2.4361 6.56982C2.4361 6.79411 2.51431 6.98454 2.67073 7.14111C2.82716 7.29769 3.01951 7.37598 3.24781 7.37598H9.74146C9.9613 7.37598 10.1515 7.29769 10.3122 7.14111C10.4728 6.98454 10.5532 6.79411 10.5532 6.56982C10.5532 6.34554 10.4728 6.15299 10.3122 5.99219C10.1515 5.83138 9.9613 5.75098 9.74146 5.75098Z"
                    fill="#EAEFF5"
                  ></path>
                </svg>
              </div>
              <span>Opportunités</span>
            </a>
          </li>
          <li class="manager-menu__item " v-if="curr_user.profil.id !== 1">
            <a
              @click="$router.push({ name: 'liste_admins' }).catch(err => {})"
              class="manager-menu__link"
            >
              <div class="manager-menu__round">
                <svg
                  fill="#EAEFF5"
                  height="12"
                  viewBox="0 0 16 12"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 7.73065C16 8.13065 15.2 8.39731 14.2667 8.53064C13.6667 7.39731 12.4667 6.53064 11.0667 5.93064C11.2 5.73064 11.3333 5.59731 11.4667 5.39731H12C14.0667 5.33065 16 6.59731 16 7.73065ZM4.53333 5.33065H4C1.93333 5.33065 0 6.59731 0 7.73065C0 8.13065 0.8 8.39731 1.73333 8.53064C2.33333 7.39731 3.53333 6.53064 4.93333 5.93064L4.53333 5.33065ZM8 5.99731C9.46667 5.99731 10.6667 4.79731 10.6667 3.33065C10.6667 1.86398 9.46667 0.663979 8 0.663979C6.53333 0.663979 5.33333 1.86398 5.33333 3.33065C5.33333 4.79731 6.53333 5.99731 8 5.99731ZM8 6.66398C5.26667 6.66398 2.66667 8.39731 2.66667 9.99731C2.66667 11.3306 8 11.3306 8 11.3306C8 11.3306 13.3333 11.3306 13.3333 9.99731C13.3333 8.39731 10.7333 6.66398 8 6.66398ZM11.8 4.66398H12C13.1333 4.66398 14 3.79731 14 2.66398C14 1.53065 13.1333 0.663979 12 0.663979C11.6667 0.663979 11.4 0.730645 11.1333 0.863979C11.6667 1.53065 12 2.39731 12 3.33065C12 3.79731 11.9333 4.26398 11.8 4.66398ZM4 4.66398H4.2C4.06667 4.26398 4 3.79731 4 3.33065C4 2.39731 4.33333 1.53065 4.86667 0.863979C4.6 0.730645 4.33333 0.663979 4 0.663979C2.86667 0.663979 2 1.53065 2 2.66398C2 3.79731 2.86667 4.66398 4 4.66398Z"
                    fill="#EAEFF5"
                  ></path>
                </svg>
              </div>
              <span>Administrateurs</span>
            </a>
          </li>
          <li class="manager-menu__item">
            <a @click="logout" class="manager-menu__link">
              <div class="manager-menu__round">
                <svg
                  fill="#FFF4C6"
                  height="15"
                  viewBox="0 0 13 15"
                  width="13"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.742 3.65004V4.18052C10.359 5.02732 11.4612 6.72092 11.4612 8.67387C11.4612 9.78002 11.1075 10.8037 10.5063 11.6367C9.58684 12.9138 8.08381 13.7449 6.39021 13.7449C4.6966 13.7449 3.19554 12.9138 2.27408 11.6367C1.67287 10.8037 1.31922 9.78002 1.31922 8.67387C1.31922 6.72092 2.42143 5.02732 4.03841 4.18052V3.65004C2.15227 4.53417 0.84375 6.45176 0.84375 8.67387C0.84375 9.88415 1.2308 11.0021 1.88899 11.9137C2.89494 13.3107 4.53746 14.2184 6.39021 14.2184C8.24296 14.2184 9.88548 13.3107 10.8914 11.9137C11.5496 11.0021 11.9367 9.88415 11.9367 8.67387C11.9367 6.45176 10.6301 4.53417 8.742 3.65004Z"
                    fill="#EAEFF5"
                  ></path>
                  <path
                    d="M8.74179 3.65004H8.44708V4.35931L8.60426 4.44183C10.1269 5.24148 11.1643 6.83489 11.1643 8.67388C11.1643 9.71716 10.8303 10.6779 10.2645 11.4638C9.39605 12.6662 7.9834 13.4502 6.38803 13.4502C4.7907 13.4502 3.38002 12.6682 2.5116 11.4638C1.94576 10.6779 1.61175 9.71716 1.61175 8.67388C1.61175 6.83489 2.6511 5.23952 4.17377 4.44183L4.33095 4.35931V3.18833L3.9105 3.3848C1.92611 4.31413 0.548828 6.33388 0.548828 8.67388C0.548828 9.94703 0.957494 11.1259 1.64908 12.0866C2.70808 13.5563 4.43901 14.515 6.39 14.515C8.34098 14.515 10.07 13.5582 11.1309 12.0866C11.8225 11.1278 12.2312 9.94703 12.2312 8.67388C12.2312 6.33388 10.8539 4.31413 8.86753 3.38284L8.44708 3.18637V3.65004H8.74179L8.61604 3.91725C10.402 4.75423 11.6398 6.56965 11.6398 8.67388C11.6398 9.81932 11.2724 10.8783 10.6495 11.7408C9.69469 13.0651 8.14254 13.9237 6.38803 13.9237C4.63352 13.9237 3.07941 13.0631 2.12651 11.7408C1.50369 10.8783 1.13825 9.81932 1.13629 8.67388C1.13629 6.56965 2.37407 4.75423 4.16002 3.91725L4.03427 3.65004H3.73956V4.18052H4.03427L3.89674 3.91921C2.18742 4.81513 1.02037 6.60894 1.02037 8.67388C1.02037 9.8429 1.39563 10.9274 2.03221 11.8076C3.00475 13.1574 4.59422 14.0376 6.38803 14.0376C8.17987 14.0376 9.76935 13.1574 10.7439 11.8076C11.3804 10.9274 11.7557 9.8429 11.7557 8.67388C11.7557 6.60894 10.5886 4.81513 8.87932 3.91921L8.74179 4.18052H9.0365V3.65004H8.74179L8.61604 3.91725L8.74179 3.65004Z"
                    fill="#EAEFF5"
                  ></path>
                  <path
                    d="M6.38998 10.002C5.53728 10.002 4.84766 9.31242 4.84766 8.45973V2.02914C4.84766 1.17644 5.53728 0.486816 6.38998 0.486816C7.24267 0.486816 7.9323 1.17644 7.9323 2.02914V8.45776C7.9323 9.31046 7.24267 10.002 6.38998 10.002Z"
                    fill="#EAEFF5"
                  ></path>
                </svg>
              </div>
              <span>Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="manager-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../helper/config";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "sideMenu",
  props: {},
  data: () => ({
    web_site_url: auth.basePath,
    notifications: [],
    search: null
  }),
  computed: {
    ...mapState({
      curr_user: state => state.user
    })
  },
  created() {
    this.checkUserIsLogin(), this.loadUserNotifications();
  },
  methods: {
    logout() {
      auth.logout();
      this.$store.state.user = null;
      this.$router.push({ name: "login" });
    },
    checkUserIsLogin() {
      if (this.$store.state.user === null) {
        this.logout();
      }
    },
    loadUserNotifications() {
      if (this.$store.state.user !== null) {
        let url = auth.baseApi;
        let user_id = auth.getCurrentUser().id;
        axios
          .get(url + "users/notifications/" + user_id, {
            headers: { Authorization: "Bearer " + auth.getSession() }
          })
          .then(response => {
            this.loading = false;
            let data = [];
            for (let i = 0; i < response.data.length; i++) {
              data[i] = JSON.parse(response.data[i].data);
            }
            this.notifications = data;
          })
          .catch(error => {
            this.loading = false;
            // eslint-disable-next-line no-constant-condition
            if (error.response.status === 401) {
              this.errors = [];
              this.$toasted.global.all({
                message: "Votre connexion a expirée"
              });
              auth.logout();
              this.$router.push({ name: "login" });
            } else {
              this.$toasted.global.error();
            }
          });
      }
    },
    goTo(route, param, notif_id) {
      let url = auth.baseApi;
      axios
        .delete(url + "users/notifications/delete/" + notif_id, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          if (response.data.status === true) {
            this.$router.push({ name: route, params: { id: param } });
            window.location.reload();
          }
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    },
    submit() {
      if (this.search !== null) {
        let q = this.search;
        this.search = null;
        //console.log(q)
        this.$router.push({ name: "liste_ancien", query: { search: q } });
      }
    }
  }
};
</script>

<style scoped>
.manager-sidebar {
  background-color: #ffffff;
}

.app-label__field {
  color: #ffffff;
}

.img-fluid {
  height: 50px;
}

#user_picture {
  -moz-border-radius: 700px;
  -webkit-border-radius: 700px;
  border-radius: 700px;
}

#logo {
  width: 70px;
  height: 65px;
}
</style>
