<template>
  <div class="hero">
    <a
      @click.prevent="prev"
      class="hero-nav hero-nav--left"
      href="#"
      id="hero-nav-left"
    >
      <svg
        fill="none"
        height="20"
        viewBox="0 0 12 20"
        width="12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2.85714L4.5 10L12 17.1429L10.5 20L0 10L10.5 0L12 2.85714Z"
          fill="#DB3832"
        />
      </svg>
    </a>
    <a
      @click.prevent="next"
      class="hero-nav hero-nav--right"
      href="#0"
      id="hero-nav-right"
    >
      <svg
        fill="none"
        height="22"
        viewBox="0 0 12 22"
        width="12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.15713e-06 18.356L7.5 11.0584L3.40602e-06 3.76084L1.5 0.8418L12 11.0584L1.5 21.2751L2.15713e-06 18.356Z"
          fill="#DB3832"
        />
      </svg>
    </a>
    <div class="custom-slick-dots" id="hero-items">
      <slick :options="slickOptions" ref="slick">
        <div class="hero-item">
          <div class="container">
            <div class="hero-item__row row">
              <div class="col-md-6">
                <h2 class="hero__title">
                  Un réseau des anciens étudiants au service des
                  <span class="text-red">cadres d’entreprise</span>
                </h2>
                <div class="hero__description">
                  Retrouvez vos camarades de classe et rejoignez un réseau de
                  plus de 5000 anciens étudiants
                </div>

                <form
                  @submit.prevent="joinUs('Cadre d’entreprise')"
                  autocomplete="off"
                  class="form-newsletter"
                >
                  <div class="form-notif form-notif--success" v-if="success">
                    <svg
                      fill="none"
                      height="15"
                      viewBox="0 0 16 15"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M13.4797 4.46314C12.0702 6.04802 9.15723 9.27463 8.2032 10.3621C8.05046 10.5342 7.82524 10.6456 7.57412 10.6456C7.34631 10.6456 7.1392 10.5545 6.98905 10.4076C5.81632 9.26071 5.67653 9.12399 4.5038 7.97707C4.35365 7.83022 4.26045 7.62768 4.26045 7.40488C4.26045 6.95674 4.63065 6.59469 5.08887 6.59469C5.31668 6.59469 5.52379 6.68584 5.67394 6.83268C6.59555 7.73401 6.88032 8.01251 7.52753 8.64547C8.74388 7.258 11.4335 4.29951 12.5108 3.0836C11.2974 1.75938 9.5354 0.925926 7.57412 0.925926C3.91394 0.925926 0.946777 3.82778 0.946777 7.40741C0.946777 10.987 3.91394 13.8889 7.57412 13.8889C11.2343 13.8889 14.2015 10.987 14.2015 7.40741C14.2015 6.34725 13.9411 5.34663 13.4797 4.46314Z"
                        fill="black"
                        fill-rule="evenodd"
                      ></path>
                      <mask
                        height="14"
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        width="15"
                        x="0"
                        y="0"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M13.4797 4.46314C12.0702 6.04802 9.15723 9.27463 8.2032 10.3621C8.05046 10.5342 7.82524 10.6456 7.57412 10.6456C7.34631 10.6456 7.1392 10.5545 6.98905 10.4076C5.81632 9.26071 5.67653 9.12399 4.5038 7.97707C4.35365 7.83022 4.26045 7.62768 4.26045 7.40488C4.26045 6.95674 4.63065 6.59469 5.08887 6.59469C5.31668 6.59469 5.52379 6.68584 5.67394 6.83268C6.59555 7.73401 6.88032 8.01251 7.52753 8.64547C8.74388 7.258 11.4335 4.29951 12.5108 3.0836C11.2974 1.75938 9.5354 0.925926 7.57412 0.925926C3.91394 0.925926 0.946777 3.82778 0.946777 7.40741C0.946777 10.987 3.91394 13.8889 7.57412 13.8889C11.2343 13.8889 14.2015 10.987 14.2015 7.40741C14.2015 6.34725 13.9411 5.34663 13.4797 4.46314Z"
                          fill="white"
                          fill-rule="evenodd"
                        ></path>
                      </mask>
                      <g mask="url(#mask0)">
                        <path
                          clip-rule="evenodd"
                          d="M0 0H15.1482V14.8148H0V0Z"
                          fill="#287D3C"
                          fill-rule="evenodd"
                        ></path>
                      </g>
                    </svg>
                    <div class="form-notif__content">
                      Un mail de confirmation vous a été envoyé pour continuer
                    </div>
                    <svg
                      fill="none"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 6L18 18"
                        stroke="#287D3C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                      <path
                        d="M18 6L6 18"
                        stroke="#287D3C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                    </svg>
                  </div>
                  <div class="form-newsletter__inner">
                    <input
                      :class="errors.email ? 'is-invalid' : ''"
                      class="form-newsletter__input form-control"
                      placeholder="Votre email"
                      type="email"
                      v-model="form.email"
                      v-on:input="resetErrors('email')"
                    />
                    <button
                      :disabled="loading"
                      class="form-newsletter__btn btn"
                      type="submit"
                    >
                      <span v-if="loading"
                        >Invitation
                        <b-spinner
                          label="Spinning"
                          variant="light"
                          small
                        ></b-spinner
                      ></span>
                      <span v-else>Rejoindre le réseau</span>
                    </button>
                  </div>
                  <span class="text-danger" v-if="errors.email">{{
                    errors.email[0]
                  }}</span>
                </form>
              </div>
              <div class="col-md-5">
                <img
                  alt="..."
                  class="hero-item__img img-fluid"
                  src="../../../assets/img/slide-1.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="hero-item">
          <div class="container">
            <div class="hero-item__row row">
              <div class="col-md-6">
                <h2 class="hero__title">
                  Un réseau des anciens étudiants au service des
                  <span class="text-red">entrepreneurs</span>
                </h2>
                <div class="hero__description">
                  Retrouvez vos camarades de classe et rejoignez un réseau de
                  plus de 5000 anciens étudiants
                </div>

                <form
                  @submit.prevent="joinUs('Entrepreneur')"
                  autocomplete="off"
                  class="form-newsletter"
                >
                  <div class="form-notif form-notif--success" v-if="success">
                    <svg
                      fill="none"
                      height="15"
                      viewBox="0 0 16 15"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M13.4797 4.46314C12.0702 6.04802 9.15723 9.27463 8.2032 10.3621C8.05046 10.5342 7.82524 10.6456 7.57412 10.6456C7.34631 10.6456 7.1392 10.5545 6.98905 10.4076C5.81632 9.26071 5.67653 9.12399 4.5038 7.97707C4.35365 7.83022 4.26045 7.62768 4.26045 7.40488C4.26045 6.95674 4.63065 6.59469 5.08887 6.59469C5.31668 6.59469 5.52379 6.68584 5.67394 6.83268C6.59555 7.73401 6.88032 8.01251 7.52753 8.64547C8.74388 7.258 11.4335 4.29951 12.5108 3.0836C11.2974 1.75938 9.5354 0.925926 7.57412 0.925926C3.91394 0.925926 0.946777 3.82778 0.946777 7.40741C0.946777 10.987 3.91394 13.8889 7.57412 13.8889C11.2343 13.8889 14.2015 10.987 14.2015 7.40741C14.2015 6.34725 13.9411 5.34663 13.4797 4.46314Z"
                        fill="black"
                        fill-rule="evenodd"
                      ></path>
                      <mask
                        height="14"
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        width="15"
                        x="0"
                        y="0"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M13.4797 4.46314C12.0702 6.04802 9.15723 9.27463 8.2032 10.3621C8.05046 10.5342 7.82524 10.6456 7.57412 10.6456C7.34631 10.6456 7.1392 10.5545 6.98905 10.4076C5.81632 9.26071 5.67653 9.12399 4.5038 7.97707C4.35365 7.83022 4.26045 7.62768 4.26045 7.40488C4.26045 6.95674 4.63065 6.59469 5.08887 6.59469C5.31668 6.59469 5.52379 6.68584 5.67394 6.83268C6.59555 7.73401 6.88032 8.01251 7.52753 8.64547C8.74388 7.258 11.4335 4.29951 12.5108 3.0836C11.2974 1.75938 9.5354 0.925926 7.57412 0.925926C3.91394 0.925926 0.946777 3.82778 0.946777 7.40741C0.946777 10.987 3.91394 13.8889 7.57412 13.8889C11.2343 13.8889 14.2015 10.987 14.2015 7.40741C14.2015 6.34725 13.9411 5.34663 13.4797 4.46314Z"
                          fill="white"
                          fill-rule="evenodd"
                        ></path>
                      </mask>
                      <g mask="url(#mask0)">
                        <path
                          clip-rule="evenodd"
                          d="M0 0H15.1482V14.8148H0V0Z"
                          fill="#287D3C"
                          fill-rule="evenodd"
                        ></path>
                      </g>
                    </svg>
                    <div class="form-notif__content">
                      Un mail de confirmation vous a été envoyé pour continuer
                    </div>
                    <svg
                      fill="none"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 6L18 18"
                        stroke="#287D3C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                      <path
                        d="M18 6L6 18"
                        stroke="#287D3C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                    </svg>
                  </div>
                  <div class="form-newsletter__inner">
                    <input
                      :class="errors.email ? 'is-invalid' : ''"
                      class="form-newsletter__input form-control"
                      placeholder="Votre email"
                      type="email"
                      v-model="form.email"
                      v-on:input="resetErrors('email')"
                    />
                    <button
                      :disabled="loading"
                      class="form-newsletter__btn btn"
                      type="submit"
                    >
                      <span v-if="loading"
                        >Invitation
                        <b-spinner
                          label="Spinning"
                          variant="light"
                          small
                        ></b-spinner
                      ></span>
                      <span v-else>Rejoindre le réseau</span>
                    </button>
                  </div>
                  <span class="text-danger" v-if="errors.email">{{
                    errors.email[0]
                  }}</span>
                </form>
              </div>
              <div class="col-md-5">
                <img
                  alt="..."
                  class="hero-item__img img-fluid"
                  src="../../../assets/img/slide-3.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="hero-item">
          <div class="container">
            <div class="hero-item__row row">
              <div class="col-md-6">
                <h2 class="hero__title">
                  Un réseau des anciens étudiants au service des
                  <span class="text-red">chercheurs d'emploi</span>
                </h2>
                <div class="hero__description">
                  Retrouvez vos camarades de classe et rejoignez un réseau de
                  plus de 5000 anciens étudiants
                </div>

                <form
                  @submit.prevent="joinUs('Chercheur d\'emploi')"
                  autocomplete="off"
                  class="form-newsletter"
                >
                  <div class="form-notif form-notif--success" v-if="success">
                    <svg
                      fill="none"
                      height="15"
                      viewBox="0 0 16 15"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M13.4797 4.46314C12.0702 6.04802 9.15723 9.27463 8.2032 10.3621C8.05046 10.5342 7.82524 10.6456 7.57412 10.6456C7.34631 10.6456 7.1392 10.5545 6.98905 10.4076C5.81632 9.26071 5.67653 9.12399 4.5038 7.97707C4.35365 7.83022 4.26045 7.62768 4.26045 7.40488C4.26045 6.95674 4.63065 6.59469 5.08887 6.59469C5.31668 6.59469 5.52379 6.68584 5.67394 6.83268C6.59555 7.73401 6.88032 8.01251 7.52753 8.64547C8.74388 7.258 11.4335 4.29951 12.5108 3.0836C11.2974 1.75938 9.5354 0.925926 7.57412 0.925926C3.91394 0.925926 0.946777 3.82778 0.946777 7.40741C0.946777 10.987 3.91394 13.8889 7.57412 13.8889C11.2343 13.8889 14.2015 10.987 14.2015 7.40741C14.2015 6.34725 13.9411 5.34663 13.4797 4.46314Z"
                        fill="black"
                        fill-rule="evenodd"
                      ></path>
                      <mask
                        height="14"
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        width="15"
                        x="0"
                        y="0"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M13.4797 4.46314C12.0702 6.04802 9.15723 9.27463 8.2032 10.3621C8.05046 10.5342 7.82524 10.6456 7.57412 10.6456C7.34631 10.6456 7.1392 10.5545 6.98905 10.4076C5.81632 9.26071 5.67653 9.12399 4.5038 7.97707C4.35365 7.83022 4.26045 7.62768 4.26045 7.40488C4.26045 6.95674 4.63065 6.59469 5.08887 6.59469C5.31668 6.59469 5.52379 6.68584 5.67394 6.83268C6.59555 7.73401 6.88032 8.01251 7.52753 8.64547C8.74388 7.258 11.4335 4.29951 12.5108 3.0836C11.2974 1.75938 9.5354 0.925926 7.57412 0.925926C3.91394 0.925926 0.946777 3.82778 0.946777 7.40741C0.946777 10.987 3.91394 13.8889 7.57412 13.8889C11.2343 13.8889 14.2015 10.987 14.2015 7.40741C14.2015 6.34725 13.9411 5.34663 13.4797 4.46314Z"
                          fill="white"
                          fill-rule="evenodd"
                        ></path>
                      </mask>
                      <g mask="url(#mask0)">
                        <path
                          clip-rule="evenodd"
                          d="M0 0H15.1482V14.8148H0V0Z"
                          fill="#287D3C"
                          fill-rule="evenodd"
                        ></path>
                      </g>
                    </svg>
                    <div class="form-notif__content">
                      Un mail de confirmation vous a été envoyé pour continuer
                    </div>
                    <svg
                      fill="none"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 6L18 18"
                        stroke="#287D3C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                      <path
                        d="M18 6L6 18"
                        stroke="#287D3C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                    </svg>
                  </div>
                  <div class="form-newsletter__inner">
                    <input
                      :class="errors.email ? 'is-invalid' : ''"
                      class="form-newsletter__input form-control"
                      placeholder="Votre email"
                      type="email"
                      v-model="form.email"
                      v-on:input="resetErrors('email')"
                    />
                    <button
                      :disabled="loading"
                      class="form-newsletter__btn btn"
                      type="submit"
                    >
                      <span v-if="loading"
                        >Invitation
                        <b-spinner
                          label="Spinning"
                          variant="light"
                          small
                        ></b-spinner
                      ></span>
                      <span v-else>Rejoindre le réseau</span>
                    </button>
                  </div>
                  <span class="text-danger" v-if="errors.email">{{
                    errors.email[0]
                  }}</span>
                </form>
              </div>
              <div class="col-md-5">
                <img
                  alt="..."
                  class="hero-item__img img-fluid"
                  src="../../../assets/img/slide-2.png"
                />
              </div>
            </div>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
import auth from "../../../helper/config";
import axios from "axios";
import Slick from "vue-slick";

export default {
  name: "homeCaroussel",
  components: { Slick },
  data: () => ({
    loading: false,
    success: false,
    errors: [],
    form: {
      email: null,
      profession: null
    },
    slickOptions: {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: "linear",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    }
  }),
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$refs.slick.reSlick();
    },
    resetErrors(field) {
      if (field) {
        this.errors[field] = null;
        this.success = false;
      } else {
        this.errors = [];
        this.form.email = null;
      }
    },
    joinUs(profession) {
      let url = auth.baseApi;
      this.loading = true;
      this.form.profession = profession;
      axios
        .post(url + "joinus", this.form, {
          headers: { "Content-Type": "Application/json" }
        })
        .then(response => {
          this.loading = false;
          if (response.data.status === true) {
            this.success = true;
            this.form.email = null;
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 400) {
            this.errors = error.response.data.errors;
          } else {
            this.$toasted.global.error();
          }
        });
    }
  }
};
</script>

<style scoped></style>
