<template>
  <div>
    <div>
      <div class="vld-parent align-content-center">
        <loading :active.sync="loading" :is-full-page="true"> </loading>
      </div>
    </div>
    <div v-if="!loading">
      <div class="student-single">
        <!--<img
                        alt="..."
                        class="student-single__img img-fluid"
                        src="../../../../assets/img/user1-big.png"
                />-->
        <img
          alt="..."
          class="student-single__img img-fluid"
          :src="web_site_url + user.path_picture"
        />
        <div class="student-single__main">
          <h3 class="student-single__name">
            {{ user.nom }} {{ user.prenoms }}
          </h3>
          <div class="student-single__job">{{ user.profession }}</div>
          <div class="student-single__main-inner">
            <ul class="student-list">
              <li class="student-list__item">
                <div class="round">
                  <svg
                    fill="none"
                    height="12"
                    viewBox="0 0 13 12"
                    width="13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7066 0.576141L9.26914 0.0136407C9.0043 -0.0472967 8.73242 0.0909845 8.62461 0.339422L7.49961 2.96442C7.40117 3.19411 7.4668 3.46364 7.66133 3.62067L9.08164 4.78317C8.23789 6.58083 6.76367 8.07614 4.92852 8.9363L3.76602 7.51598C3.60664 7.32145 3.33945 7.25583 3.10977 7.35427L0.484766 8.47927C0.233984 8.58942 0.0957031 8.8613 0.156641 9.12614L0.719141 11.5636C0.777734 11.8168 1.00273 11.9996 1.26758 11.9996C7.26992 11.9996 12.1426 7.1363 12.1426 1.12458C12.1426 0.862078 11.9621 0.634735 11.7066 0.576141Z"
                      fill="#344051"
                    />
                  </svg>
                </div>
                <span>41526312</span>
              </li>
              <li class="student-list__item">
                <div class="round">
                  <svg
                    fill="none"
                    height="11"
                    viewBox="0 0 13 11"
                    width="13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1875 4.12045C12.1875 3.74129 11.9979 3.38913 11.6728 3.19963L6.5 0.625L1.32719 3.19963C1.02911 3.38913 0.8125 3.74129 0.8125 4.12045V9.29166C0.8125 9.88755 1.29992 10.375 1.89582 10.375H11.1042C11.7001 10.375 12.1875 9.88755 12.1875 9.29166V4.12045ZM6.5 6.71875L2.14883 3.875L6.5 1.70834L10.8512 3.875L6.5 6.71875Z"
                      fill="#344051"
                    />
                  </svg>
                </div>
                <span>{{ user.email }}</span>
              </li>
              <li class="student-list__item">
                <div class="round">
                  <svg
                    fill="none"
                    height="15"
                    viewBox="0 0 11 15"
                    width="11"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.93476 14.6974C0.772578 8.5263 0 7.89296 0 5.625C0 2.51839 2.46242 0 5.5 0C8.53758 0 11 2.51839 11 5.625C11 7.89296 10.2274 8.5263 6.06524 14.6974C5.7921 15.1009 5.20787 15.1009 4.93476 14.6974ZM5.5 7.96875C6.76566 7.96875 7.79167 6.91942 7.79167 5.625C7.79167 4.33058 6.76566 3.28125 5.5 3.28125C4.23434 3.28125 3.20833 4.33058 3.20833 5.625C3.20833 6.91942 4.23434 7.96875 5.5 7.96875Z"
                      fill="#344051"
                    />
                  </svg>
                </div>
                <span
                  >Pays: <b>{{ user.pays_residence }}</b></span
                >
              </li>
              <li class="student-list__item">
                <div class="round">
                  <svg
                    fill="none"
                    height="14"
                    viewBox="0 0 12 14"
                    width="12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 6.33333H2.66667V7.66666H4V6.33333ZM6.66667 6.33333H5.33333V7.66666H6.66667V6.33333ZM9.33333 6.33333H8V7.66666H9.33333V6.33333ZM10.6667 1.66666H10V0.333328H8.66667V1.66666H3.33333V0.333328H2V1.66666H1.33333C0.593333 1.66666 0.00666666 2.26666 0.00666666 2.99999L0 12.3333C0 12.687 0.140476 13.0261 0.390524 13.2761C0.640573 13.5262 0.979711 13.6667 1.33333 13.6667H10.6667C11.4 13.6667 12 13.0667 12 12.3333V2.99999C12 2.26666 11.4 1.66666 10.6667 1.66666ZM10.6667 12.3333H1.33333V5H10.6667V12.3333Z"
                      fill="#344051"
                    />
                  </svg>
                </div>
                <span
                  >Période:
                  <b>{{
                    user.first_year + "-" + user.last_grade_year
                  }}</b></span
                >
              </li>
            </ul>
            <ul class="student-state-list">
              <li class="student-state-list-item">
                <span class="student-state-list-item__value">{{
                  user.nombre_connexions
                }}</span>
                <span class="student-state-list-item__label">Connexions</span>
              </li>
              <li class="student-state-list-item">
                <span class="student-state-list-item__value">{{
                  user.nombre_vues
                }}</span>
                <span class="student-state-list-item__label">Vues</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="student-single-content">
              <h3 class="student-single-content__title">Biographie</h3>
              <div
                class="student-single-content__content"
                v-if="user.biographie"
              >
                {{ user.biographie }}
              </div>
              <div v-else>
                Rendez-vous sur votre profil pour renseignez une biographie
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth from "../../../../helper/config";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "oldStudentDetails",
  data: () => ({
    loading: false,
    web_site_url: auth.basePath,
    user: {
      nom: null,
      prenoms: null,
      profession: null,
      pays_residence: null,
      email: null,
      last_grade_year: null,
      first_year: null,
      biographie: null
    }
  }),
  components: { Loading },
  watch: {
    "$route.params.id": function() {
      this.loadOldStudentData();
    }
  },
  created() {
    this.loadOldStudentData();
  },
  methods: {
    loadOldStudentData() {
      let url = auth.baseApi;
      let student_id = this.$route.params.id;
      this.loading = true;
      axios
        .get(url + "users/profil/" + student_id, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          if (response) {
            this.user = response.data;
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    }
  }
};
</script>

<style scoped>
.img-fluid {
  width: 10%;
}
</style>
