<template>
    <div>
        <home-caroussel></home-caroussel>

        <home-esgis-birthday></home-esgis-birthday>

        <home-services></home-services>

        <home-actualities :scroll="scroll" />

        <home-banner></home-banner>

        <home-partenaires></home-partenaires>
    </div>
</template>

<script>
    // @ is an alias to /src

    import HomeServices from "@/views/shared/homeComponents/homeServices";
    import HomePartenaires from "@/views/shared/homeComponents/homePartenaires";
    import HomeEsgisBirthday from "@/views/shared/homeComponents/homeEsgisBirthday";
    import HomeCaroussel from "@/views/shared/homeComponents/homeCaroussel";
    import HomeBanner from "@/views/shared/homeComponents/homeBanner";
    import HomeActualities from "@/views/shared/homeComponents/homeActualities";
    import auth from "../helper/config";
    import axios from "axios";

    export default {
        name: "home",
        components: {
            HomeActualities,
            HomeBanner,
            HomeCaroussel,
            HomeEsgisBirthday,
            HomePartenaires,
            HomeServices
        },
        data: () => ({
            scroll: ""
        }),
        methods: {
            activate(){
                let token = this.$route.params.token;
                if (token){
                    let url = auth.basePath;
                    this.loading = true;

                    axios
                        .get(url + "user/verify/" + token, {
                            headers: {}
                        })
                        .then(response => {
                            this.loading = false;
                            if (response.data == '1') {
                                this.$toasted.global.all({ message: "Votre compte a été réactivé avec succès" });
                            } else this.$toasted.global.error({ message: 'Votre compte a déjà été activé'});
                        })
                        .catch(error => {
                            this.loading = false;
                            // eslint-disable-next-line no-constant-condition
                            if (error.response.status === 401) {
                                this.errors = [];
                                this.$toasted.global.all({ message: "Votre connexion a expirée" });
                                auth.logout();
                                this.$router.push({ name: "login" });
                            } else {
                                this.$toasted.global.error();
                            }
                        });
                }
            }
        },
        created() {
            this.scroll = this.$route.query.scroll;
            this.activate()
        }
    };
</script>

<style scoped></style>
