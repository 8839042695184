/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap';
import BootstrapVue from 'bootstrap-vue';
import VueAgile from 'vue-agile';
import VueLoading from 'vuejs-loading-plugin'
// using default options
Vue.use(VueLoading)

Vue.use(VueAgile)
Vue.use(BootstrapVue)
var VueTruncate = require('vue-truncate-filter')
Vue.use(VueTruncate)


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
