<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <header class="header" id="header">
          <div class="header__inner">
            <a class="header__brand" @click.prevent="$router.push('/home')">
              <img
                alt="..."
                class="header__logo img-fluid"
                src="../../assets/img/logo.png"
              />
            </a>
            <nav class="header-nav" role="navigation">
              <a class="js-header-trigger" href="#">
                <svg
                  fill="none"
                  height="23"
                  viewBox="0 0 33 23"
                  width="33"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 2H32.5" stroke="#DB3832" stroke-width="3" />
                  <path d="M0 11.5H32.5" stroke="#DB3832" stroke-width="3" />
                  <path d="M0 21H32.5" stroke="#DB3832" stroke-width="3" />
                </svg>
              </a>
              <div class="header-nav-inner">
                <div class="menu-header-mobile">
                  <img
                    alt="..."
                    class="img-fluid"
                    src="../../assets/img/logo.png"
                    width="70"
                  />
                  <a
                    class="menu-header-mobile__trigger js-header-trigger"
                    href="#"
                  >
                    <svg
                      fill="none"
                      height="18"
                      viewBox="0 0 18 18"
                      width="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.1209 9.00001L17.0584 3.06251C17.3402 2.78121 17.4987 2.3995 17.4991 2.00133C17.4994 1.60317 17.3416 1.22118 17.0603 0.939383C16.779 0.657591 16.3973 0.499084 15.9991 0.498732C15.601 0.49838 15.219 0.656213 14.9372 0.937508L8.99967 6.87501L3.06217 0.937508C2.78038 0.655716 2.39819 0.497406 1.99967 0.497406C1.60116 0.497406 1.21896 0.655716 0.937172 0.937508C0.65538 1.2193 0.49707 1.60149 0.49707 2.00001C0.49707 2.39852 0.65538 2.78072 0.937172 3.06251L6.87467 9.00001L0.937172 14.9375C0.65538 15.2193 0.49707 15.6015 0.49707 16C0.49707 16.3985 0.65538 16.7807 0.937172 17.0625C1.21896 17.3443 1.60116 17.5026 1.99967 17.5026C2.39819 17.5026 2.78038 17.3443 3.06217 17.0625L8.99967 11.125L14.9372 17.0625C15.219 17.3443 15.6012 17.5026 15.9997 17.5026C16.3982 17.5026 16.7804 17.3443 17.0622 17.0625C17.344 16.7807 17.5023 16.3985 17.5023 16C17.5023 15.6015 17.344 15.2193 17.0622 14.9375L11.1209 9.00001Z"
                        fill="#344051"
                      />
                    </svg>
                  </a>
                </div>
                <div class="menu-menu-container">
                  <ul class="header__menu" id="menu-menu">
                    <li class="menu-item">
                      <a
                        @click="$router.push({ name: 'home' }).catch(err => {})"
                        class="menu-link"
                        >Accueil</a
                      >
                    </li>
                    <!--<li class="menu-item">
                      <a
                        @click="$router.push({ name: 'home' }).catch(err => {})"
                        class="menu-link"
                        >Contact</a
                      >
                    </li>-->
                    <li class="menu-item" v-if="isEmpty(curr_user)">
                      <div class="header-actions">
                        <div class="header-btns">
                          <!--<button
                            @click="
                              $router
                                .push({ name: 'register' })
                                .catch(err => {})
                            "
                            class="btn btn-red"
                          >
                            S’inscrire
                          </button>-->
                          <button
                            @click="
                              $router.push({ name: 'login' }).catch(err => {})
                            "
                            class="btn btn-outline-danger"
                          >
                            Se Connecter
                          </button>
                        </div>
                        <ul class="social-list">
                          <li class="social-list__item">
                            <a
                              class="btn btn--no-padding btn-social btn-social--facebook"
                              href="https://web.facebook.com/esgisalumni/"
                              target="_blank"
                            >
                              <svg
                                fill="none"
                                height="13"
                                viewBox="0 0 6 13"
                                width="6"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.75839 6.0691H3.97873V12.1616H1.28239V6.0691H0V3.92794H1.28239V2.54237C1.28239 1.55154 1.78606 0 4.00273 0L6 0.0078082V2.08616H4.55085C4.31315 2.08616 3.9789 2.19714 3.9789 2.6698V3.92994H5.99396L5.75839 6.0691Z"
                                  fill="white"
                                />
                              </svg>
                            </a>
                          </li>
                          <!--<li class="social-list__item">
                            <a
                              class="btn btn&#45;&#45;no-padding btn-social btn-social&#45;&#45;linkedin"
                              href="https://www.linkedin.com/company/e-business-afrique"
                              target="_blank"
                            >
                              <svg
                                fill="none"
                                height="12"
                                viewBox="0 0 13 12"
                                width="13"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.83229 3.8999H0.15332V12.0001H2.83229V3.8999Z"
                                  fill="white"
                                />
                                <path
                                  d="M11.6262 4.63864C11.0631 4.02017 10.318 3.71094 9.39369 3.71094C9.05242 3.71094 8.74244 3.75389 8.46373 3.83692C8.18503 3.92282 7.94898 4.04021 7.75844 4.19197C7.56505 4.34372 7.41433 4.48689 7.30057 4.61573C7.1925 4.73885 7.08728 4.88488 6.98489 5.04809V3.89705H4.31445L4.32298 4.28932C4.32867 4.54988 4.33152 5.35732 4.33152 6.70879C4.33152 8.06026 4.32583 9.82404 4.31445 11.9973H6.98489V7.47901C6.98489 7.20127 7.01333 6.9808 7.07306 6.8176C7.18681 6.53986 7.35745 6.30793 7.58781 6.11895C7.81816 5.92998 8.10256 5.83549 8.44383 5.83549C8.91023 5.83549 9.2515 5.9987 9.47048 6.32225C9.68946 6.6458 9.80038 7.09533 9.80038 7.66799V12.0001H12.4708V7.35875C12.4708 6.16477 12.1893 5.25711 11.6262 4.63864Z"
                                  fill="white"
                                />
                                <path
                                  d="M1.51012 0C1.06078 0 0.69676 0.131711 0.418056 0.395132C0.139352 0.661417 0 0.993558 0 1.39728C0 1.79528 0.136508 2.12742 0.406681 2.3937C0.676853 2.65999 1.03519 2.79456 1.47884 2.79456H1.4959C1.95093 2.79456 2.31779 2.65999 2.5965 2.3937C2.8752 2.12742 3.01171 1.79528 3.00602 1.39728C3.00034 0.993558 2.86383 0.661417 2.59081 0.395132C2.32064 0.131711 1.95946 0 1.51012 0Z"
                                  fill="white"
                                />
                              </svg>
                            </a>
                          </li>-->
                        </ul>
                      </div>
                    </li>
                    <li class="menu-item" v-else>
                      <div class="header-actions">
                        <div class="header-btns">
                          <button
                            @click="
                              $router
                                .push({ name: 'show_user_profil' })
                                .catch(err => {})
                            "
                            class="btn btn-outline-danger"
                          >
                            Mon compte
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "topBar",
  props: {
    logout: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      years: "getYears",
      pays: "getPays",
      profession: "getProfession"
    }),
    ...mapState({
      curr_user: state => state.user
    })
  },
  methods: {
    addMenu() {
      alert("ok");
    },
    logoutIn() {
      this.logout();
    },
    isEmpty(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
  }
};
</script>

<style scoped></style>
