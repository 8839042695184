<template>
  <div class="section section--gray">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="section-heading section-heading--center">
            <h2 class="section-title">NOS PARTENAIRES</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="partner-wrapper col">
          <div class="partner">
            <a
              href="#0"
              @click.prevent="prev"
              id="partner-nav-left"
              class="partner-nav partner-nav--left"
            >
              <svg
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2.85714L4.5 10L12 17.1429L10.5 20L0 10L10.5 0L12 2.85714Z"
                  fill="#DB3832"
                />
              </svg>
            </a>
            <a
              href="#0"
              @click.prevent="next"
              id="partner-nav-right"
              class="partner-nav partner-nav--right"
            >
              <svg
                width="12"
                height="22"
                viewBox="0 0 12 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.15713e-06 18.356L7.5 11.0584L3.40602e-06 3.76084L1.5 0.8418L12 11.0584L1.5 21.2751L2.15713e-06 18.356Z"
                  fill="#DB3832"
                />
              </svg>
            </a>
            <slick ref="slick" :options="slickOptions">
              <div class="partner-item">
                <img :src="asset('front/img/logo-esgis.1044be8c.png')" alt="" />
              </div>
              <div class="partner-item">
                <img :src="asset('front/img/logo-eba.bdf75ed7.png')" alt="" />
              </div>
              <div class="partner-item">
                <img :src="asset('front/img/logo_conglomerat.jpeg')" alt="" />
              </div>
              <div class="partner-item">
                <img
                  :src="asset('front/img/logo_partenaire_esgis_alumni.jpeg')"
                  alt=""
                />
              </div>
              <div class="partner-item">
                <img :src="asset('front/img/seed_sarl.png')" alt="" />
              </div>
            </slick>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";
import url from "@/helper/config";
//front/img/logo-eba.bdf75ed7.png
export default {
  name: "homePartenaires",
  components: { Slick },

  data: () => ({
    slickOptions: {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
            arrows: false
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
            arrows: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false
          }
        }
      ]
    }
  }),
  methods: {
    asset(file) {
      return url.basePath + file;
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$refs.slick.reSlick();
    }
  }
};
</script>

<style scoped></style>
