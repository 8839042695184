<template>
  <main class="main main--gray">
    <div class="auth-box">
      <div class="auth-box__main">
        <h3 class="auth-box__title">Créer un mot de passe pour votre compte</h3>
        <form @submit.prevent="createPassword()" autocomplete="off">
          <div
            :class="!valid_data ? 'alert-danger' : ''"
            class="alert alert-dismissible fade show"
            role="alert"
            v-if="!valid_data"
          >
            <p>
              <strong>{{ message }}</strong>
            </p>
          </div>
          <div class="form-group">
            <label class="app-label">
              <span>Nouveau mot de passe</span>
              <input
                :class="errors.password ? 'is-invalid' : ''"
                class="form-control"
                type="password"
                v-model="form.password"
                v-on:input="resetErrors('password')"
              />
              <password v-model="form.password" :strength-meter-only="true" />
              <div class="form-info">
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password[0] }}
                </span>
              </div>
            </label>
          </div>
          <div class="form-group">
            <label class="app-label">
              <span>Confirmer le mot de passe</span>
              <input
                :class="errors.password_confirmation ? 'is-invalid' : ''"
                class="form-control"
                type="password"
                v-model="form.password_confirmation"
                v-on:input="resetErrors('password_confirmation')"
              />
            </label>
            <span class="text-danger" v-if="errors.password_confirmation">
              {{ errors.password[0] }}
            </span>
          </div>
          <button :disabled="loading" class="btn btn-red" type="submit">
            <span v-if="loading">
              Validation
              <b-spinner label="Spinning" variant="light" small></b-spinner>
            </span>
            <span v-else>Valider</span>
          </button>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import auth from "../../../helper/config";
import Password from "vue-password-strength-meter";

export default {
  name: "CreatePassword",
  data: () => ({
    verification_code: null,
    loading: false,
    form: {
      password: null,
      password_confirmation: null
    },
    errors: [],
    message: "",
    valid_data: true
  }),
  components: { Password },
  watch: {
    "$route.params.code": function() {
      if (this.$route.params.code) {
        alert(this.$route.params.code);
        this.verification_code = this.$route.params.code;
      }
    }
  },
  methods: {
    resetErrors(field) {
      if (field) {
        this.errors[field] = null;
      } else {
        this.errors = [];
        this.form.password = null;
        this.form.password_confirmation = null;
      }
    },
    createPassword() {
      let url = auth.baseApi;
      this.loading = true;
      //let code = this.verification_code;
      axios
        .post(url + "password/create/" + this.$route.params.code, this.form)
        .then(response => {
          this.loading = false;
          if (response.data.status === true) {
            this.$toasted.global.all({
              message: "Félicitations!!! Mot de passe défini avec succès"
            });
            this.$router.push({ name: "login" });
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 400) {
            this.errors = error.response.data.errors;
          } else if (error.response.status === 422) {
            this.valid_data = false;
            this.message =
              "Ce lien n'est pas valide. Veuillez utiliser le lien qui vous a été envoyé par email.";
          }
        });
    }
  }
};
</script>

<style scoped></style>
