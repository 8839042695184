<template>
  <div class="section section--gray">
    <div class="container">
      <div class="row">
        <div class="service-item-wrapper col-md-6 col-lg-4">
          <div class="service-item">
            <img
              alt="..."
              class="service-item__img img-fluid"
              src="../../../assets/img/service-1.jpg"
            />
            <div class="service-item__body">
              <h3 class="service-item__title">Cadres d’entreprise</h3>
              <div class="service-item__content">
                <ul class="service-item-list">
                  <li class="service-item-list__item">
                    Développez votre carrière
                  </li>
                  <li class="service-item-list__item">
                    Trouvez de nouvelles opportunités
                  </li>
                  <li class="service-item-list__item">
                    Recrutez des stagiaires
                  </li>
                </ul>
              </div>
              <!--<div class="service-item__actions">
                <button
                  @click="goToRegister('Cadre d\'entreprises')"
                  class="btn btn-dark"
                >
                  S’inscrire
                </button>
                <button
                  @click="$router.push({ name: 'login' }).catch(err => {})"
                  class="btn btn-outline-dark"
                >
                  Se Connecter
                </button>
              </div>-->
            </div>
          </div>
        </div>
        <div class="service-item-wrapper col-md-6 col-lg-4">
          <div class="service-item">
            <img
              alt="..."
              class="service-item__img img-fluid"
              src="../../../assets/img/service-2.jpg"
            />
            <div class="service-item__body">
              <h3 class="service-item__title">Chercheurs d’emploi</h3>
              <div class="service-item__content">
                <ul class="service-item-list">
                  <li class="service-item-list__item">Trouvez un emploi</li>
                  <li class="service-item-list__item">
                    Cherchez des modèles à suivre
                  </li>
                  <li class="service-item-list__item">Trouvez des mentors</li>
                </ul>
              </div>
              <!--<div class="service-item__actions">
                <button
                  @click="goToRegister('Chercheur d\'emploie')"
                  class="btn btn-dark"
                >
                  S’inscrire
                </button>
                <button
                  @click="$router.push({ name: 'login' }).catch(err => {})"
                  class="btn btn-outline-dark"
                >
                  Se Connecter
                </button>
              </div>-->
            </div>
          </div>
        </div>
        <div class="service-item-wrapper col-md-6 col-lg-4">
          <div class="service-item">
            <img
              alt="..."
              class="service-item__img img-fluid"
              src="../../../assets/img/service-3.jpg"
            />
            <div class="service-item__body">
              <h3 class="service-item__title">Entrepreuneurs</h3>
              <div class="service-item__content">
                <ul class="service-item-list">
                  <li class="service-item-list__item">
                    Trouvez de nouveaux clients
                  </li>
                  <li class="service-item-list__item">
                    Trouvez de nouveaux partenaires
                  </li>
                  <li class="service-item-list__item">Recrutez des employés</li>
                </ul>
              </div>
              <!--<div class="service-item__actions">
                <button
                  @click="goToRegister('Entrepreneur')"
                  class="btn btn-dark"
                >
                  S’inscrire
                </button>
                <button
                  @click="$router.push({ name: 'login' }).catch(err => {})"
                  class="btn btn-outline-dark"
                >
                  Se Connecter
                </button>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeServices",
  data: () => ({
    profession: null
  }),
  methods: {
    goToRegister(profession) {
      let q = profession;
      this.$router.push({ name: "register", query: { search: q } });
    }
  }
};
</script>

<style scoped>
.btn-dark {
  font-size: 1.05em;
}

.btn-outline-dark {
  font-size: 1.05em;
}
</style>
