<template>
  <main class="main main--gray">
    <div class="auth-box">
      <div class="auth-box__main">
        <h3 class="auth-title">Reinitialisez votre mot de passe</h3>
        <div class="auth-subtitle">
          Entrez votre email pour recevoir un lien de réinitialisation de mot de
          passe
        </div>
        <form @submit.prevent="requestForNewPassword()" autocomplete="off">
          <div
            :class="valid_email ? 'alert-success' : 'alert-danger'"
            class="alert alert-dismissible fade show"
            role="alert"
            v-if="message"
          >
            {{ message }}
          </div>
          <div class="form-group">
            <label class="app-label">
              <span>Email</span>
              <input
                :class="errors.email ? 'is-invalid' : ''"
                class="form-control"
                type="email"
                v-model="form.email"
                v-on:input="resetErrors('email')"
              />
            </label>
            <span class="text-danger" v-if="errors.email">{{
              errors.email[0]
            }}</span>
          </div>
          <button :disabled="loading" class="btn btn-red" type="submit">
            <span v-if="loading">Demande en cours...</span>
            <span v-else>Réinitialiser le mot de passe</span>
          </button>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import auth from "../../../helper/config";
import axios from "axios";

export default {
  name: "resetRequest",
  data: () => ({
    loading: false,
    form: {
      email: null
    },
    errors: [],
    message: "",
    valid_email: null
  }),
  methods: {
    resetErrors(field) {
      if (field) {
        this.errors[field] = null;
      } else {
        this.errors = [];
        this.form.email = null;
      }
    },
    requestForNewPassword() {
      let url = auth.baseApi;
      this.loading = true;
      axios
        .post(url + "password/reset/request", this.form)
        .then(response => {
          this.loading = false;
          this.valid_email = response.data.status;
          if (this.valid_email === true) {
            this.$toasted.global.check();
            this.message =
              "Un lien de réinitialisation de mot de passe vous a été envoyé. Vérifiez vos emails";
            this.resetErrors();
          } else {
            this.message = response.data.error;
          }
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 400) {
            this.errors = error.response.data.errors;
          } else {
            this.$toasted.global.error();
          }
        });
    }
  }
};
</script>

<style scoped></style>
