<template>
  <main class="main main--gray">
    <div class="auth-box">
      <div class="auth-box__main">
        <h3 class="auth-box__title">Entrer un nouveau mot de passe</h3>
        <form @submit.prevent="updatePassword()" autocomplete="off">
          <div
            :class="!valid_data ? 'alert-danger' : ''"
            class="alert alert-dismissible fade show"
            role="alert"
            v-if="!valid_data"
          >
            <p>
              <strong>{{ message }}</strong>
            </p>
            <a @click="$router.push({ name: 'reset_password_request' })"
              ><u>Réinitiliser mon mot de passe</u></a
            >
          </div>
          <div class="form-group">
            <label class="app-label">
              <span>Nouveau mot de passe</span>
              <input
                :class="errors.password ? 'is-invalid' : ''"
                class="form-control"
                type="password"
                v-model="form.password"
                v-on:input="resetErrors('password')"
              />
              <!--<div class="form-info">
                Le mot de passe doit contenir au moins huit caractères, dont au
                moins une lettre et un chiffre.
              </div>-->
            </label>
            <span class="text-danger" v-if="errors.password">{{
              errors.password[0]
            }}</span>
          </div>
          <div class="form-group">
            <label class="app-label">
              <span>Confirmer le mot de passe</span>
              <input
                :class="errors.password_confirmation ? 'is-invalid' : ''"
                class="form-control"
                type="password"
                v-model="form.password_confirmation"
                v-on:input="resetErrors('password_confirmation')"
              />
            </label>
            <span class="text-danger" v-if="errors.password_confirmation">{{
              errors.password[0]
            }}</span>
          </div>
          <button :disabled="loading" class="btn btn-red" type="submit">
            <span v-if="loading">Validation...</span>
            <span v-else>Valider</span>
          </button>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import auth from "../../../helper/config";

export default {
  name: "resetPasswordApply",
  data: () => ({
    loading: false,
    form: {
      password: null,
      password_confirmation: null
    },
    errors: [],
    message: "",
    valid_data: true
  }),
  methods: {
    resetErrors(field) {
      if (field) {
        this.errors[field] = null;
      } else {
        this.errors = [];
        this.form.password = null;
        this.form.password_confirmation = null;
      }
    },
    updatePassword() {
      let url = auth.baseApi;
      this.loading = true;
      let token = this.$route.params.token;
      axios
        .post(url + "password/reset/apply/" + token, this.form)
        .then(response => {
          this.loading = false;
          if (response.data.status === true) {
            this.$toasted.global.all();
            auth.logout();
            this.$router.push({ name: "login" });
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 400) {
            this.errors = error.response.data.errors;
          } else if (error.response.status === 422) {
            this.valid_data = false;
            this.message =
              "Ce lien n'est plus valide. Veuillez renvoyer une nouvelle demande.";
          }
        });
    }
  }
};
</script>

<style scoped></style>
