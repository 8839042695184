<template>
  <div>
    <div class="container">
      <b-modal id="modal-delete-actu" title="Supprimer l'actualité">
        <template v-slot:default="{ hide }">
          <div class="delete-confirm-form__title">
            Supprimer l’actualité ?
          </div>
        </template>
        <div slot="modal-footer">
          <div class="modal-actions">
            <button
              :disabled="loading_delete"
              @click="deleteActu(actu_selceted.id)"
              class="btn btn-red"
              type="submit"
            >
              <span v-if="loading_delete"
                >Suppresion <b-spinner small label="Small Spinner"></b-spinner
              ></span>
              <span v-else>Oui, Supprimer</span>
            </button>
          </div>
        </div>
      </b-modal>
      <div class="row">
        <div class="col-md-12">
          <div class="m-heading mb-3">
            <h3 class="m-title">Actualités ({{ pagination.total }})</h3>
            <div class="row">
              <button
                @click="loadActualities()"
                class="btn btn-primary mx-1"
                data-toggle="modal"
              >
                <span>Actualiser</span>
              </button>
              <button
                @click="$router.push({ name: 'actuality_add' })"
                class="btn btn-primary"
                v-if="curr_user.profil.id !== 1"
              >
                <svg
                  fill="none"
                  height="13"
                  viewBox="0 0 13 13"
                  width="13"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M7.04232 5.95834H11.9173V7.04168H7.04232V11.9167H5.95898V7.04168H1.08398V5.95834H5.95898V1.08334H7.04232V5.95834Z"
                    fill="white"
                    fill-rule="evenodd"
                    stroke="white"
                    stroke-width="2"
                  ></path>
                </svg>
                <span>Ajouter une actualité </span>
              </button>
            </div>
          </div>
          <div class="dash-box">
            <div class="text-center" v-if="loading">
              <div class="row">
                <div class="container">
                  <div class="col">
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                      style="width: 8rem; height: 8rem;"
                    ></b-spinner>
                  </div>
                </div>
              </div>
            </div>
            <div class="dash-table-box table-responsive" v-else>
              <table
                class="table--custom table table-hover"
                v-if="pagination.total > 0"
              >
                <thead>
                  <tr>
                    <th>Titre</th>
                    <th>Date de création</th>
                    <th>Auteur</th>
                    <th>Date de publication</th>
                    <th>Status</th>
                    <th width="50">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="cpt" v-for="(actuality, cpt) in actualities">
                    <td>{{ actuality.titre | truncate(50) }}</td>
                    <td>{{ format_date(actuality.created_at) }}</td>
                    <td>
                      {{ actuality.auteur.nom }} {{ actuality.auteur.prenoms }}
                    </td>
                    <td>
                      <span
                        v-html="
                          actuality.draft === 0
                            ? format_date(actuality.date_publication)
                            : '-------'
                        "
                      ></span>
                    </td>
                    <td>
                      <span
                        v-html="actuality.draft === 0 ? 'Publiée' : 'Brouillon'"
                      ></span>
                    </td>
                    <td>
                      <ul class="list-actions">
                        <li class="list-action">
                          <button
                            class="btn btn-sm btn-outline-primary"
                            title="Modifier l'actualité"
                            @click="
                              $router.push({
                                name: 'admin_actuality_update',
                                params: { id: actuality.id }
                              })
                            "
                          >
                            <svg
                              fill="none"
                              height="14"
                              viewBox="0 0 14 14"
                              width="14"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.0741 2.9843C13.362 2.69639 13.362 2.21654 13.0741 1.94339L11.3466 0.215932C11.0735 -0.0719775 10.5936 -0.0719775 10.3057 0.215932L8.94736 1.56689L11.7157 4.33526L13.0741 2.9843ZM0 10.5216V13.29H2.76837L10.9332 5.11778L8.16483 2.34942L0 10.5216Z"
                                fill="#2F80ED"
                              ></path>
                            </svg>
                          </button>
                        </li>
                        <li class="list-action">
                          <button
                            @click="chooseActu(actuality)"
                            class="btn btn-sm btn-outline-danger"
                            title="Supprimer l'actualité"
                            v-b-modal.modal-delete-actu
                          >
                            <svg
                              fill="none"
                              height="14"
                              viewBox="0 0 11 14"
                              width="11"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.40029 1.09201L7.54674 1.23846H7.75385H9.83846V1.71538H0.5V1.23846H2.58462H2.79172L2.93817 1.09201L3.53018 0.5H6.80828L7.40029 1.09201ZM1.5246 12.5062C1.34139 12.323 1.23846 12.0745 1.23846 11.8154V3.45385H9.1V11.8154C9.1 12.0745 8.99708 12.323 8.81387 12.5062C8.63066 12.6894 8.38217 12.7923 8.12308 12.7923H2.21538C1.95629 12.7923 1.7078 12.6894 1.5246 12.5062Z"
                                fill="#EB5757"
                                stroke="#EB5757"
                              ></path>
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <div class="text-center" v-if="loading">
                  <div class="row">
                    <div class="container">
                      <div class="col">
                        <div class="profile-box">
                          <b-spinner
                            variant="primary"
                            label="Text Centered"
                            style="width: 8rem; height: 8rem;"
                          ></b-spinner>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <div class="row">
                    <div class="col">
                      <div class="pagination">
                        <pagination-component
                          :offset="5"
                          :pagination="pagination"
                          @paginate="loadActualities()"
                          v-if="pagination.last_page > 1"
                        ></pagination-component>
                      </div>
                    </div>
                  </div>
                </div>
              </table>
              <div class="container" v-else>
                <div class="row">
                  <div class="col">
                    <div class="student-single-content__content text-center">
                      Auncun résultat
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth from "../../../../helper/config";
import PaginationComponent from "../PaginationComponent";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "ListeActualities",
  data: () => ({
    loading_delete: false,
    loading: false,
    actu_selceted: null,
    actualities: [],
    pagination: {
      current_page: 1
    }
  }),
  computed: {
    ...mapState({
      curr_user: state => state.user
    })
  },
  components: { PaginationComponent },
  created() {
    this.loadActualities();
  },
  methods: {
    chooseActu(actu) {
      this.actu_selceted = actu;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    loadActualities() {
      let url = auth.baseApi;
      this.loading = true;
      let page = "/?page=" + this.pagination.current_page;
      axios
        .get(url + "actualities/list" + page, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading = false;
          if (response) {
            this.actualities = response.data.data.data;
            this.pagination = response.data.pagination;
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    },
    deleteActu(id) {
      let url = auth.baseApi;
      this.loading_delete = true;
      axios
        .delete(url + "actualities/delete/" + id, {
          headers: { Authorization: "Bearer " + auth.getSession() }
        })
        .then(response => {
          this.loading_delete = false;
          if (response.data.status === true) {
            this.$toasted.global.all();
            this.$bvModal.hide("modal-delete-actu");
            this.loadActualities();
          } else this.$toasted.global.error();
        })
        .catch(error => {
          this.loading_delete = false;
          // eslint-disable-next-line no-constant-condition
          if (error.response.status === 401) {
            this.errors = [];
            this.$toasted.global.all({ message: "Votre connexion a expirée" });
            auth.logout();
            this.$router.push({ name: "login" });
          } else {
            this.$toasted.global.error();
          }
        });
    }
  }
};
</script>

<style scoped></style>
