<template>
    <div>
        <b-modal id="modal-add-admin" title="Ajouter un administrateur">
            <template v-slot:default="{ hide }">
                <form autocomplete="off" class="form-modal">
                    <div class="form-group">
                        <label class="app-label">
                            <span>Nom</span>
                            <input
                                    :class="errors.nom ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="text"
                                    v-model="form_add.nom"
                                    v-on:input="resetErrors('nom')"
                            />
                        </label>
                        <span class="text-danger" v-if="errors.nom">{{
              errors.nom[0]
            }}</span>
                    </div>
                    <div class="form-group">
                        <label class="app-label">
                            <span>Prénom(s)</span>
                            <input
                                    :class="errors.prenoms ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="text"
                                    v-model="form_add.prenoms"
                                    v-on:input="resetErrors('prenoms')"
                            />
                        </label>
                        <span class="text-danger" v-if="errors.prenoms">{{
              errors.prenoms[0]
            }}</span>
                    </div>
                    <div class="form-group">
                        <label class="app-label">
                            <span>Email</span>
                            <input
                                    :class="errors.email ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="text"
                                    v-model="form_add.email"
                                    v-on:input="resetErrors('email')"
                            />
                        </label>
                        <span class="text-danger" v-if="errors.email">{{
              errors.email[0]
            }}</span>
                    </div>
                    <div class="form-group">
                        <label class="app-label">
                            <span>Rôle</span>
                            <select
                                    :class="errors.profil ? 'is-invalid' : ''"
                                    class="form-control"
                                    v-model="form_add.profil"
                                    v-on:change="resetErrors('profil')"
                            >
                                <option value="null">-- Sélectionner un profil --</option>
                                <option value="2">Administrateur</option>
                                <option value="3">Comptable</option>
                            </select>
                        </label>
                        <span class="text-danger" v-if="errors.profil">{{
              errors.profil[0]
            }}</span>
                    </div>
                    <div class="mb-3">
                        Un mail contenant les accès sera envoyé à l’administrateur.
                    </div>
                </form>
            </template>
            <div slot="modal-footer">
                <div class="modal-actions">
                    <a @click="resetErrors()" class="link-red mx-1">Annuler</a>
                    <button
                            :disabled="loading"
                            @click="addAdmin()"
                            class="btn btn-red"
                            type="submit"
                    >
            <span v-if="loading"
            >Ajout en cours
              <b-spinner label="Spinning" variant="light" small></b-spinner
              ></span>
                        <span v-else>Ajouter un administrateur</span>
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal id="modal-update-admin" title="Modifier l'administrateur">
            <template v-slot:default="{ hide }">
                <form autocomplete="off" class="form-modal">
                    <!--<h4>{{ form_update.nom }} {{ form_update.prenoms }}</h4>-->

                    <div class="form-group">
                        <label class="app-label">
                            <span>Nom</span>
                            <input
                                    :class="errors.nom ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="text"
                                    v-model="form_update.nom"
                                    v-on:input="resetErrors('nom')"
                            />
                        </label>
                        <span class="text-danger" v-if="errors.nom">{{
              errors.nom[0]
            }}</span>
                    </div>
                    <div class="form-group">
                        <label class="app-label">
                            <span>Prénom(s)</span>
                            <input
                                    :class="errors.prenoms ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="text"
                                    v-model="form_update.prenoms"
                                    v-on:input="resetErrors('prenoms')"
                            />
                        </label>
                        <span class="text-danger" v-if="errors.prenoms">{{
              errors.prenoms[0]
            }}</span>
                    </div>
                    <div class="form-group">
                        <label class="app-label">
                            <span>Email</span>
                            <input
                                    :class="errors.email ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="text"
                                    v-model="form_update.email"
                                    v-on:input="resetErrors('email')"
                            />
                        </label>
                        <span class="text-danger" v-if="errors.email">{{
              errors.email[0]
            }}</span>
                    </div>
                    <div class="form-group">
                        <label class="app-label">
                            <span>Rôle</span>
                            <b-form-group>
                                <b-form-select
                                        :class="errors.profil ? 'is-invalid' : ''"
                                        :options="profiles"
                                        text-field="libelle"
                                        v-model="form_update.id_profil"
                                        v-on:change="resetErrors('profil')"
                                        value-field="id_profil"
                                >
                                    <template v-slot:first>
                                        <option :value="null" disabled
                                        >-- Sélectionner un rôle --
                                        </option
                                        >
                                    </template>
                                </b-form-select>
                                <span class="text-danger" v-if="errors.profil">{{
                  errors.profil[0]
                }}</span>
                            </b-form-group>
                        </label>
                        <span class="text-danger" v-if="errors.profil">{{
              errors.profil[0]
            }}</span>
                    </div>
                    <div class="mb-3">
                        Un mail contenant les accès sera envoyé à l’administrateur.
                    </div>
                </form>
            </template>
            <div slot="modal-footer">
                <div class="modal-actions">
                    <a @click="resetErrors()" class="link-red mx-1">Annuler</a>
                    <button
                            :disabled="loading_update"
                            @click="updateAdmin(form_update.id)"
                            class="btn btn-red"
                            type="submit"
                    >
            <span v-if="loading_update"
            >Mise à jour
              <b-spinner label="Spinning" variant="light" small></b-spinner
              ></span>
                        <span v-else>Mettre à jour</span>
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal id="modal-delete-admin" title="Supprimer l'administrateur">
            <template v-slot:default="{ hide }">
                <div class="delete-confirm-form__title">
                    Supprimer l’administrateur ?
                </div>
                <div class="delete-confirm-form__info">
                    {{ admin_selceted.nom }} {{ admin_selceted.prenoms }}
                </div>
            </template>
            <div slot="modal-footer">
                <div class="modal-actions">
                    <a @click="resetErrors()" class="link-red mx-1">Annuler</a>
                    <button
                            :disabled="loading"
                            @click="deleteAdmin(admin_selceted.id)"
                            class="btn btn-red"
                            type="submit"
                    >
                        <span v-if="loading">Suppresion...</span>
                        <span v-else>Oui, Supprimer</span>
                    </button>
                </div>
            </div>
        </b-modal>

        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="m-heading mb-3">
                        <h3 class="m-title">Administrateurs ({{ pagination.total }})</h3>
                        <div class="row">
                            <button
                                    @click="loadAdmins()"
                                    class="btn btn-primary mx-1"
                                    data-toggle="modal"
                            >
                                <span>Actualiser</span>
                            </button>
                            <button
                                    @click="resetErrors()"
                                    class="btn btn-primary"
                                    title="Ajouter un administrateur"
                                    v-b-modal.modal-add-admin
                            >
                                <svg
                                        fill="none"
                                        height="13"
                                        viewBox="0 0 13 13"
                                        width="13"
                                        xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                            clip-rule="evenodd"
                                            d="M7.04232 5.95834H11.9173V7.04168H7.04232V11.9167H5.95898V7.04168H1.08398V5.95834H5.95898V1.08334H7.04232V5.95834Z"
                                            fill="white"
                                            fill-rule="evenodd"
                                            stroke="white"
                                            stroke-width="2"
                                    />
                                </svg>
                                <span>Ajouter un administrateur</span>
                            </button>
                        </div>
                    </div>
                    <div class="dash-box">
                        <div class="text-center" v-if="loading">
                            <div class="row">
                                <div class="container">
                                    <div class="col">
                                        <b-spinner
                                                variant="primary"
                                                label="Text Centered"
                                                style="width: 8rem; height: 8rem;"
                                        ></b-spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dash-table-box table-responsive" v-else>
                            <table
                                    class="table--custom table table-hover"
                                    v-if="pagination.total > 0"
                            >
                                <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom(s)</th>
                                    <th>Email</th>
                                    <th>Rôle</th>
                                    <th width="50">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr :key="cpt" v-for="(admin, cpt) in admins">
                                    <td>{{ admin.nom }}</td>
                                    <td>{{ admin.prenoms }}</td>
                                    <td>{{ admin.email }}</td>
                                    <td>{{ admin.profil.libelle }}</td>
                                    <td>
                                        <ul class="list-actions">
                                            <li class="list-action">
                                                <button
                                                        @click="form_update = {...admin}"
                                                        class="btn btn-sm btn-outline-primary"
                                                        title="Modifier l'administrateur"
                                                        v-b-modal.modal-update-admin
                                                >
                                                    <svg
                                                            fill="none"
                                                            height="14"
                                                            viewBox="0 0 14 14"
                                                            width="14"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                                d="M13.0741 2.9843C13.362 2.69639 13.362 2.21654 13.0741 1.94339L11.3466 0.215932C11.0735 -0.0719775 10.5936 -0.0719775 10.3057 0.215932L8.94736 1.56689L11.7157 4.33526L13.0741 2.9843ZM0 10.5216V13.29H2.76837L10.9332 5.11778L8.16483 2.34942L0 10.5216Z"
                                                                fill="#2F80ED"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </li>
                                            <li
                                                    class="list-action"
                                                    v-if="curr_user.email !== admin.email"
                                            >
                                                <button
                                                        @click="chooseAdmin(admin)"
                                                        class="btn btn-sm btn-outline-danger"
                                                        title="Supprimer l'administrateur"
                                                        v-b-modal.modal-delete-admin
                                                >
                                                    <svg
                                                            fill="none"
                                                            height="14"
                                                            viewBox="0 0 11 14"
                                                            width="11"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                                d="M7.40029 1.09201L7.54674 1.23846H7.75385H9.83846V1.71538H0.5V1.23846H2.58462H2.79172L2.93817 1.09201L3.53018 0.5H6.80828L7.40029 1.09201ZM1.5246 12.5062C1.34139 12.323 1.23846 12.0745 1.23846 11.8154V3.45385H9.1V11.8154C9.1 12.0745 8.99708 12.323 8.81387 12.5062C8.63066 12.6894 8.38217 12.7923 8.12308 12.7923H2.21538C1.95629 12.7923 1.7078 12.6894 1.5246 12.5062Z"
                                                                fill="#EB5757"
                                                                stroke="#EB5757"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                                <div class="text-center">
                                    <div class="row">
                                        <div class="col">
                                            <div class="pagination">
                                                <pagination-component
                                                        :offset="5"
                                                        :pagination="pagination"
                                                        @paginate="loadAdmins()"
                                                        v-if="pagination.last_page > 1"
                                                ></pagination-component>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </table>
                            <div class="container" v-else>
                                <div class="row">
                                    <div class="col">
                                        <div class="student-single-content__content text-center">
                                            Auncun résultat
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import axios from "axios";
    import auth from "../../../../helper/config";
    import PaginationComponent from "../PaginationComponent";

    export default {
        name: "listeAdmins",
        data: () => ({
            curr_user: auth.getCurrentUser(),
            loading: false,
            loading_update: false,
            admins: [],
            admin_selceted: {},
            form_add: {
                nom: null,
                prenoms: null,
                email: null,
                profil: null
            },
            form_update: {
                id: null,
                nom: null,
                prenoms: null,
                email: null,
                id_profil: null
            },
            errors: [],
            profiles: [
                {id_profil: 2, libelle: 'Administrateur'},
                {id_profil: 3, libelle: 'Comptable'},
            ],
            pagination: {
                current_page: 1
            }
        }),
        components: {PaginationComponent},
        created() {
            this.loadAdmins()
        },
        methods: {
            resetErrors(field) {
                if (field) {
                    this.errors[field] = null
                } else {
                    this.errors = []
                    this.form_add.nom = null
                    this.form_add.prenoms = null
                    this.form_add.email = null
                    this.form_add.profil = null

                    this.form_update.nom = null
                    this.form_update.prenoms = null
                    this.form_update.email = null
                    this.form_update.id_profil = null
                    this.$bvModal.hide('modal-add-admin')
                    this.$bvModal.hide('modal-update-admin')
                    this.$bvModal.hide('modal-delete-admin')
                }
            },
            chooseAdmin(admin) {
                this.admin_selceted = admin
            },
            loadAdmins() {
                let url = auth.baseApi;
                this.loading = true;
                let page = "/?page=" + this.pagination.current_page;
                axios
                    .get(url + "users/admin/list" + page, {
                        headers: {'Authorization': 'Bearer ' + auth.getSession()}
                    })
                    .then(response => {
                        this.loading = false;
                        if (response) {
                            this.admins = response.data.data.data;
                            this.pagination = response.data.pagination;
                        } else this.$toasted.global.error()
                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.status === 401) {
                            this.errors = []
                            this.$toasted.global.all({message: 'Votre connexion a expirée'})
                            auth.logout()
                            this.$router.push({name: 'login'})
                        } else {
                            this.$toasted.global.error()
                        }
                    });
            },
            loadOneAdmin(id) {
                let url = auth.baseApi;
                this.loading_update = true;
                Object.keys(this.form_update).forEach(index => (this.form_update[index] = null));
                axios
                    .get(url + "users/profil/" + id, {
                        headers: {'Authorization': 'Bearer ' + auth.getSession()}
                    })
                    .then(response => {
                        this.loading_update = false;
                        if (response) {
                            this.form_update = response.data
                        } else this.$toasted.global.error()
                    })
                    .catch(error => {
                        this.loading_update = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.status === 401) {
                            this.errors = []
                            this.$toasted.global.all({message: 'Votre connexion a expirée'})
                            auth.logout()
                            this.$router.push({name: 'login'})
                        } else {
                            this.$toasted.global.error()
                        }
                    });
            },
            deleteAdmin(id) {
                let url = auth.baseApi;
                this.loading = true;
                axios
                    .delete(url + "users/admin/delete/" + id, {
                        headers: {'Authorization': 'Bearer ' + auth.getSession()}
                    })
                    .then(response => {
                        this.loading = false;
                        if (response.data.status === true) {
                            this.$bvModal.hide('modal-delete-admin')
                            this.loadAdmins()
                            this.$toasted.global.all()
                        } else this.$toasted.global.error()
                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.status === 401) {
                            this.$toasted.global.all({message: 'Votre connexion a expirée'})
                            auth.logout()
                            this.$router.push({name: 'login'})
                        } else {
                            this.$toasted.global.error()
                        }
                    });
            },
            updateAdmin(id) {
                let url = auth.baseApi;
                this.loading_update = true;
                axios
                    .put(url + "users/admin/update/" + id, this.form_update, {
                        headers: {'Authorization': 'Bearer ' + auth.getSession()}
                    })
                    .then(response => {
                        this.loading_update = false;
                        if (response.data.status === true) {
                            this.$bvModal.hide('modal-update-admin')
                            this.loadAdmins()
                            this.$toasted.global.all()
                        } else this.$toasted.global.error()
                    })
                    .catch(error => {
                        this.loading_update = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.status === 401) {
                            this.$toasted.global.all({message: 'Votre connexion a expirée'})
                            auth.logout()
                            this.$router.push({name: 'login'})
                        } else if (error.response.status === 400) {
                            this.errors = error.response.data.errors
                        } else {
                            this.$toasted.global.error()
                        }
                    });
            },
            addAdmin() {
                let url = auth.baseApi;
                this.loading = true;
                axios
                    .post(url + "users/admin/create", this.form_add, {
                        headers: {'Authorization': 'Bearer ' + auth.getSession()}
                    })
                    .then(response => {
                        this.loading = false;
                        if (response.data.status === true) {
                            this.$toasted.global.all()
                            this.$bvModal.hide('modal-add-admin')
                            this.loadAdmins()
                        } else this.$toasted.global.error()
                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.status === 401) {
                            this.$toasted.global.all({message: 'Votre connexion a expirée'})
                            auth.logout()
                            this.$router.push({name: 'login'})
                        } else if (error.response.status === 400) {
                            this.errors = error.response.data.errors
                        } else {
                            this.$toasted.global.error()
                        }
                    });
            }
        }
    };
</script>

<style scoped></style>
