/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import auth from "../helper/config.js";
import Home from "../views/Home.vue";
import Login from "../views/pages/auth/login";
import Register from "../views/pages/auth/register";
import LayoutAdmin from "../views/shared/DefaultContainer";
import LayoutGuest from "../views/shared/DefaultGuestContainer";
import UserProfil from "../views/pages/profil/UpdateProfil.vue";
import ShowUserProfil from "../views/pages/profil/userProfil";
import ResetPasswordRequest from "../views/pages/resetPassword/resetPasswordRequest";
import ResetPasswordApply from "../views/pages/resetPassword/resetPasswordApply";
import ListeOldStudents from "../views/pages/admin/oldStudents/liste_old_students";
import OldStudentDetails from "../views/pages/admin/oldStudents/oldStudentDetails";
import ListeOpportunities from "../views/pages/admin/opportunities/listOpportunities";
import DetailsOpportunity from "../views/pages/admin/opportunities/detailsOpportunity";
import AddOpportunity from "../views/pages/admin/opportunities/addOpportunity";
import UpdateOpportunity from "../views/pages/admin/opportunities/UpdateOpportunity";
import ListeAdmins from "../views/pages/admin/admins/listeAdmins";
import DetailsAdmin from "../views/pages/admin/admins/detailsAdmin";
import AddAdmin from "../views/pages/admin/admins/addAdmin";
import TermsConditions from "../views/pages/termsAndCondtions";
import DetailsActuality from "../views/pages/guest/actualities/detailsActuality.vue";
import ListeActualities from "../views/pages/admin/actualities/ListeActualities";
import AddActuality from "../views/pages/admin/actualities/addActuality";
import UpdateActu from "../views/pages/admin/actualities/updateActuality";
import Dashboard from "../views/pages/admin/Dashboard";
import CreatePassword from "../views/pages/password/CreatePassword";
import confidentialPolicy from "../views/pages/confidentialPolicy";
import userDataDelete from "../views/pages/userDataDelete";

Vue.use(VueRouter);

const routes = [
    {
      path: "*",
      redirect: "home"
    },
    {
        path: "/",
        name: "guest",
        redirect: "home",
        component: LayoutGuest,
        children: [
            {
                path: "home/:token",
                component: Home,
                name: "check_user",
                meta: {
                    guest: true
                }
            },
            {
                path: "terms",
                component: TermsConditions,
                name: "terms",
                meta: {
                    guest: true
                }
            },
            {
                path: "privacy",
                component: confidentialPolicy,
                name: "privacy",
                meta: {
                    guest: true
                }
            },{
                path: "usersinfo",
                component: userDataDelete,
                name: "usersinfo",
                meta: {
                    guest: true
                }
            },
            {
                path: "home",
                component: Home,
                name: "home",
                meta: {
                    guest: true
                }
            },
            {
                path: "/login",
                name: "login",
                component: Login,
                meta: {
                    guest: true
                }
            },
            {
                path: "/password/create/:code",
                name: "create_password",
                component: CreatePassword,
                meta: {
                    guest: true
                }
            },
            {
                path: "/register",
                //name: "register",
                redirect: 'home',
                component: Register,
                meta: {
                    guest: true
                }
            },
            {
                path: "/password/reset/request",
                name: "reset_password_request",
                component: ResetPasswordRequest,
                meta: {
                    guest: true
                }
            },
            {
                path: "/password/reset/apply/:token",
                name: "reset_password_apply",
                component: ResetPasswordApply,
                meta: {
                    guest: true
                }
            },
            {
                path: "/actulities/:id",
                name: "actuality_details",
                component: DetailsActuality,
                meta: {
                    guest: true
                }
            }
        ]
    },
    {
        path: "/admin",
        component: LayoutAdmin,
        redirect: "/",
        name: "layout",
        children: [
            {
                path: "dashboard",
                component: Dashboard,
                name: "admin_dashboard",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "profil",
                component: UserProfil,
                name: "user_profil",
                meta: {
                    requiresAuth: true
                }
            },{
                path: "show_profil",
                component: ShowUserProfil,
                name: "show_user_profil",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "students",
                component: ListeOldStudents,
                name: "liste_ancien",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "students/:id",
                component: OldStudentDetails,
                name: "ancien_details",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "opportunities",
                component: ListeOpportunities,
                name: "liste_opportunities",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "opportunities/:id",
                component: DetailsOpportunity,
                name: "opportunity_details",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "opportunities/add",
                component: AddOpportunity,
                name: "opportunity_add",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "opportunities/update/:id",
                component: UpdateOpportunity,
                name: "opportunity_update",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "actualities",
                component: ListeActualities,
                name: "liste_actualities",
                meta: {
                    requiresAuth: true
                }
            },
            {
              path: "actualities/:id",
              component: DetailsActuality,
              name: "admin_actuality_details",
              meat: {
                  requiresAuth: true
              }
            },
            {
              path: "update-actualities/:id",
              component: UpdateActu,
              name: "admin_actuality_update",
              meat: {
                  requiresAuth: true
              }
            },
            {
              path: "add/actualities",
              component: AddActuality,
              name: "actuality_add",
              meta: {
                  requiresAuth: true
              }
            },
            {
                path: "admins",
                component: ListeAdmins,
                name: "liste_admins",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "admins/:id",
                component: DetailsAdmin,
                name: "admin_details",
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "admins/add",
                component: AddAdmin,
                name: "admin_add",
                meta: {
                    requiresAuth: true
                }
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (auth.getToken() == null) {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            })
        } else {
            let user = auth.getToken()
            next()
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (auth.getToken() == null) {
            next()
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;
