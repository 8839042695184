<template>
    <main class="main main--gray">
        <div class="auth-box">
            <div class="auth-box__main">
                <h3 class="auth-box__title">Connexion</h3>
                <form @submit.prevent="login" autocomplete="off">
                    <div
                            class="alert alert-danger alert-dismissible fade show"
                            role="alert"
                            v-if="message"
                    >
                        <strong>Attention!</strong> {{ message }}
                    </div>
                    <div class="form-group">
                        <label class="app-label">
                            <span>Email</span>
                            <input
                                    :class="errors.email ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="email"
                                    v-model="form.email"
                                    v-on:input="resetErrors('email')"
                            />
                        </label>
                        <span class="text-danger" v-if="errors.email">{{
              errors.email[0]
            }}</span>
                    </div>
                    <div class="form-group">
                        <label class="app-label">
              <span>
                <span>Mot de passe</span>
                <router-link class="link-red" to="/password/reset/request">
                  <span> Mot de passe oublié </span>
                </router-link>
              </span>
                            <input
                                    :class="errors.password ? 'is-invalid' : ''"
                                    class="form-control"
                                    type="password"
                                    v-model="form.password"
                                    v-on:input="resetErrors('password')"
                            />
                        </label>
                        <span class="text-danger" v-if="errors.password">{{
              errors.password[0]
            }}</span>
                    </div>
                    <div class="form-group">
            <span @click="faceLogin()">
              <svg style="cursor: pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px"
                   height="48px"><path fill="#3f51b5" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"/><path fill="#fff"
                                                                                                           d="M29.368,24H26v12h-5V24h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H30v4h-2.287 C26.104,16,26,16.6,26,17.723V20h4L29.368,24z"/></svg>
            </span>
                        <span @click="googleAuth()">
              <svg style="cursor: pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px"
                   height="48px"><path fill="#FFC107"
                                       d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/><path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/><path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/><path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/></svg>
            </span>
                    </div>
                    <button :disabled="loading" class="btn btn-red" type="submit">
            <span v-if="loading"
            >Connexion <b-spinner small label="Small Spinner"></b-spinner
            ></span>
                        <span v-else>Se connecter</span>
                    </button>
                </form>
            </div>
            <!--
            <div class="auth-box__footer">
              Vous n’avez pas de compte?<a
                @click="$router.push({ name: 'home' })"
                class="auth-box__link"
                >Inscrivez-vous</a
              >
            </div>-->
        </div>
    </main>
</template>

<script>
    import axios from "axios";
    import auth from "../../../helper/config";
    import firebase from "firebase/app";
    import "firebase/auth";
    import {mapState} from "vuex";

    /*const firebaseConfig = {
        apiKey: "AIzaSyDJA_A6jOHreOKAyouOh_2_FuGNLApY3IE",
        authDomain: "esgis-alumin.firebaseapp.com",
        databaseURL: "https://esgis-alumin.firebaseio.com",
        projectId: "esgis-alumin",
        storageBucket: "esgis-alumin.appspot.com",
        messagingSenderId: "460050997984",
        appId: "1:460050997984:web:2c7a34912c6a158fa62685"
    };*/
    const firebaseConfig = {
        apiKey: "AIzaSyCn1kOsYfat_ugQ5ClxpvNdz5TFETvv2wM",
        authDomain: "esgisalumni-62d80.firebaseapp.com",
        projectId: "esgisalumni-62d80",
        storageBucket: "esgisalumni-62d80.appspot.com",
        messagingSenderId: "1081000144120",
        appId: "1:1081000144120:web:54b582e7ea2784d398df0d",
        measurementId: "G-EWBVP74D8F"
    };;
    export default {
        name: "login",
        data: () => ({
            form: {
                email: null,
                password: null
            },
            errors: [],
            loading: false,
            message: "",
            correct: true,
            authConnect: {
                first_name: '',
                email: '',
                phone: '',
                last_name: ''
            }

        }),
        computed: {
            ...mapState({
                curr_user: state => state.user
            })
        },
        mounted() {
            if (!firebase.apps.length) {
                firebase.initializeApp(firebaseConfig);
            }
        },
        methods: {
            faceLogin() {
                var provider = new firebase.auth.FacebookAuthProvider();
                provider.addScope('user_birthday');
                firebase.auth().languageCode = 'fr_FR';
                firebase.auth().signInWithPopup(provider).then((result) => {
                    var user = result.additionalUserInfo.profile;
                    this.authConnect.first_name = user.first_name
                    this.authConnect.last_name = user.last_name
                    this.authConnect.email = user.email
                    this.authConnect.phone = ''
                    let url = auth.baseApi;
                    this.loading = true;
                    axios.post(url + 'auth/authenticate', this.authConnect).then((response) => {
                        auth.authenticate(response.data);
                        this.$store.commit("updateUserdata", auth.getCurrentUser());
                        this.message = "";
                        this.loading = false;
                        this.$router.push({name: "liste_ancien"})
                    })
                }).catch(() => {
                    this.loading = false;
                });
            },
            googleAuth() {
                var provider = new firebase.auth.GoogleAuthProvider();
                firebase.auth().languageCode = 'fr_FR';
                firebase.auth().signInWithPopup(provider).then((result) => {
                    var user = result.additionalUserInfo.profile;
                    this.authConnect.first_name = user.family_name
                    this.authConnect.last_name = user.given_name
                    this.authConnect.email = user.email
                    this.authConnect.phone = ''
                    let url = auth.baseApi;
                    this.loading = true;
                    axios.post(url + 'auth/authenticate', this.authConnect).then((response) => {
                        auth.authenticate(response.data);
                        this.$store.commit("updateUserdata", auth.getCurrentUser());
                        this.message = "";
                        this.loading = false;
                        this.$router.push({name: "liste_ancien"})
                    })
                }).catch(() => {
                    this.loading = false;
                });
            },
            resetErrors(field) {
                if (field) {
                    this.errors[field] = null;
                } else {
                    this.errors = [];
                    this.form.email = null;
                    this.form.password = null;
                }
            },
            login() {
                let url = auth.baseApi;
                this.loading = true;
                axios
                    .post(url + "login", this.form)
                    .then(response => {
                        this.loading = false;
                        auth.authenticate(response.data);
                        this.$store.commit("updateUserdata", auth.getCurrentUser());
                        this.message = "";
                        if (this.curr_user.profil.id !== 1)
                            this.$router.push({name: "admin_dashboard"});
                        else this.$router.push({name: "liste_ancien"});
                    })
                    .catch(error => {
                        this.loading = false;
                        // eslint-disable-next-line no-constant-condition
                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors;
                        } else if (error.response.status === 401) {
                            this.correct = false;
                            this.message = "Données invalides ou compte désactivé.";
                        }
                    });
            }
        }
    };
</script>

<style lang="css">
    .auth-box{
        padding-bottom: 70px;
    }
</style>
